import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  isFetchingItems: boolean;
  setFetchingItems: () => void;
  cancelFetchingItems: () => void;
}

interface IFetchingContext {
  children: ReactNode;
}

const FetchingContext = createContext<State | undefined>(undefined);

const FetchingProvider = ({ children }: IFetchingContext) => {
  const [isFetchingItems, setIsFetchingItems] = useState(false);

  const setFetchingItems = useCallback(() => {
    setIsFetchingItems(true);
  }, []);

  const cancelFetchingItems = useCallback(() => {
    setIsFetchingItems(false);
  }, []);

  const value = useMemo(
    () => ({
      isFetchingItems,
      setFetchingItems,
      cancelFetchingItems,
    }),
    [isFetchingItems, setFetchingItems, cancelFetchingItems]
  );
  return (
    <FetchingContext.Provider value={value}>
      {children}
    </FetchingContext.Provider>
  );
};

const useFetching = () => {
  const context = React.useContext(FetchingContext);
  if (context === undefined) {
    throw new Error("useFetching must be used within a FetchingProvider");
  }
  return context;
};

export { FetchingContext, FetchingProvider, useFetching };
