import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import React from "react";
import { IGoal } from "../../constants/types";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Avatar from "@mui/material/Avatar";
import FlagIcon from "@mui/icons-material/Flag";
import ModalTitle from "../ModalTitle/ModalTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useHistory } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";

interface IModalDetailGoalProps {
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  data: IGoal;
}

const ModalDetailGoal = ({
  fullScreen,
  open,
  onClose,
  data,
}: IModalDetailGoalProps) => {
  const history = useHistory();

  const goToGoal = () => {
    history.push(`/goal-detail?id=${data.id}`);
  };

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <FlagIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            {data.title}
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          padding: "32px!important",
        }}
      >
        <Grid2 container>
          <Grid2 xs={12} md={2}>
            <Typography fontWeight={700}>Title:</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Typography>{data.title}</Typography>
          </Grid2>
        </Grid2>
        <Grid2 container mt={2.5}>
          <Grid2 xs={12} md={2}>
            <Typography fontWeight={700}>Summary:</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Typography>{data.summary}</Typography>
          </Grid2>
        </Grid2>
        {data.gross_amount && (
          <Grid2 container mt={2.5}>
            <Grid2 xs={12} md={2}>
              <Typography fontWeight={700}>Gross Amount:</Typography>
            </Grid2>
            <Grid2 xs={12} md={10}>
              <Typography>${data.gross_amount}</Typography>
            </Grid2>
          </Grid2>
        )}
        <Grid2 container mt={2.5}>
          <Grid2 xs={12} md={2}>
            <Typography fontWeight={700}>Status:</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Typography>{data.status}</Typography>
          </Grid2>
        </Grid2>
        <Grid2 container mt={2.5}>
          <Grid2 xs={12} md={2}>
            <Typography fontWeight={700}>Advisor name:</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  width: 24,
                  height: 24,
                }}
                alt={data.advisor_name}
                src={data.advisor_avatar_photo}
              />
              <Typography>{data.advisor_name}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
        <Grid2 container mt={2.5}>
          <Grid2 xs={12} md={2}>
            <Typography fontWeight={700}>Member name:</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  width: 24,
                  height: 24,
                }}
                alt={data.member_name}
                src={data.member_avatar_photo}
              />
              <Typography>{data.member_name}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ p: 4 }}>
        <Button
          variant="contained"
          endIcon={<OpenInNewIcon />}
          onClick={goToGoal}
        >
          Go to goal
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDetailGoal;
