import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import {
  GetBusinessDataResponse,
  ParamsGetBusinessData,
} from "../../constants/types";

const handleRequest = async (params: ParamsGetBusinessData) => {
  const urlApi = qs.stringifyUrl({
    url: `/business/${params.businessAlias}`,
    query: {
      filter: params.filter,
    },
  });
  const { data } = await axios.get<GetBusinessDataResponse>(urlApi);
  return data;
};

export default function useBusinessData(params: ParamsGetBusinessData) {
  return useQuery<GetBusinessDataResponse>(
    ["businessData", params],
    () => handleRequest(params),
    {
      enabled: !!params.businessAlias,
    }
  );
}
