import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import FindAdvisorPage from "../pages/FindAdvisorPage/FindAdvisorPage";
import RequestAssistancePage from "../pages/RequestAssistancePage/RequestAssistancePage";
import BecomeAdvisorPage from "../pages/BecomeAdvisorPage/BecomeAdvisorPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import MemberPage from "../pages/MemberPage/MemberPage";
import MemberApplicationPage from "../pages/MemberApplicationPage/MemberApplicationPage";
// import AdvisorPage from "../pages/AdvisorPage/AdvisorPage";
// import AdvisorClientsPage from "../pages/AdvisorClientsPage/AdvisorClientsPage";
// import AdvisorAddClientPage from "../pages/AdvisorAddClientPage/AdvisorAddClientPage";
// import WorksheetApplicationPage from "../pages/WorksheetApplicationPage/WorksheetApplicationPage";
import Error404Page from "../pages/Error404Page/Error404Page";
import ThemeComponent from "../components/ThemeComponent/ThemeComponent";
import PageLayout from "../layouts/PageLayout";
// import LicenseeFoundPage from "../pages/LicenseeFoundPage/LicenseeFoundPage";
import LicenseeFoundPagev2 from "../pages/LicenseeFoundPage/LicenseeFoundPagev2";
import OTPLoginPage from "../pages/OTPLoginPage/OTPLoginPage";
import PageLayoutAlternative from "../layouts/PageLayoutAlternative";
// import PageLayoutDashboard from "../layouts/PageLayoutDashboard";
import LicenseeServiceRequest from "../pages/LicenseeServiceRequest/LicenseeServiceRequest";
import AdvisorProfilePage from "../pages/AdvisorProfilePage/AdvisorProfilePage";
import MemberDetailPage from "../pages/MemberDetailPage/MemberDetailPage";
import MemberAddGoalPage from "../pages/MemberAddGoalPage/MemberAddGoalPage";
import GoalListPage from "../pages/GoalListPage/GoalListPage";
import GoalDetailPage from "../pages/GoalDetailPage/GoalDetailPage";
import SecuredDocumentVaultPage from "../pages/SecuredDocumentVaultPage/SecuredDocumentVaultPage";
import OTPLoginMemberPage from "../pages/OTPLoginPage/OTPLoginMemberPage";
import SecuredDocumentListPage from "../pages/SecuredDocumentListPage/SecuredDocumentListPage";
import PageLayoutDashboardv2 from "../layouts/PageLayoutDashboardv2";
import AssociatePage from "../pages/AssociatePage/AssociatePage";
import OTPAdvisorOrMemberPage from "../pages/OTPLoginPage/OTPAdvisorOrMemberPage";
import RandomBusinessPage from "../pages/RandomBusinessPage/RandomBusinessPage";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          {/* <PageLayout>
            <HomePage />
          </PageLayout> */}
          <Redirect
            to={{
              pathname: "/welcome",
            }}
          />
        </Route>
        <Route path="/find-an-advisor">
          <PageLayoutAlternative>
            <FindAdvisorPage />
          </PageLayoutAlternative>
        </Route>
        <Route path="/request-assistance">
          <PageLayoutAlternative>
            <RequestAssistancePage />
          </PageLayoutAlternative>
        </Route>
        <Route path="/become-an-advisor">
          <PageLayout>
            <BecomeAdvisorPage />
          </PageLayout>
        </Route>
        <Route path="/login">
          <ThemeComponent>
            <LoginPage />
          </ThemeComponent>
        </Route>
        <Route path="/welcome">
          <ThemeComponent>
            <LicenseeFoundPagev2 />
          </ThemeComponent>
        </Route>
        <Route path="/licensee-service-request">
          <PageLayoutAlternative>
            <LicenseeServiceRequest />
          </PageLayoutAlternative>
        </Route>
        <Route path="/otp-login">
          <ThemeComponent>
            <OTPLoginPage />
          </ThemeComponent>
        </Route>
        <Route path="/otp-login-secured-vault">
          <ThemeComponent>
            <OTPLoginMemberPage />
          </ThemeComponent>
        </Route>
        <Route path="/otp-login-member-advisor">
          <ThemeComponent>
            <OTPAdvisorOrMemberPage />
          </ThemeComponent>
        </Route>
        <Route path="/dashboard">
          {/* <PageLayoutDashboardv2>
            <DashboardAdvisorPage />
          </PageLayoutDashboardv2> */}
          <Redirect
            to={{
              pathname: "/member-list",
            }}
          />
        </Route>
        <Route path="/member-list">
          <PageLayoutDashboardv2>
            <MemberPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/member-detail">
          <PageLayoutDashboardv2>
            <MemberDetailPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/member-add-a-goal">
          <PageLayoutDashboardv2>
            <MemberAddGoalPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/goal-list">
          <PageLayoutDashboardv2>
            <GoalListPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/goal-detail">
          <PageLayoutDashboardv2>
            <GoalDetailPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/secured-document-vault">
          <PageLayoutAlternative>
            <SecuredDocumentVaultPage />
          </PageLayoutAlternative>
        </Route>
        <Route path="/secured-document-list">
          <PageLayoutDashboardv2>
            <SecuredDocumentListPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/associate-list">
          <PageLayoutDashboardv2>
            <AssociatePage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/member-application">
          <PageLayoutDashboardv2>
            <MemberApplicationPage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/my-account">
          <PageLayoutDashboardv2>
            <AdvisorProfilePage />
          </PageLayoutDashboardv2>
        </Route>
        <Route path="/random-business">
          <ThemeComponent>
            <RandomBusinessPage />
          </ThemeComponent>
        </Route>
        <Route path="*">
          <Error404Page />
        </Route>
      </Switch>
    </Router>
  );
}
