import React, { useEffect, useMemo, useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import { Sync } from "@mui/icons-material";
import qs from "query-string";
import { GetSuggestedTagsResponse } from "../../constants/types";
import EditIcon from "@mui/icons-material/Edit";
import ModalTitle from "../ModalTitle/ModalTitle";
import TagsCheckbox from "../TagsCheckbox/TagsCheckbox";

interface IModalEditTagsProps {
  advisorID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  tags: string[];
  isEdit?: boolean;
}

interface IValues {
  tags: string[];
}

const ModalEditTags = ({
  advisorID,
  fullScreen,
  open,
  onClose,
  tags,
  isEdit,
}: IModalEditTagsProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoadingGenerateTags, setIsLoadingGenerateTags] = useState(false);
  const [limitReached, setLimitReached] = useState(false);

  const initialValues = useMemo(
    () => ({
      tags: tags,
    }),
    [tags]
  );

  const schema = yup.object({
    tags: yup.array().min(1).of(yup.string().required()).required(),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const generateTags = async () => {
    setIsLoadingGenerateTags(true);

    try {
      const urlApi = qs.stringifyUrl({
        url: `advisor/${advisorID}/suggested-advisor-atrributes`,
        query: {
          advisor_id: advisorID,
        },
      });
      const res = await axios.get<GetSuggestedTagsResponse>(urlApi);
      if (res.status === 200 && res.data.suggested_tags.length > 0) {
        setValue("tags", res.data.suggested_tags);
      }
      setIsLoadingGenerateTags(false);
    } catch (error) {
      setIsLoadingGenerateTags(false);
      console.error(error);
    }
  };

  const onSubmit = async (values: IValues) => {
    console.log(values);
    setIsButtonLoading(true);

    try {
      const body = {
        id: advisorID,
        tags: values.tags,
      };
      console.log("Body", body);
      const res = await axios.patch(`/advisor/${advisorID}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: `Tags successfully edited`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setFetchingItems();
        onClose();
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      reset(initialValues);
    }
  }, [initialValues, open, reset]);

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <EditIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            {isEdit ? "Edit" : "Add"} tags
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Typography>Tags:</Typography>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagsCheckbox
                  options={[]}
                  getOptionDisabled={(option) =>
                    limitReached && !field.value.includes(option)
                  }
                  value={field.value}
                  onChange={(_event, newTag: string[]) => {
                    field.onChange(newTag);
                    setLimitReached(newTag.length >= 5);
                  }}
                  freeSolo={true}
                  placeholder="Select from existing suggestion or type a new tag, and press Enter..."
                  error={Boolean(errors.tags)}
                  helperText={errors.tags?.message}
                />
              )}
            />
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <LoadingButton
                disabled
                loading={isLoadingGenerateTags}
                loadingPosition="start"
                onClick={generateTags}
                disableElevation
                variant="outlined"
                size="small"
                color="buttongreen"
                startIcon={<Sync />}
              >
                Generate suggested tags
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditTags;
