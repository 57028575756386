import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import { Autocomplete, LoadingButton } from "@mui/lab";
import { useFetching } from "../../context/fetchingContext";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import useSuburbs from "../../services/queries/useSuburb";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import { Sync } from "@mui/icons-material";
import {
  GetAssociateResponse,
  GetSuggestedPhotoResponse,
} from "../../constants/types";
import qs from "query-string";
import DialogActions from "@mui/material/DialogActions";
import useSuggestedAssociateTags from "../../services/queries/useSuggestedAssociateTags";
import ModalTitle from "../ModalTitle/ModalTitle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import TagsCheckbox from "../TagsCheckbox/TagsCheckbox";

interface IModalAddAssociateProps {
  advisorID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  associateDetail?: GetAssociateResponse;
}

interface IValues {
  name: string;
  email?: string;
  phone?: string;
  suburb?: string;
  tags: string[];
  services: string[];
}

const ModalAddAssociate = ({
  advisorID,
  fullScreen,
  open,
  onClose,
  associateDetail,
}: IModalAddAssociateProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoadingGenerateProfilePhoto, setIsLoadingGenerateProfilePhoto] =
    useState(false);
  // const [isLoadingGenerateTags, setIsLoadingGenerateTags] = useState(false);
  // const [isLoadingGenerateServices, setIsLoadingGenerateServices] =
  //   useState(false);
  const [image, setImage] = useState<File | string>("");
  const { data: suburbs } = useSuburbs();
  const { data: suggestedAssociateTags } = useSuggestedAssociateTags(advisorID);
  const [limitTagReached, setLimitTagReached] = useState(false);
  const [limitServiceReached, setLimitServiceReached] = useState(false);

  const uniqueSuburbs = [...new Set(suburbs)];

  const initialValues = useMemo(
    () => ({
      name: "",
      email: "",
      phone: "",
      suburb: "",
      tags: [],
      services: [],
    }),
    []
  );

  const schema = yup.object({
    name: yup.string().required("Name is required").max(50),
    email: yup
      .string()
      .email("Email format is not correct")
      .required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    suburb: yup.string().required("Suburb is required"),
    tags: yup.array().min(1).of(yup.string().required()).required(),
    services: yup.array().min(1).of(yup.string().required()).required(),
  });

  const schemaEdit = yup.object({
    name: yup.string().required("Name is required").max(50),
    // email: yup
    //   .string()
    //   .email("Email format is not correct")
    //   .required("Email is required"),
    // phone: yup.string().required("Phone number is required"),
    // suburb: yup.string().required("Suburb is required"),
    tags: yup.array().min(1).of(yup.string().required()).required(),
    services: yup.array().min(1).of(yup.string().required()).required(),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IValues>({
    resolver: yupResolver(associateDetail ? schemaEdit : schema),
    defaultValues: initialValues,
  });

  const generateProfilePhoto = async () => {
    setIsLoadingGenerateProfilePhoto(true);

    try {
      const urlApi = qs.stringifyUrl({
        url: `suggested-avatar-photo`,
      });
      const res = await axios.get<GetSuggestedPhotoResponse>(urlApi);
      if (res.status === 200 && res.data.url.length > 0) {
        setImage(res.data.url);
      }
      setIsLoadingGenerateProfilePhoto(false);
    } catch (error) {
      setIsLoadingGenerateProfilePhoto(false);
      console.error(error);
    }
  };

  // const generateTags = async () => {
  //   setIsLoadingGenerateTags(true);

  //   try {
  //     const urlApi = qs.stringifyUrl({
  //       url: `suggested-associate-attributes`,
  //       // query: {
  //       //   advisor_id: advisorID,
  //       // },
  //     });
  //     const res = await axios.get<GetSuggestedServicesResponse>(urlApi);
  //     if (res.status === 200 && res.data.suggested_roles.length > 0) {
  //       setValue("tags", res.data.suggested_roles);
  //     }
  //     setIsLoadingGenerateTags(false);
  //   } catch (error) {
  //     setIsLoadingGenerateTags(false);
  //     console.error(error);
  //   }
  // };

  // const generateServices = async () => {
  //   setIsLoadingGenerateServices(true);

  //   try {
  //     const urlApi = qs.stringifyUrl({
  //       url: `suggested-associate-attributes`,
  //       // query: {
  //       //   advisor_id: advisorID,
  //       // },
  //     });
  //     const res = await axios.get<GetSuggestedServicesResponse>(urlApi);
  //     if (res.status === 200 && res.data.suggested_services.length > 0) {
  //       setValue("services", res.data.suggested_services);
  //     }
  //     setIsLoadingGenerateServices(false);
  //   } catch (error) {
  //     setIsLoadingGenerateServices(false);
  //     console.error(error);
  //   }
  // };

  const onSubmit = async (values: IValues) => {
    console.log(values);
    setIsButtonLoading(true);
    if (associateDetail) {
      try {
        const body = {
          name: values.name,
          contact: {
            phone_number: values.phone,
            email: values.email,
            address: {
              suburb: values.suburb,
            },
          },
          tags: values.tags,
          services: values.services,
          avatar_photo: image ?? "",
          // campaign_id: "c2023003",
        };
        console.log("Body", body);
        const res = await axios.patch(`advisor/${associateDetail.id}`, body);
        if (res.status === 200 || res.status === 201) {
          Swal.fire({
            title: `Associate updated`,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
          setFetchingItems();
          onClose();
        }
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
        console.error(error);
      }
    } else {
      try {
        const body = {
          referral_advisor_id: advisorID,
          name: values.name,
          contact: {
            phone_number: values.phone,
            email: values.email,
            address: {
              suburb: values.suburb,
            },
          },
          tags: values.tags,
          services: values.services,
          avatar_photo: image ?? "",
          // campaign_id: "c2023003",
        };
        console.log("Body", body);
        const res = await axios.post(`advisor`, body);
        if (res.status === 200) {
          Swal.fire({
            title: `New associate added`,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
          setFetchingItems();
          onClose();
        }
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (open) {
      if (associateDetail) {
        reset({
          name: associateDetail.name,
          tags: associateDetail.tags,
          services: associateDetail.services,
        });
        setImage(associateDetail.avatar_photo);
      } else {
        reset(initialValues);
        setImage("");
        generateProfilePhoto();
      }
    }
  }, [associateDetail, initialValues, open, reset]);

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          {associateDetail ? <EditIcon /> : <AddIcon />}
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            {associateDetail ? "Edit associate" : "Add new associate"}
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center">
              <label htmlFor="outlined-button-file">
                <Input
                  inputProps={{
                    accept: "image/*",
                    style: {
                      display: "none",
                    },
                  }}
                  id="outlined-button-file"
                  type="file"
                  onChange={async (event: any) => {
                    if (event.target.files[0].size < 2097152) {
                      console.log(event.target.files[0]);

                      const formData = new FormData();
                      if (event.target.files[0]) {
                        formData.append(
                          "filename",
                          event.target.files[0] as any
                        );
                      }
                      const res = await axios.post(`/upload-media`, formData, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      });
                      if (res.status === 201 || res.status === 200) {
                        console.log("imageurl", res.data.url);
                        setImage(res.data.url);
                      }
                    } else {
                      event.target.value = "";
                    }
                  }}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        position: "relative",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        border: "0.5px solid rgba(0,0,0,0.2)",
                      }}
                    >
                      <Box>
                        <PhotoCamera
                          sx={{
                            fontSize: "1rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </Box>
                    </Box>
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    alt="photo"
                    src={typeof image === "string" ? image : ""}
                    sx={{
                      width: 72,
                      height: 72,
                    }}
                  />
                </Badge>
              </label>
            </Stack>
            <Stack direction="row" justifyContent="center" mt={2}>
              <LoadingButton
                loading={isLoadingGenerateProfilePhoto}
                loadingPosition="start"
                onClick={generateProfilePhoto}
                disableElevation
                variant="contained"
                color="buttonlightgreen"
                size="small"
                startIcon={<Sync />}
              >
                Hey AI, generate another photo
              </LoadingButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Name:</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    placeholder="eg. John Smith"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? ""}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Email:</Typography>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    placeholder="eg. john@mail.com"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? ""}
                    InputProps={{
                      type: "email",
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Phone Number:</Typography>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    placeholder="eg. 04111 222 333"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? ""}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography>Suburb:</Typography>
            <Controller
              name="suburb"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  // loading={isLoadingKategori}
                  disableClearable
                  options={uniqueSuburbs ?? []}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) =>
                    String(option) === String(value)
                  }
                  size="small"
                  value={field.value}
                  onChange={(_event, suburb: string | null) => {
                    field.onChange(suburb);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Choose Suburb --"
                      error={Boolean(errors.suburb)}
                      helperText={errors.suburb && "Please choose one"}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Competencies:</Typography>
            <Stack spacing={1}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <TagsCheckbox
                    options={suggestedAssociateTags?.suggested_roles ?? []}
                    getOptionDisabled={(option) =>
                      limitTagReached && !field.value.includes(option)
                    }
                    value={field.value}
                    onChange={(_event, newTag: string[]) => {
                      field.onChange(newTag);
                      setLimitTagReached(newTag.length >= 5);
                    }}
                    freeSolo={field.value.length >= 5 ? false : true}
                    placeholder="Select from existing suggestion or type a new competency, and press Enter..."
                    error={Boolean(errors.tags)}
                    helperText={errors.tags?.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography>Skills:</Typography>
            <Stack spacing={1}>
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <TagsCheckbox
                    options={suggestedAssociateTags?.suggested_services ?? []}
                    getOptionDisabled={(option) =>
                      limitServiceReached && !field.value.includes(option)
                    }
                    value={field.value}
                    onChange={(_event, newTag: string[]) => {
                      field.onChange(newTag);
                      setLimitServiceReached(newTag.length >= 5);
                    }}
                    freeSolo={field.value.length >= 5 ? false : true}
                    placeholder="Select from existing suggestion or type a new skill and press Enter..."
                    error={Boolean(errors.services)}
                    helperText={errors.services?.message}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddAssociate;
