import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import {
  GetAdvisorDocumentsResponse,
  ParamsGetAdvisorDocuments,
} from "../../constants/types";

const handleRequest = async (params: ParamsGetAdvisorDocuments) => {
  const urlApi = qs.stringifyUrl({
    url: `/advisor/${params.advisorID}/documents`,
    query: {
      filter: params.filter,
      allowed_access_document_id: params.documentID,
    },
  });
  const { data } = await axios.get<GetAdvisorDocumentsResponse>(urlApi);
  return data;
};

export default function useAdvisorDocuments(params: ParamsGetAdvisorDocuments) {
  return useQuery<GetAdvisorDocumentsResponse>(
    ["advisorDocuments", params],
    () => handleRequest(params),
    {
      enabled: !!params.advisorID,
    }
  );
}
