import React, { useEffect, useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import { Sync } from "@mui/icons-material";
import qs from "query-string";
import { GetSuggestedPhotoResponse } from "../../constants/types";
import Input from "@mui/material/Input";
import Badge from "@mui/material/Badge";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import ModalTitle from "../ModalTitle/ModalTitle";

interface IModalEditAvatarProps {
  advisorID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  avatar: string;
}

const ModalEditAvatar = ({
  advisorID,
  fullScreen,
  open,
  onClose,
  avatar,
}: IModalEditAvatarProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoadingGenerateProfilePhoto, setIsLoadingGenerateProfilePhoto] =
    useState(false);
  const [image, setImage] = useState<File | string>(avatar);

  const generateProfilePhoto = async () => {
    setIsLoadingGenerateProfilePhoto(true);

    try {
      const urlApi = qs.stringifyUrl({
        url: `suggested-avatar-photo`,
      });
      const res = await axios.get<GetSuggestedPhotoResponse>(urlApi);
      if (res.status === 200 && res.data.url.length > 0) {
        setImage(res.data.url);
      }
      setIsLoadingGenerateProfilePhoto(false);
    } catch (error) {
      setIsLoadingGenerateProfilePhoto(false);
      console.error(error);
    }
  };

  const onSubmit = async () => {
    setIsButtonLoading(true);

    try {
      const body = {
        id: advisorID,
        avatar_photo: image ?? "",
      };
      console.log("Body", body);
      const res = await axios.patch(`/advisor/${advisorID}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: `Avatar successfully edited`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setFetchingItems();
        onClose();
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      if (avatar) {
        setImage(avatar);
      }
    }
  }, [avatar, open]);

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <EditIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Edit avatar
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center">
              <label htmlFor="outlined-button-file">
                <Input
                  inputProps={{
                    accept: "image/*",
                    style: {
                      display: "none",
                    },
                  }}
                  id="outlined-button-file"
                  type="file"
                  onChange={async (event: any) => {
                    if (event.target.files[0].size < 2097152) {
                      console.log(event.target.files[0]);

                      const formData = new FormData();
                      if (event.target.files[0]) {
                        formData.append(
                          "filename",
                          event.target.files[0] as any
                        );
                      }
                      const res = await axios.post(`/upload-media`, formData, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      });
                      if (res.status === 201 || res.status === 200) {
                        console.log("imageurl", res.data.url);
                        setImage(res.data.url);
                      }
                    } else {
                      event.target.value = "";
                    }
                  }}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        position: "relative",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        border: "0.5px solid rgba(0,0,0,0.2)",
                      }}
                    >
                      <Box>
                        <PhotoCamera
                          sx={{
                            fontSize: "1rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </Box>
                    </Box>
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    alt="photo"
                    src={typeof image === "string" ? image : ""}
                    sx={{
                      width: 72,
                      height: 72,
                    }}
                  />
                </Badge>
              </label>
            </Stack>
            <Stack direction="row" justifyContent="center" mt={2}>
              <LoadingButton
                loading={isLoadingGenerateProfilePhoto}
                loadingPosition="start"
                onClick={generateProfilePhoto}
                disableElevation
                variant="outlined"
                color="buttongreen"
                size="small"
                startIcon={<Sync />}
              >
                Hey AI, generate another photo
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={onSubmit}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditAvatar;
