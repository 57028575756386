import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetSuggestedTagsResponse } from "../../constants/types";

const handleRequest = async (advisorID?: string) => {
  const apiUrl = qs.stringifyUrl({
    url: "/suggested-document-attributes",
    query: {
      advisor_id: advisorID,
    },
  });
  const { data } = await axios.get<GetSuggestedTagsResponse>(apiUrl);
  return data;
};

export default function useSuggestedDocumentTags(advisorID?: string) {
  return useQuery<GetSuggestedTagsResponse>(
    ["suggestedDocumentTags", advisorID],
    () => handleRequest(advisorID)
  );
}
