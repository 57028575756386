import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import { Cookies } from "react-cookie";
import {
  advisorId,
  defaultColor,
  faviconMeta,
  titleMeta,
} from "../../constants/common";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useAdvisorDetail from "../../services/queries/useAdvisorDetail";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import ModalEditName from "../../components/ModalEditName/ModalEditName";
import ModalEditEmail from "../../components/ModalEditEmail/ModalEditEmail";
import ModalEditTags from "../../components/ModalEditTags/ModalEditTags";
import ModalEditAvatar from "../../components/ModalEditAvatar/ModalEditAvatar";
import { TraceSpinner } from "react-spinners-kit";
import Switch from "@mui/material/Switch";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

interface IValues {
  aiTraining: boolean;
  dataSharing: boolean;
  notification: string;
}

const schema = yup.object({
  aiTraining: yup.boolean().required(),
  dataSharing: yup.boolean().required(),
  notification: yup.string().required("This field is required"),
});

const AdvisorProfilePage = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const {
    data: advisorDetail,
    refetch: refetchAdvisorDetail,
    isLoading: isLoadingAdvisorDetail,
    isError: isErrorAdvisorDetail,
  } = useAdvisorDetail(advisorID);
  const [isOpenModalEditName, setIsOpenModalEditName] = useState(false);
  const [isOpenModalEditEmail, setIsOpenModalEditEmail] = useState(false);
  const [isOpenModalEditTags, setIsOpenModalEditTags] = useState(false);
  const [isOpenModalEditAvatar, setIsOpenModalEditAvatar] = useState(false);

  const openModalEditName = () => setIsOpenModalEditName(true);
  const closeModalEditName = () => setIsOpenModalEditName(false);
  const openModalEditEmail = () => setIsOpenModalEditEmail(true);
  const closeModalEditEmail = () => setIsOpenModalEditEmail(false);
  const openModalEditTags = () => setIsOpenModalEditTags(true);
  const closeModalEditTags = () => setIsOpenModalEditTags(false);
  const openModalEditAvatar = () => setIsOpenModalEditAvatar(true);
  const closeModalEditAvatar = () => setIsOpenModalEditAvatar(false);

  const initialValues: IValues = useMemo(
    () => ({
      aiTraining: false,
      dataSharing: false,
      notification: "",
    }),
    []
  );

  const {
    // handleSubmit,
    control,
    reset,
    // formState: { errors },
  } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const handleChangeAI = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const body = {
        id: advisorID,
        pereferences: {
          ai_training: event.target.checked,
        },
      };
      const res = await axios.patch(`/advisor/${advisorID}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: event.target.checked
            ? `AI Training is now enabled`
            : "AI Training is now disabled",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        refetchAdvisorDetail();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeDataSharing = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const body = {
        id: advisorID,
        pereferences: {
          data_sharing: event.target.checked,
        },
      };
      const res = await axios.patch(`/advisor/${advisorID}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: event.target.checked
            ? `Data sharing is now enabled`
            : "Data sharing is now disabled",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        refetchAdvisorDetail();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeNotification = async (event: SelectChangeEvent<string>) => {
    try {
      const body = {
        id: advisorID,
        pereferences: {
          notification: event.target.value,
        },
      };
      console.log(body);
      const res = await axios.patch(`/advisor/${advisorID}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: "Notification method succesfully changed",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        refetchAdvisorDetail();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (advisorDetail && advisorDetail?.preferences) {
      reset({
        aiTraining: advisorDetail.preferences.ai_training,
        dataSharing: advisorDetail.preferences.data_sharing,
        notification: advisorDetail.preferences.notification,
      });
    }
  }, [advisorDetail, reset]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchAdvisorDetail();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchAdvisorDetail]);

  if (isLoadingAdvisorDetail) {
    return (
      <Container>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <TraceSpinner
            frontColor={defaultColor}
            backColor="#4b4c56"
            loading={true}
          />
        </Stack>
      </Container>
    );
  }

  if (isErrorAdvisorDetail) {
    return (
      <Container sx={{ minHeight: "80vh" }}>
        <Stack spacing={3} padding={2}>
          {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              variant="body2"
              fontSize={12}
              underline="hover"
              color="inherit"
              href="/"
            >
              Home
            </Link>
            <Link
              variant="body2"
              fontSize={12}
              underline="hover"
              color="inherit"
              href="/"
            >
              Advisor
            </Link>
            <Typography variant="body2" fontSize={12} color="text.primary">
              My account
            </Typography>
          </Breadcrumbs> */}
          <Typography variant="h4" fontWeight={700}>
            My Account
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="center"
            padding={3}
            gap={2}
          >
            <Typography variant="h5" fontWeight={700}>
              No data
            </Typography>
          </Stack>
        </Stack>
      </Container>
    );
  }

  return (
    <Container sx={{ minHeight: "80vh" }}>
      <Stack
        spacing={3}
        padding={isMobileScreen ? undefined : 2}
        paddingTop={isMobileScreen ? 2 : undefined}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            onClick={handleGoBack}
            sx={{ cursor: "pointer" }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <ChevronLeftIcon fontSize="small" />
              Go back
            </Stack>
          </Link>
        </Breadcrumbs>
        {/* <Typography variant="h4" fontWeight={700}>
          My Account
        </Typography> */}
        {isLoadingAdvisorDetail ? (
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <React.Fragment>
            <Stack
              border="1px solid #cdcdcd"
              borderRadius={1}
              padding={isMobileScreen ? 2 : 3}
            >
              <Stack borderBottom="1px solid #cdcdcd" paddingY={1.5}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={3}>
                    <Typography fontWeight={700}>Name</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>{advisorDetail?.name}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        onClick={openModalEditName}
                        color="buttonlightgreen"
                        size="small"
                      >
                        Edit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
              <Stack borderBottom="1px solid #cdcdcd" paddingY={1.5}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={3}>
                    <Typography fontWeight={700}>Email</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>{advisorDetail?.email}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        onClick={openModalEditEmail}
                        color="buttonlightgreen"
                        size="small"
                      >
                        Edit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
              {advisorDetail && advisorDetail.tags ? (
                <Stack borderBottom="1px solid #cdcdcd" paddingY={1.5}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={3}>
                      <Typography fontWeight={700}>Tags</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Stack direction="row" flexWrap="wrap" gap={1}>
                        {advisorDetail?.tags.length > 0 ? (
                          advisorDetail?.tags.map((ta) => (
                            <Chip key={ta} label={ta} size="small" />
                          ))
                        ) : (
                          <Typography>No tags...</Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Stack direction="row" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          onClick={openModalEditTags}
                          color="buttonlightgreen"
                          size="small"
                        >
                          {advisorDetail.tags.length > 0 ? "Edit" : "Add"}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              ) : null}
              <Stack paddingY={1.5}>
                <Grid container alignItems="flex-start">
                  <Grid item xs={12} md={3}>
                    <Typography fontWeight={700}>Avatar</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        width: 100,
                        height: 100,
                      }}
                      alt={advisorDetail?.name}
                      src={advisorDetail?.avatar_photo}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        onClick={openModalEditAvatar}
                        color="buttonlightgreen"
                        size="small"
                      >
                        Edit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>

            {advisorDetail && advisorDetail.preferences ? (
              <Stack
                border="1px solid #cdcdcd"
                borderRadius={1}
                padding={isMobileScreen ? 2 : 3}
              >
                <Stack paddingY={1.5}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12} md={3}>
                      <Typography fontWeight={700}>Preferences</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Stack spacing={1.5}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={1}
                        >
                          <Typography>AI Training</Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            {isMobileScreen ? null : (
                              <Typography>Disable</Typography>
                            )}
                            <Controller
                              name="aiTraining"
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    handleChangeAI(e);
                                  }}
                                />
                              )}
                            />
                            {isMobileScreen ? null : (
                              <Typography>Enable</Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={1}
                        >
                          <Typography>Data Sharing</Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            {isMobileScreen ? null : (
                              <Typography>Disable</Typography>
                            )}
                            <Controller
                              name="dataSharing"
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    handleChangeDataSharing(e);
                                  }}
                                />
                              )}
                            />
                            {isMobileScreen ? null : (
                              <Typography>Enable</Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={1}
                        >
                          <Typography>Notification</Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Controller
                              name="notification"
                              control={control}
                              render={({ field, fieldState }) => (
                                <Select
                                  placeholder="Choose notification method"
                                  margin="dense"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    handleChangeNotification(e);
                                  }}
                                  size="small"
                                  value={field.value}
                                  error={Boolean(fieldState.error)}
                                  sx={{
                                    minWidth: 150,
                                  }}
                                >
                                  <MenuItem value={"EMAIL"}>Email</MenuItem>
                                  <MenuItem value={"SATELLITE"}>
                                    Satellite
                                  </MenuItem>
                                </Select>
                              )}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            ) : null}
          </React.Fragment>
        )}
      </Stack>
      <ModalEditName
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalEditName}
        onClose={closeModalEditName}
        name={advisorDetail?.name ?? ""}
      />
      <ModalEditEmail
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalEditEmail}
        onClose={closeModalEditEmail}
        email={advisorDetail?.email ?? ""}
      />
      <ModalEditTags
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalEditTags}
        onClose={closeModalEditTags}
        tags={advisorDetail?.tags ?? []}
        isEdit={advisorDetail?.tags && advisorDetail?.tags.length > 0}
      />
      <ModalEditAvatar
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalEditAvatar}
        onClose={closeModalEditAvatar}
        avatar={advisorDetail?.avatar_photo ?? ""}
      />
    </Container>
  );
};

export default AdvisorProfilePage;
