import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { ReactComponent as OrangeBannerAltDesktop } from "../../assets/images/OrangeBannerAltDesktop.svg";
import LandingBanner2 from "../../assets/images/LandingBanner2.jpg";
import BenefitIllust from "../../assets/images/BenefitIllust.jpg";
import RegisterIllust from "../../assets/images/RegisterIllust.jpg";
import ObjDesktop from "../../assets/images/ObjDesktop.png";
import Arrow1 from "../../assets/images/Arrow1.png";
import Arrow2 from "../../assets/images/Arrow2.png";
import Arrow3 from "../../assets/images/Arrow3.png";
import Arrow4 from "../../assets/images/Arrow4.png";
import Arrow5 from "../../assets/images/Arrow5.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Fab from "@mui/material/Fab";
import { KeyboardArrowUp } from "@mui/icons-material";
import BecomeAdvisorSteps from "../../components/BecomeAdvisorSteps/BecomeAdvisorSteps";

const becomeAdvisorSteps = [
  {
    title: "Expression of interest",
    src: Arrow1,
    contents: [
      "Send us your ASIC Financial Advisor licence number; or",
      "Send us your group ASIC Financial Advisor licencee number",
    ],
  },
  {
    title: "Verification",
    src: Arrow2,
    contents: ["We'll get your details from ASIC"],
  },
  {
    title: "Choose a monthly plan",
    src: Arrow3,
    contents: [
      "Select a monthly premium plan based on your purpose",
      "Don't worry, you can change your plan anytime",
    ],
  },
  {
    title: "Onboarded",
    src: Arrow4,
    contents: ["It's that easy."],
  },
  {
    title: "Growth",
    src: Arrow5,
    contents: [
      "Import your member's data",
      "Set goals for your member and track progress",
    ],
  },
];

const BecomeAdvisorPage = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Stack>
        <Container maxWidth="xl" sx={{ px: "0 !important" }}>
          <Stack direction="row" justifyContent="flex-end" position="relative">
            <img
              src={LandingBanner2}
              alt="banner"
              style={{
                // backgroundColor: "#000",
                // backgroundPosition: "20% top",
                objectPosition: "20% top",
                backgroundSize: "cover",
                color: "#fff",
                height: "21.5rem",
                zIndex: 1,
                padding: 0,
                width: "60%",
                objectFit: "cover",
              }}
            />
            <Box
              position="absolute"
              top="40%"
              left={{ xs: "5%", lg: "12%" }}
              zIndex={2}
            >
              <Typography variant="h4" color="#fff">
                Become a financial advisers
              </Typography>
            </Box>
            <Box
              position="absolute"
              top={0}
              left={{ xs: -500, md: -200, lg: 0 }}
              zIndex={1}
              sx={{
                transition: "all 200ms ease-out",
              }}
            >
              <OrangeBannerAltDesktop height="100%" />
            </Box>
          </Stack>
        </Container>
        <Container maxWidth="lg" sx={{ mt: 3 }}>
          <Typography variant="h6" fontWeight={700} maxWidth="500px">
            Our shared goal is to help people achieve their best possible
            retirement, so like you, everything we do is in the best interest of
            members.
          </Typography>
          <Grid container bgcolor="#F6F6F6" sx={{ mt: 3 }}>
            <Grid
              item
              xs={12}
              md={6}
              position="relative"
              minHeight={{ xs: "435px", md: undefined }}
            >
              <img
                src={BenefitIllust}
                alt="illust"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  objectPosition: "50%",
                  color: "#fff",
                  height: "100%",
                  zIndex: 1,
                  padding: 0,
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              p={{ xs: 3, md: 8 }}
              minHeight={{ xs: undefined, md: "435px" }}
            >
              <Typography variant="h5" fontWeight={700}>
                Benefits of registration
              </Typography>
              <Typography mt={2}>
                Consolidate to have a clear view of your member's financial
                goals.
              </Typography>
              <Typography mt={2}>
                Early access to our pioneering artifictual intellence (AI) based
                optimised financial recommendation. So you can identify your
                unique selling points to build your effective value proposition.
              </Typography>
              <Typography mt={2}>
                Once registered, you have access to our leading software your
                advisor experience and grow your business.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            bgcolor="#F6F6F6"
            direction={{ xs: "row", md: "row-reverse" }}
          >
            <Grid
              item
              xs={12}
              md={6}
              position="relative"
              minHeight={{ xs: "435px", md: undefined }}
            >
              <img
                src={RegisterIllust}
                alt="illust"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  objectPosition: "50%",
                  color: "#fff",
                  height: "100%",
                  zIndex: 1,
                  padding: 0,
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              p={{ xs: 3, md: 8 }}
              minHeight={{ xs: undefined, md: "435px" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" fontWeight={700}>
                Register with us
              </Typography>
              <Typography mt={2}>
                Register as an individual adviser or as a licensee.
              </Typography>
              <Button
                variant="outlined"
                size="large"
                endIcon={<DownloadIcon />}
                sx={{ mt: 2, width: "fit-content" }}
              >
                Start Here
              </Button>
            </Grid>
          </Grid>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(20rem, 1fr))"
            columnGap={2}
            rowGap={4}
            mt={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              paddingTop={3}
              bgcolor="#F4EDD3"
              position="relative"
              minHeight={"350px"}
              gap={2}
              borderRadius={2}
            >
              <Typography variant="h5" textAlign="center">
                Your onboarding journey
              </Typography>
              <img
                src={ObjDesktop}
                alt="illust"
                style={{
                  color: "#fff",
                  zIndex: 1,
                  padding: 0,
                  width: "100%",
                }}
              />
            </Box>
            {becomeAdvisorSteps.map((li, idx) => (
              <BecomeAdvisorSteps
                stepNo={idx + 1}
                title={li.title}
                src={li.src}
                contents={li.contents}
              />
            ))}
          </Box>
        </Container>
      </Stack>
      <Box
        display={showButton ? "block" : "none"}
        position="fixed"
        right={30}
        bottom={30}
      >
        <Fab color="buttonwhite" onClick={scrollToTop}>
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </React.Fragment>
  );
};

export default BecomeAdvisorPage;
