import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";

interface ITagsCheckboxProps {
  options: any[];
  getOptionDisabled?: ((option: any) => boolean) | undefined;
  value: any;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  freeSolo: boolean;
  placeholder: string;
  error: boolean;
  helperText: React.ReactNode;
}

const TagsCheckbox = ({
  options,
  getOptionDisabled,
  value,
  onChange,
  freeSolo,
  placeholder,
  error,
  helperText,
}: ITagsCheckboxProps) => {
  return (
    <Autocomplete
      multiple
      limitTags={2}
      disableCloseOnSelect
      options={options}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderTags={(tagValue) =>
        tagValue.map((option, index, array) => (
          <Typography ml={1}>
            {index === array.length - 1 ? option : `${option}, `}
          </Typography>
        ))
      }
      renderOption={(props, option, { selected, inputValue }) => {
        const matches = match(option, inputValue, {
          insideWords: true,
        });
        const parts = parse(option, matches);

        return (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{
                marginRight: 8,
              }}
              checked={selected}
            />
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      value={value}
      onChange={onChange}
      freeSolo={freeSolo}
      popupIcon={null}
      forcePopupIcon={true}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          size="small"
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            sx: {
              backgroundColor: "#fff",
            },
          }}
        />
      )}
    />
  );
};

export default TagsCheckbox;
