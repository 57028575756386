import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import { titleMeta, faviconMeta, licenseeAlias } from "../../constants/common";
import { ParamsGetBusinessData } from "../../constants/types";
import { useFetching } from "../../context/fetchingContext";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataGridCustom from "../../components/DataGridCustom/DataGridCustom";
import { GridColDef } from "@mui/x-data-grid";
import useServiceRequest from "../../services/queries/useServiceRequest";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Add } from "@mui/icons-material";
import ModalServiceRequest from "../../components/ModalServiceRequest/ModalServiceRequest";
import Grid from "@mui/material/Grid";
import { ReactComponent as ServiceRequestIllust } from "../../assets/images/ServiceRequestIllust.svg";
import Chip from "@mui/material/Chip";

const LicenseeServiceRequest = () => {
  const cookies = useMemo(() => new Cookies(), []);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const [alias, setAlias] = useState("");
  const [isOpenModalServiceRequest, setIsOpenModalServiceRequest] =
    useState(false);

  const initialParamsServiceRequest = {
    businessAlias: alias,
    filter: "service-request",
  };

  const [paramsServiceRequest, setParamsServiceRequest] =
    useState<ParamsGetBusinessData>(initialParamsServiceRequest);
  const {
    data: serviceRequest,
    refetch: refetchServiceRequest,
    isLoading: isLoadingServiceRequest,
  } = useServiceRequest(paramsServiceRequest);

  const openModalServiceRequest = () => {
    setIsOpenModalServiceRequest(true);
  };

  const closeModalServiceRequest = () => {
    setIsOpenModalServiceRequest(false);
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      //   const logo = await cookies.get(logoMeta);
      const getLicenseeAlias = await cookies.get(licenseeAlias);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      //   if (logo) {
      //     setLogo(logo);
      //   }
      if (getLicenseeAlias) {
        setAlias(getLicenseeAlias);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (alias) {
      setParamsServiceRequest((prev) => ({
        ...prev,
        businessAlias: alias,
      }));
    }
  }, [alias]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchServiceRequest();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchServiceRequest]);

  const columnsData: GridColDef[] = [
    {
      field: "modified_on",
      headerName: "Modified on",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "message",
      headerName: "Message",
      headerAlign: "left",
      align: "left",
      minWidth: 300,
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return <Chip label={params.value} color="buttongreen" />;
      },
    },
  ];

  return (
    <Box minHeight="80vh">
      <Stack paddingX={isLaptopScreen ? undefined : 3}>
        <Grid
          container
          padding={5}
          bgcolor="#E9E6EC"
          maxHeight={isLaptopScreen ? undefined : "300px"}
        >
          <Grid item xs={12} md={6} xl={4}>
            <Typography
              variant={isMobileScreen ? "h4" : "h3"}
              fontWeight={700}
              paddingLeft={isLaptopScreen ? undefined : 25}
            >
              Service Request
            </Typography>
          </Grid>
          <Grid item xs={0} xl={2}></Grid>
          <Grid item xs={12} md={6} xl={6} textAlign="center">
            <ServiceRequestIllust
              style={{
                height: "fit-content",
                width: "50%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container padding={5}>
          <Grid item xs={12} md={6} xl={5}>
            <Typography
              variant={isMobileScreen ? "body1" : "h5"}
              fontSize={isMobileScreen ? undefined : 20}
              fontWeight={700}
              paddingLeft={isLaptopScreen ? undefined : 25}
            >
              Got a question, request or feedback? Ask us and we will get back
              to you.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} xl={7}></Grid>
        </Grid>
      </Stack>
      <Stack direction="row" bgcolor="#E9E6EC">
        <Container maxWidth="lg">
          <Stack spacing={3} py={3}>
            <Card>
              <CardContent sx={{ p: 3 }}>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      disableElevation
                      variant="contained"
                      color="buttongreen"
                      onClick={openModalServiceRequest}
                      size="medium"
                      sx={{
                        borderRadius: 50,
                        fontWeight: 700,
                        width: "fit-content",
                      }}
                    >
                      New Request
                    </Button>
                  </Stack>
                  <DataGridCustom
                    loading={isLoadingServiceRequest}
                    rows={serviceRequest?.service_requests ?? []}
                    columns={columnsData}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      </Stack>
      <ModalServiceRequest
        id={String(serviceRequest?.id)}
        fullScreen={isMobileScreen}
        isOpenModalServiceRequest={isOpenModalServiceRequest}
        closeModalServiceRequest={closeModalServiceRequest}
      />
    </Box>
  );
};

export default LicenseeServiceRequest;
