import React, { useCallback, useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import axios from "../../services/axios";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TraceSpinner } from "react-spinners-kit";
import { secondaryColor } from "../../constants/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalTitle from "../ModalTitle/ModalTitle";
import EditIcon from "@mui/icons-material/Edit";

interface IModalEditDocumentProps {
  fullScreen: boolean;
  isOpenModalEditDocument: boolean;
  closeModalEditDocument: () => void;
  documentId: string;
}

const ModalEditDocument = ({
  fullScreen,
  isOpenModalEditDocument,
  closeModalEditDocument,
  documentId,
}: IModalEditDocumentProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setIsDropzoneLoading(true);
    try {
      const formData = new FormData();
      if (acceptedFiles[0]) {
        formData.append("filename", acceptedFiles[0] as any);
      }
      const res = await axios.post(`/upload-document`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 201 || res.status === 200) {
        console.log("documentUrl", res.data.url);
        setDocumentName(acceptedFiles[0].name);
        setDocumentUrl(res.data.url);
      }
      setIsDropzoneLoading(false);
    } catch (error) {
      setIsDropzoneLoading(false);
      console.error(error);
    }
  }, []);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: isDropzoneLoading || isButtonLoading ? true : false,
  });

  const onSubmit = async () => {
    setIsButtonLoading(true);

    try {
      const response = await axios.patch(`document/${documentId}`, {
        url: documentUrl,
      });
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: `Successfully uploaded document`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setFetchingItems();
        closeModalEditDocument();
        setDocumentName("");
        setDocumentUrl("");
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={isOpenModalEditDocument}
      onClose={() => {
        closeModalEditDocument();
        setDocumentName("");
        setDocumentUrl("");
      }}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle
        onClose={() => {
          closeModalEditDocument();
          setDocumentName("");
          setDocumentUrl("");
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <EditIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Upload document
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers sx={{ pb: 8 }}>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Stack>
              {/* <Typography>Upload File:</Typography> */}
              <Stack
                direction="row"
                justifyContent="center"
                border={"1px solid rgba(0,0,0,0.2)"}
                borderRadius={2}
                padding={3}
                bgcolor={"#FFF"}
                sx={{
                  opacity:
                    isDragAccept || isDropzoneLoading || isButtonLoading
                      ? 0.5
                      : 1,
                }}
                {...getRootProps({ className: "dropzone" })}
              >
                {isDropzoneLoading ? (
                  <Stack spacing={2} alignItems="center">
                    <TraceSpinner
                      frontColor={secondaryColor}
                      backColor="#4b4c56"
                      loading={isDropzoneLoading}
                    />
                    <Typography variant="h6">Please wait…</Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <input {...getInputProps()} />
                    <Stack spacing={3}>
                      <FontAwesomeIcon
                        icon={"file-arrow-up"}
                        color="#2C3B55"
                        size="5x"
                      />
                      <Stack
                        direction={fullScreen ? "column" : "row"}
                        alignItems="center"
                        gap={0.5}
                      >
                        <Typography>Drag and drop files or</Typography>
                        <Link onClick={open} sx={{ cursor: "pointer" }}>
                          browse document
                        </Link>
                      </Stack>
                      {documentName && documentUrl && (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <h4>File:</h4>
                          <Link
                            href={`${documentUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            textAlign="center"
                          >
                            {documentName}
                          </Link>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          disabled={!documentUrl}
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={onSubmit}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditDocument;
