import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

interface IFooterLinksProps {
  alternative?: boolean;
}

const FooterLinks = ({ alternative }: IFooterLinksProps) => {
  const theme = useTheme();
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const today = new Date();
  const thisYear = today.getFullYear();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={2}
      justifyContent="center"
      // px={alternative ? undefined : isLaptopScreen ? 3 : 19}
      mb={3}
    >
      <Typography variant="subtitle2" fontSize={12}>
        © {thisYear} Advisor & Co, ABN 28 147 052 524.
      </Typography>
      <Link variant="subtitle2" fontSize={12}>
        Privacy Policy
      </Link>
      <Link variant="subtitle2" fontSize={12}>
        Terms of Use & Disclaimer
      </Link>
    </Box>
  );
};

export default FooterLinks;
