import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetRandomBusinessResponse } from "../../constants/types";

const handleRequest = async (count: number) => {
  const urlApi = qs.stringifyUrl({
    url: `business/random/${count}`,
  });
  const { data } = await axios.get<GetRandomBusinessResponse[]>(urlApi);
  return data;
};

export default function useRandomBusiness(count: number) {
  return useQuery<GetRandomBusinessResponse[]>(
    ["randomBusiness", count],
    () => handleRequest(count),
    {
      enabled: !!count,
      refetchOnWindowFocus: false,
    }
  );
}
