import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
// import { Cookies } from "react-cookie";
import { useHistory } from "react-router-dom";

interface IModalLogoutProps {
  open: boolean;
  onClose: () => void;
}

const ModalLogout = ({ open, onClose }: IModalLogoutProps) => {
  const history = useHistory();
  //   const cookies = useMemo(() => new Cookies(), []);

  const onYes = () => {
    // cookies.remove(licenseeAlias);
    // cookies.remove(emailSenderId);
    // cookies.remove(emailHeaderBanner);
    // cookies.remove(emailFooterBanner);
    // cookies.remove(titleMeta);
    // cookies.remove(faviconMeta);
    // cookies.remove(logoMeta);
    // cookies.remove(bannerMeta);
    // cookies.remove(backgroundMeta);
    // cookies.remove(workbookEditionMeta);
    // cookies.remove(featureFlagAnnouncement);
    // cookies.remove(featureFlagWelcomeMessage);
    history.push("/welcome");
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" fontWeight="bold">
            Logout?
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to logout?</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Stack direction="row" gap={2}>
          <Button onClick={onYes} variant="contained" color="buttonlightgreen">
            Yes
          </Button>
          <Button onClick={onClose} variant="contained" color="buttongreen">
            No
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ModalLogout;
