import React from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Delete, Upload } from "@mui/icons-material";

interface IDataGridMenuDocumentListProps {
  onEdit?: () => void;
  onDelete?: () => void;
}

const DataGridMenuDocumentList = ({
  onEdit,
  onDelete,
}: IDataGridMenuDocumentListProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    if (!onEdit) return;

    onEdit();
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (!onDelete) return;

    onDelete();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizOutlinedIcon sx={{ color: "#015A06" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            border: "1px solid rgba(69, 69, 69, 0.24)",
            borderRadius: "8px",
            boxShadow: "0px 2px 4px 0px rgba(91, 91, 91, 0.16)",
          },
        }}
        elevation={0}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Upload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Upload document</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete document</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DataGridMenuDocumentList;
