import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { AreaType } from "../../constants/types";

interface IARInputProps {
  data: AreaType;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  selectedAreas: AreaType[];
}

const ARInput = ({ data, onClick, selectedAreas }: IARInputProps) => {
  const isSelected = selectedAreas.findIndex((c) => c.id === data.id) >= 0;

  return (
    <Stack
      onClick={onClick}
      component={Paper}
      display="flex"
      direction="row"
      alignItems="center"
      sx={{
        backgroundImage: "linear-gradient(90deg, #ffffff 5rem, #eeeeee 5rem)",
        cursor: "pointer",
        border: isSelected ? "1px solid #EA4403" : "1px solid #ededed",
        transition: "all 200ms ease-out",
        "&:hover": {
          opacity: isSelected ? undefined : 0.5,
        },
      }}
    >
      <Box width="33%" p={1.5}>
        <img
          width="55px"
          height="55px"
          loading="eager"
          src={data.src}
          alt={data.name}
        />
      </Box>
      <Box
        width="67%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding={3}
      >
        <Typography>{data.name}</Typography>
      </Box>
    </Stack>
  );
};

export default ARInput;
