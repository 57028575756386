import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFetching } from "../../context/fetchingContext";
import { Cookies } from "react-cookie";
import useAssociate from "../../services/queries/useAssociate";
import {
  GetAssociateResponse,
  ParamsGetAssociate,
} from "../../constants/types";
import {
  advisorId,
  faviconMeta,
  titleMeta,
  workbookEditionMeta,
} from "../../constants/common";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ModalAddAssociate from "../../components/ModalAddAssociate/ModalAddAssociate";

const AssociatePage = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const [workbookEdition, setWorkbookEdition] = useState("");
  const initialParams = {
    advisorId: undefined,
  };
  const [paramsAssociate, setParamsAssociate] =
    useState<ParamsGetAssociate>(initialParams);
  const {
    data: associate,
    refetch: refetchAssociate,
    isLoading: isLoadingAssociate,
  } = useAssociate(paramsAssociate);
  const [search, setSearch] = useState("");
  const [isOpenModalAddAssociate, setIsOpenModalAddAssociate] = useState(false);
  const [associateDetail, setAssociateDetail] =
    useState<GetAssociateResponse>();

  const openModalAddAssociate = (data?: GetAssociateResponse) => {
    setAssociateDetail(data);
    setIsOpenModalAddAssociate(true);
  };
  const closeModalAddAssociate = () => setIsOpenModalAddAssociate(false);

  let filteredAssociate =
    associate &&
    associate.filter((li) => {
      const s = li.name.toLowerCase();
      const t = li.tags;
      return (
        s.includes(search.toLowerCase()) || t.includes(search.toLowerCase())
      );
    });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      const getWorkbookEdition = await cookies.get(workbookEditionMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
      if (getWorkbookEdition) {
        setWorkbookEdition(getWorkbookEdition);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (advisorID) {
      setParamsAssociate((prev) => ({
        ...prev,
        advisorId: advisorID,
      }));
    }
  }, [advisorID]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchAssociate();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchAssociate]);

  return (
    <Container>
      <Stack spacing={3} padding={2}>
        <Box>
          <TextField
            fullWidth
            placeholder="Search"
            value={search}
            size="small"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#fff",
              },
            }}
          />
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          gap={3}
        >
          <Box
            component={ListItemButton}
            onClick={() => openModalAddAssociate()}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            border="1px dashed #B7B7B7"
            borderRadius="6px"
            gap={2}
            padding={3}
            sx={{
              background: "#f2f7f1",
              cursor: "pointer",
              minHeight: "300px",
            }}
          >
            <AddIcon
              htmlColor="#B7B7B7"
              sx={{ width: "56px", height: "56px" }}
            />
            <Typography
              variant="h6"
              // fontWeight="bold"
              fontSize="21px"
              color="#B7B7B7"
            >
              Add Associate
            </Typography>
          </Box>
          {isLoadingAssociate && (
            <Stack padding={5} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
          {filteredAssociate?.map((li, idx) => (
            <Box
              key={idx.toString()}
              component={ListItemButton}
              display="block"
              gap={3}
              onClick={() => openModalAddAssociate(li)}
              sx={{
                border: "1px solid #B7B7B7",
                background: "#f2f7f1",
                borderRadius: "6px",
                cursor: "pointer",
                padding: 0,
                minHeight: 300,
              }}
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                gap={2}
                padding={3}
              >
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 50,
                    height: 50,
                  }}
                  alt={li.name}
                  src={li.avatar_photo}
                />
                <Stack>
                  <Typography variant="h6" fontWeight={500}>
                    {li.name}
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" gap={1}>
                    {li.tags.map((ta) => (
                      <Chip
                        key={ta}
                        label={
                          <Typography variant="body2" fontWeight={500}>
                            {ta}
                          </Typography>
                        }
                        color="chiplightgray"
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          ))}
        </Box>
      </Stack>
      <ModalAddAssociate
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalAddAssociate}
        onClose={closeModalAddAssociate}
        associateDetail={associateDetail}
      />
    </Container>
  );
};

export default AssociatePage;
