import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ThemeComponent from "../components/ThemeComponent/ThemeComponent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InboxIcon from "@mui/icons-material/Inbox";
import MailIcon from "@mui/icons-material/Mail";
import { useCallback, useEffect, useMemo, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import FooterLinks from "../components/FooterLinks/FooterLinks";
import { Cookies } from "react-cookie";
import { faviconMeta, logoMeta, titleMeta } from "../constants/common";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  children: React.ReactNode;
  // window?: () => Window;
}

export default function PageLayout({ children }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(false);
  const cookies = useMemo(() => new Cookies(), []);
  const [logo, setLogo] = useState("");

  const drawerWidth = "100%";

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <ThemeComponent>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position={isMobileScreen ? "fixed" : "static"}
          elevation={0}
          sx={{
            backgroundColor: "#fff",
            // paddingY: isMobileScreen ? 1 : 3,
            paddingX: isMobileScreen ? 3 : undefined,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          {isMobileScreen ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box />
              <img
                src={logo as string}
                alt="banner"
                style={{
                  backgroundSize: "cover",
                  color: "#fff",
                  height: "100px",
                  zIndex: 1,
                  padding: 0,
                  width: "100%",
                  objectFit: "contain",
                }}
              />
              <IconButton
                color="primary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          ) : (
            <Container maxWidth="lg">
              <Toolbar>
                <Box display="flex" gap={2} alignItems="center">
                  <img
                    src={logo as string}
                    alt="banner"
                    style={{
                      backgroundSize: "cover",
                      color: "#fff",
                      height: "100px",
                      zIndex: 1,
                      padding: 0,
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box display="flex" gap={4} alignItems="center">
                  <Link underline="hover" variant={"body2"} fontWeight={700}>
                    ABOUT US
                  </Link>
                  <Link underline="hover" variant={"body2"} fontWeight={700}>
                    CONTACT US
                  </Link>
                  <Box display="flex" gap={2} alignItems="center">
                    <Button
                      disableElevation
                      variant="contained"
                      size={isLaptopScreen ? "small" : "large"}
                      color="buttonyellow"
                      href="/welcome"
                      sx={{
                        borderRadius: 50,
                        fontWeight: 700,
                        width: isLaptopScreen ? "6rem" : "8rem",
                      }}
                      // key={page}
                      // onClick={handleCloseNavMenu}
                    >
                      Login
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      size={isLaptopScreen ? "small" : "large"}
                      color="buttonorange"
                      sx={{
                        borderRadius: 50,
                        fontWeight: 700,
                        width: isLaptopScreen ? "6rem" : "8rem",
                      }}
                      // key={page}
                      // onClick={handleCloseNavMenu}
                    >
                      Join
                    </Button>
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          )}
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          // aria-label="mailbox folders"
        >
          <Drawer
            anchor="right"
            container={container}
            variant="temporary"
            open={open}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
              flexShrink: 0,
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, pt: isMobileScreen ? 12 : undefined }}
        >
          {children}
        </Box>
        <Grid
          container
          alignItems="center"
          px={isLaptopScreen ? undefined : 19}
          mt={16}
          pb={3}
        >
          <Grid item xs={12} lg={1.5} px={isLaptopScreen ? 3 : undefined}>
            <Button variant="text">About Us</Button>
          </Grid>
          <Grid item xs={12} display={isLaptopScreen ? undefined : "none"}>
            <Divider />
          </Grid>
          <Grid item xs={12} lg={1.5} px={isLaptopScreen ? 3 : undefined}>
            <Button variant="text">Careers</Button>
          </Grid>
          <Grid item xs={12} display={isLaptopScreen ? undefined : "none"}>
            <Divider />
          </Grid>
          <Grid item xs={12} lg={1.5} px={isLaptopScreen ? 3 : undefined}>
            <Button variant="text">For Advisers</Button>
          </Grid>
          <Grid item xs={12} display={isLaptopScreen ? undefined : "none"}>
            <Divider />
          </Grid>
          <Grid item xs={0} lg={1.5}></Grid>
          <Grid
            item
            xs={12}
            lg={6}
            display={isLaptopScreen ? "none" : undefined}
          >
            <Typography>
              AustralianSuper is a profit-for-member fund. This means we don’t
              pay profits or dividends to shareholders, so profit we make is for
              members.
            </Typography>
          </Grid>
        </Grid>
        <Box display={isLaptopScreen ? "none" : undefined} px={19} mb={3}>
          <Divider />
        </Box>
        <Box px={isLaptopScreen ? 3 : 19} mb={3}>
          <Grid container>
            <Grid item xs={12} md={6} xl={4}>
              <Typography variant="subtitle2" fontSize={10}>
                The information shown on this website is general information
                only. We haven’t taken into account your needs or personal
                objectives when providing the information. You should assess
                your own financial situation and needs and read the relevant
                Product Disclosure Statement before making a decision about
                products on this website. A Target Market Determination (TMD) is
                a document that outlines the target market a product has been
                designed for. Find the TMDs at australiansuper.com/tmd. This
                website is provided by AustralianSuper Pty Ltd ABN 94 006 457
                987, AFSL 233788, Trustee of AustralianSuper ABN 65 714 394 898
                Superannuation Fund Number (SFN): 2683 519 45, Superannuation
                Product Identification Number (SPIN): STA0100AU.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <FooterLinks />
      </Box>
    </ThemeComponent>
  );
}
