import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import { titleMeta, faviconMeta, advisorId } from "../../constants/common";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import useMemberDetail from "../../services/queries/useMemberDetail";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as HomePageIllust } from "../../assets/images/HomePageIllust.svg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import useAdvisorServices from "../../services/queries/useAdvisorServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import {
  ConfigItem,
  GetGoalTypes,
  GetMemberResponse,
  ParamsGetMember,
  ParamsGetMemberDetail,
} from "../../constants/types";
import CheckIcon from "@mui/icons-material/Check";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Form from "../../components/Form/Form";
import TextField from "@mui/material/TextField";
import useMember from "../../services/queries/useMember";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import ModalSendEmail from "../../components/ModalSendEmail/ModalSendEmail";
import { useHistory } from "react-router-dom";
import ModalAreYouSure from "../../components/ModalAreYouSure/ModalAreYouSure";
import Grid2 from "@mui/material/Unstable_Grid2";
import TagsCheckbox from "../../components/TagsCheckbox/TagsCheckbox";

const MemberAddGoalPage = () => {
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const initialParamsMemberDetail = {
    memberID: id,
    documentID: undefined,
  };
  const [paramsMemberDetail, setParamsMemberDetail] =
    useState<ParamsGetMemberDetail>(initialParamsMemberDetail);
  const {
    data: memberDetail,
    // refetch: refetchMemberDetail,
    // isLoading: isLoadingMemberDetail,
  } = useMemberDetail(paramsMemberDetail);

  const initialParams = {
    advisorId: undefined,
  };
  const [paramsMember, setParamsMember] =
    useState<ParamsGetMember>(initialParams);
  const { data: member } = useMember(paramsMember);

  const initialMember = member?.find((li) => li.id === id);

  const {
    data: advisorServices,
    // refetch: refetchAdvisorServices,
    // isLoading: isLoadingAdvisorServices,
  } = useAdvisorServices(advisorID);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedService, setSelectedService] = useState<GetGoalTypes>({
    id: "li.id",
    name: "Title",
    fa_icon: "icon",
    description: "Summary",
    tasks: [],
    request_documents: [],
    fields: [],
  });
  const [selectedMember, setSelectedMember] = useState(initialMember);
  const [isOpenModalSendEmail, setIsOpenModalSendEmail] = useState(false);
  const [isOpenModalAreYouSure, setIsOpenModalAreYouSure] = useState(false);
  const [emailFrom, setEmailFrom] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [html, setHtml] = useState("");
  const [memberDocumentVault, setMemberDocumentVault] = useState("");
  // const [formTasks, setFormTasks] = useState<string[]>([]);
  // const [formDocuments, setFormDocuments] = useState<string[]>([]);

  const openModalSendEmail = () => {
    setIsOpenModalSendEmail(true);
  };
  const closeModalSendEmail = () => setIsOpenModalSendEmail(false);

  const openModalAreYouSure = () => setIsOpenModalAreYouSure(true);
  const closeModalAreYouSure = () => setIsOpenModalAreYouSure(false);

  const handleEditorContent = (content: string) => {
    setHtml(content);
  };

  const CONFIG: ConfigItem[] = selectedService?.fields.map((li) => ({
    name: li.id,
    label: li.name,
    type: li.type,
    placeholder: li.placeholder,
    required: li.required,
  }));

  const formMethods = useForm();

  const handleSelectService = (service: GetGoalTypes) => {
    setSelectedService(service);
    formMethods.reset();
    formMethods.reset({
      title: service.name,
      summary: service.description,
      tasks: [],
      requested_documents: [],
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { title, summary, tasks, requested_documents, ...rest } =
    formMethods.watch();

  let restObject = Object.keys(rest);
  let restArray: { value: string; id: string }[] = [];

  restObject.forEach((obj) => {
    restArray.push({ value: rest[obj], id: obj });
  });

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonLoadingDraft, setIsButtonLoadingDraft] = useState(false);

  const onSubmitDraft: SubmitHandler<any> = async (value) => {
    setIsButtonLoadingDraft(true);
    const { title, summary, tasks, requested_documents, ...rest } = value;
    let restObject = Object.keys(rest);
    let restArray: { value: string; id: string }[] = [];

    restObject.forEach((obj) => {
      restArray.push({ value: rest[obj], id: obj });
    });

    const body = {
      advisor_id: advisorID,
      member_id: selectedMember?.id,
      status: "DRAFT",
      type: selectedService.id,
      title: title,
      summary: summary,
      tasks: tasks,
      requested_documents: requested_documents,
      fields: restArray,
    };
    console.log("body", body);
    try {
      const res = await axios.post(`/goal`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "New goals added",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        history.push(`/member-detail?id=${id}`);
      }
      setIsButtonLoadingDraft(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoadingDraft(false);
    }
  };

  const onSubmit: SubmitHandler<any> = async (value) => {
    setIsButtonLoading(true);
    const { title, summary, tasks, requested_documents, ...rest } = value;
    let restObject = Object.keys(rest);
    let restArray: { value: string; id: string }[] = [];

    restObject.forEach((obj) => {
      restArray.push({ value: rest[obj], id: obj });
    });

    const body = {
      advisor_id: advisorID,
      member_id: selectedMember?.id,
      status: "ACTIVE",
      type: selectedService.id,
      title: title,
      summary: summary,
      tasks: tasks,
      requested_documents: requested_documents,
      fields: restArray,
    };
    console.log("body", body);
    try {
      const res = await axios.post<{
        sendmail: true;
        email_from: string;
        email_to: string;
        email_reply_to: string;
        email_subject: string;
        email_content: string;
        member_document_vault: string;
      }>(`/goal`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "New goals added",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setEmailFrom(res.data.email_from);
        setEmailTo(res.data.email_to);
        setEmailSubject(res.data.email_subject);
        setHtml(`${res.data.email_content}`);
        setMemberDocumentVault(res.data.member_document_vault);
        openModalSendEmail();
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (id) {
      setParamsMemberDetail((prev) => ({
        ...prev,
        memberID: id,
      }));
      setSelectedMember(initialMember);
    }
  }, [id, initialMember]);
  useEffect(() => {
    if (advisorID) {
      setParamsMember((prev) => ({
        ...prev,
        advisorId: advisorID,
      }));
    }
  }, [advisorID]);

  return (
    <React.Fragment>
      <Container>
        <Stack spacing={3} padding={2}>
          {memberDetail?.name ? (
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {/* <Link
              variant="body2"
              fontSize={12}
              underline="hover"
              color="inherit"
              href="/"
            >
              Home
            </Link>
            <Link
              variant="body2"
              fontSize={12}
              underline="hover"
              color="inherit"
              href="/dashboard"
            >
              Dashboard
            </Link> */}
              <Link
                variant="body2"
                fontSize={12}
                underline="hover"
                color="inherit"
                href="/member-list"
              >
                Members
              </Link>
              <Link
                variant="body2"
                fontSize={12}
                underline="hover"
                color="inherit"
                href={`/member-detail?id=${id}`}
              >
                {memberDetail?.name}
              </Link>
              <Typography variant="body2" fontSize={12} color="text.primary">
                Add a goal
              </Typography>
            </Breadcrumbs>
          ) : null}
        </Stack>
      </Container>
      <Container maxWidth="xl">
        <Grid container padding={5} bgcolor="#F7F6F1">
          <Grid item xs={12} md={6} xl={4}>
            <Stack height="100%" justifyContent="center">
              <Typography variant="h5" fontWeight={700} textAlign="center">
                Start a goal
              </Typography>
              <Typography
                mt={3}
                fontSize={isMobileScreen ? undefined : 20}
                textAlign="center"
              >
                Setting a clear goal to be successful together.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={0} xl={2}></Grid>
          <Grid item xs={12} md={6} xl={6} textAlign="center">
            <HomePageIllust
              style={{
                height: "fit-content",
                width: "70%",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            marginY: 3,
            "& .MuiStepLabel-root .Mui-completed": {
              color: "secondary.main", // circle color (COMPLETED)
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "grey.500", // Just text label (COMPLETED)
              },
            "& .MuiStepLabel-root .Mui-active": {
              color: "secondary.main", // circle color (ACTIVE)
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "#fff", // Just text label (ACTIVE)
            },
          }}
        >
          <Step>
            <StepLabel>
              <Typography variant="h5" color="secondary" marginLeft={3}>
                Step 1
              </Typography>
            </StepLabel>
            <StepContent>
              <Stack mt={1} spacing={3} paddingLeft={3}>
                <Stack spacing={1}>
                  <Typography variant="h6" fontWeight={700}>
                    Choose the type of goal?
                  </Typography>
                  <Box maxWidth={650}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                      gap={2}
                    >
                      {advisorServices?.goal_types.map((li) => (
                        <Box
                          key={li.id}
                          component={ListItemButton}
                          onClick={() => handleSelectService(li)}
                          position="relative"
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={2}
                          border={
                            selectedService?.id === li.id
                              ? "1px solid #326E61"
                              : "1px solid rgba(0,0,0,0.1)"
                          }
                          padding={2}
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(0,0,0,0.2)",
                            },
                          }}
                        >
                          {selectedService?.id === li.id && (
                            <Box position="absolute" top={4} right={4}>
                              <CheckIcon htmlColor="#326E61" />
                            </Box>
                          )}
                          <FontAwesomeIcon
                            icon={li.fa_icon.replace("fa-", "") as IconProp}
                            color="black"
                            size="xl"
                          />
                          <Typography textAlign="center">{li.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6" fontWeight={700}>
                    About the goal
                  </Typography>
                </Stack>
                <Grid2 container>
                  <Grid2 xs={12} md={2}>
                    <Typography>Title</Typography>
                  </Grid2>
                  <Grid2 xs={12} md={10}>
                    <Controller
                      name="title"
                      control={formMethods.control}
                      rules={{
                        required: "Title field is required",
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder={selectedService.name ?? ""}
                          multiline
                          required={true}
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : " "
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid2>
                </Grid2>
                <Grid2 container>
                  <Grid2 xs={12} md={2}>
                    <Typography>Summary</Typography>
                  </Grid2>
                  <Grid2 xs={12} md={10}>
                    <Controller
                      name="summary"
                      control={formMethods.control}
                      rules={{
                        required: "Summary field is required",
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder={selectedService.description ?? ""}
                          multiline
                          required={true}
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : " "
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid2>
                </Grid2>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    onClick={formMethods.handleSubmit(handleNext)}
                    color="buttongreen"
                  >
                    Next
                  </Button>
                </Stack>
              </Stack>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="h5" color="secondary" marginLeft={3}>
                Step 2
              </Typography>
            </StepLabel>
            <StepContent>
              <Stack mt={1} spacing={3} paddingLeft={3}>
                <Typography variant="h6" fontWeight={700}>
                  About your member
                </Typography>
                <Grid2 container alignItems="center" gap={1}>
                  <Grid2 xs={12} md={2}>
                    <Typography>Member Name</Typography>
                  </Grid2>
                  <Grid2 xs={12} md={6}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar
                        sx={{
                          backgroundColor: "primary.main",
                          width: 50,
                          height: 50,
                        }}
                        alt={selectedMember?.name}
                        src={selectedMember?.avatar_photo ?? ""}
                      />
                      <Autocomplete
                        disablePortal
                        // loading={isLoadingKategori}
                        disableClearable
                        options={member ?? []}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          String(option.id) === String(value.id)
                        }
                        size="small"
                        value={selectedMember}
                        onChange={(_event, suburb: GetMemberResponse) => {
                          setSelectedMember(suburb);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="-- Choose Member --"
                            error={
                              selectedMember === null ||
                              selectedMember === undefined
                            }
                            helperText={
                              selectedMember === null ||
                              (selectedMember === undefined &&
                                "Please choose one")
                            }
                          />
                        )}
                      />
                    </Box>
                  </Grid2>
                  <Grid2 xs={12} md={3}>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        href="/member"
                        startIcon={<AddIcon />}
                        sx={{ textTransform: "none" }}
                      >
                        New member
                      </Button>
                    </Stack>
                  </Grid2>
                </Grid2>
                <Typography variant="h6" fontWeight={700}>
                  Requirements
                </Typography>
                <Form onSubmit={onSubmit} formMethods={formMethods}>
                  <Stack>
                    {CONFIG.map((configItem, index) => {
                      return React.createElement(Form.Input, {
                        ...configItem,
                        key: index,
                      });
                    })}
                  </Stack>
                </Form>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Button onClick={handleBack} color="buttongreen">
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={formMethods.handleSubmit(handleNext)}
                      color="buttongreen"
                    >
                      Next
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="h5" color="secondary" marginLeft={3}>
                Step 3
              </Typography>
            </StepLabel>
            <StepContent>
              <Stack mt={1} spacing={4} paddingLeft={3}>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight={700}>
                    Your tasks todo to complete this goal
                  </Typography>
                  <Stack spacing={2} maxWidth={600}>
                    <Stack spacing={1}>
                      <Controller
                        name="tasks"
                        control={formMethods.control}
                        rules={{
                          required: "Please choose one",
                        }}
                        render={({ field, fieldState }) => (
                          <TagsCheckbox
                            options={selectedService.tasks ?? []}
                            value={field.value}
                            onChange={(_event, newTag: string[]) => {
                              field.onChange(newTag);
                            }}
                            freeSolo={true}
                            placeholder="Select from existing suggestion or type a new task and press Enter..."
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight={700}>
                    Requesting for documents
                  </Typography>
                  <Stack spacing={2} maxWidth={600}>
                    <Stack spacing={1}>
                      <Controller
                        name="requested_documents"
                        control={formMethods.control}
                        rules={{
                          required: "Please choose one",
                        }}
                        render={({ field, fieldState }) => (
                          <TagsCheckbox
                            options={selectedService.request_documents ?? []}
                            value={field.value}
                            onChange={(_event, newTag: string[]) => {
                              field.onChange(newTag);
                            }}
                            freeSolo={true}
                            placeholder="Select from existing suggestion or type a new document and press Enter..."
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Button onClick={handleBack} color="buttongreen">
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={formMethods.handleSubmit(handleNext)}
                      color="buttongreen"
                    >
                      Review
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="h5" color="secondary" marginLeft={3}>
                Step 4
              </Typography>
            </StepLabel>
            <StepContent>
              <Stack mt={1} spacing={4} paddingLeft={3}>
                <Stack spacing={3}>
                  <Typography variant="h6" fontWeight={700}>
                    Goal review
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        width: 50,
                        height: 50,
                      }}
                      alt={selectedMember?.name}
                      src={selectedMember?.avatar_photo ?? ""}
                    />
                    <Typography fontWeight={500}>
                      {selectedMember?.name}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    gap={0.5}
                  >
                    <Typography fontWeight={500}>{title}:</Typography>
                    <Typography>{summary}</Typography>
                  </Stack>
                  <Stack spacing={1}>
                    {restArray && restArray.length > 0
                      ? restArray.map((li) => {
                          return (
                            <>
                              {li.value && (
                                <Stack
                                  direction="row"
                                  flexWrap="wrap"
                                  alignItems="center"
                                  justifyContent={
                                    isMobileScreen ? "space-between" : undefined
                                  }
                                  gap={0.5}
                                >
                                  <Typography
                                    fontWeight={500}
                                    sx={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {li.id ? li.id.replaceAll("_", " ") : ""}
                                  </Typography>
                                  {!isMobileScreen && (
                                    <Box
                                      display="flex"
                                      flex={1}
                                      borderBottom="1px dotted black"
                                      mt={2}
                                    />
                                  )}
                                  <Typography>
                                    {isNaN(
                                      Number(
                                        li.value
                                          ? li.value.replaceAll(",", "")
                                          : ""
                                      )
                                    )
                                      ? li.value
                                      : `$${li.value}`}
                                  </Typography>
                                </Stack>
                              )}
                            </>
                          );
                        })
                      : null}
                    <Typography>Your Tasks:</Typography>
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {formMethods.watch("tasks") &&
                      formMethods.watch("tasks").length > 0
                        ? formMethods
                            .watch("tasks")
                            .map((ta: string) => (
                              <Chip key={ta} label={ta} size="small" />
                            ))
                        : null}
                    </Stack>
                    <Typography>
                      Requesting for the following documents:
                    </Typography>
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {formMethods.watch("requested_documents") &&
                      formMethods.watch("requested_documents").length > 0
                        ? formMethods
                            .watch("requested_documents")
                            .map((rd: string) => (
                              <Chip key={rd} label={rd} size="small" />
                            ))
                        : null}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems="center"
                    gap={2}
                  >
                    <Button
                      // variant="outlined"
                      onClick={handleBack}
                      color="buttongreen"
                      // fullWidth
                    >
                      Back
                    </Button>
                    <LoadingButton
                      loading={isButtonLoadingDraft}
                      loadingPosition="center"
                      variant="contained"
                      fullWidth={isMobileScreen ? true : false}
                      onClick={formMethods.handleSubmit(onSubmitDraft)}
                      color="buttonlightgreen"
                      sx={{
                        width: isMobileScreen ? undefined : 300,
                      }}
                    >
                      Save Draft
                    </LoadingButton>
                    <LoadingButton
                      loading={isButtonLoading}
                      loadingPosition="center"
                      variant="contained"
                      fullWidth
                      onClick={formMethods.handleSubmit(onSubmit)}
                      color="buttongreen"
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      OK, SAVE and SEND REQUEST DOCUMENTS
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            </StepContent>
          </Step>
        </Stepper>
      </Container>
      <ModalSendEmail
        advisorID={advisorID}
        memberID={selectedMember?.id ?? ""}
        fullScreen={isMobileScreen}
        open={isOpenModalSendEmail}
        onClose={closeModalSendEmail}
        sendmail={true}
        email_from={emailFrom}
        email_to={emailTo}
        email_subject={emailSubject}
        html={html}
        handleEditorContent={handleEditorContent}
        openModalAreYouSure={openModalAreYouSure}
        closeModalAreYouSure={closeModalAreYouSure}
        member_document_vault={memberDocumentVault}
      />
      <ModalAreYouSure
        open={isOpenModalAreYouSure}
        onClose={closeModalAreYouSure}
        onYes={() => {
          closeModalAreYouSure();
          closeModalSendEmail();
        }}
      />
    </React.Fragment>
  );
};

export default MemberAddGoalPage;
