import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { ReactComponent as OrangeBannerDesktop } from "../../assets/images/OrangeBannerDesktop.svg";
import LandingBanner from "../../assets/images/LandingBanner.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ARInput from "../../components/ARInput/ARInput";
import { AreaType } from "../../constants/types";
import useSuburbs from "../../services/queries/useSuburb";
import BuildingSuper from "../../assets/images/buildingsuper.png";
import CentreLinkBenefits from "../../assets/images/centrelinkbenefits.png";
import EstatePlanning from "../../assets/images/estateplanning.png";
import HouseholdBudgeting from "../../assets/images/householdbudgeting.png";
import InsuranceProtection from "../../assets/images/insuranceprotection.png";
import PreparingForRetirement from "../../assets/images/preparingforretirement.png";
import SavingInvestments from "../../assets/images/savingsinvestments.png";
import SelfManagedSuperfunds from "../../assets/images/selfmanagedsuperfunds.png";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

interface IFindAdvisor {
  suburb: string;
}

const schema = yup.object({
  suburb: yup.string().required("Suburb is required"),
});

const areas: AreaType[] = [
  { id: 1, src: BuildingSuper, name: "Superannuation" },
  { id: 2, src: PreparingForRetirement, name: "Retirement Planning" },
  { id: 3, src: CentreLinkBenefits, name: "Centrelink" },
  { id: 4, src: SelfManagedSuperfunds, name: "Self Managed Super Funds" },
  { id: 5, src: HouseholdBudgeting, name: "Budget Planning" },
  { id: 6, src: SavingInvestments, name: "Wealth Creation" },
  { id: 7, src: InsuranceProtection, name: "Personal Insurance" },
  { id: 8, src: EstatePlanning, name: "Estate Planning" },
];

const FindAdvisorPage = () => {
  const [selectedAreas, setSelectedAreas] = useState<AreaType[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { data: suburbs } = useSuburbs();

  const uniqueSuburbs = [...new Set(suburbs)];

  const handleClickArea = (data: AreaType) => {
    if (selectedAreas.findIndex((c) => c.id === data.id) >= 0) {
      setSelectedAreas(
        selectedAreas.filter((li) => {
          return li.id !== data.id;
        })
      );
    } else {
      setSelectedAreas((prev) => [...prev, data]);
    }
  };

  const initialValues = React.useMemo(
    () => ({
      suburb: "",
    }),
    []
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFindAdvisor>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IFindAdvisor) => {
    // setIsButtonLoading(true);
    try {
      const body = {
        suburb: values.suburb,
      };
      // const res = await axios.post(`/person`, body);
      // if (res.status === 200) {
      //   setIsButtonLoading(false);
      //   reset(initialValues);
      //   setSelectedAreas([]);
      //   Swal.fire({
      //     title:
      //       "Thank you for your request, our Advisor Team will reach out to you shortly.",
      //     position: "top-end",
      //     showConfirmButton: false,
      //     icon: "success",
      //     toast: true,
      //     timer: 3000,
      //     timerProgressBar: true,
      //     showCloseButton: true,
      //     customClass: {
      //       container: "my-swal",
      //     },
      //   });
      // }
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  return (
    <Stack bgcolor="#E9E6EC">
      <Stack direction="row" justifyContent="flex-end" position="relative">
        <img
          src={LandingBanner}
          alt="banner"
          style={{
            // backgroundColor: "#000",
            // backgroundPosition: "20% top",
            objectPosition: "20% top",
            backgroundSize: "cover",
            color: "#fff",
            height: "21.25rem",
            // position: "relative",
            zIndex: 1,
            padding: 0,
            width: "100%",
            objectFit: "cover",
          }}
        />
        <Box position="absolute" top="40%" left="12%" zIndex={2}>
          <Typography variant="h4" color="#fff">
            Find a financial advisor
          </Typography>
        </Box>
        <Box position="absolute" top={0} left={0} zIndex={1}>
          <OrangeBannerDesktop height="100%" />
        </Box>
      </Stack>
      <Container maxWidth="lg" sx={{ zIndex: 2 }}>
        <Stack p={4} borderRadius={2} mt={-5} bgcolor="#fff" spacing={3}>
          <Typography variant="h5">What can we help you with today?</Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
            gap={2}
          >
            {areas.map((li) => (
              <ARInput
                key={li.id.toString()}
                data={li}
                onClick={() => handleClickArea(li)}
                selectedAreas={selectedAreas}
              />
            ))}
          </Box>
        </Stack>
        <Stack p={4} borderRadius={2} mt={2} bgcolor="#fff" spacing={3}>
          <Typography variant="h5">Enter your suburb</Typography>
          <Grid container gap={3} alignItems="flex-start">
            <Grid item xs={12} md={9}>
              <Controller
                name="suburb"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    disableClearable
                    options={uniqueSuburbs ?? []}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      String(option) === String(value)
                    }
                    size="small"
                    value={field.value}
                    onChange={(_event, suburb: string | null) => {
                      field.onChange(suburb);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="-- Choose Suburb --"
                        error={Boolean(errors.suburb)}
                        helperText={errors.suburb && "Please choose one"}
                        // InputProps={{ sx: { borderRadius: "50px" } }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <LoadingButton
                loadingPosition="center"
                loading={isButtonLoading}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
              >
                Search Advisors
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
        <Typography
          variant="subtitle2"
          fontSize={12}
          color="#464b51"
          mt={5}
          mb={3}
        >
          The financial advice you receive will be provided under the Australian
          Financial Services Licence held by a third party and will be their
          responsibility. Personal product advice provided may attract a fee,
          which will be outlined before any work is completed and is subject to
          your agreement.
        </Typography>
      </Container>
      <Box
        px={4}
        py={8}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        bgcolor="#fff"
      >
        <Stack
          component={Paper}
          p={4}
          borderRadius={2}
          bgcolor="#fff"
          spacing={2}
          alignItems="center"
        >
          <Typography variant="h5">Unsure where to start?</Typography>
          <Typography variant="h6">
            The AustralianSuper team is here to help
          </Typography>
          <Button
            variant="outlined"
            size="large"
            sx={{ borderRadius: 50, textTransform: "none" }}
          >
            Get in touch
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FindAdvisorPage;
