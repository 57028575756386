import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAdvisorDocuments from "../../services/queries/useAdvisorDocuments";
import {
  ContentGetAdvisorDocument,
  ParamsGetAdvisorDocuments,
} from "../../constants/types";
import {
  titleMeta,
  faviconMeta,
  advisorId,
  defaultColor,
  secondaryColor,
} from "../../constants/common";
import { Cookies } from "react-cookie";
import { useFetching } from "../../context/fetchingContext";
import { GridColDef, GridOverlay } from "@mui/x-data-grid";
// import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import DataGridCustom from "../../components/DataGridCustom/DataGridCustom";
import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import ModalRequestDocument from "../../components/ModalRequestDocument/ModalRequestDocument";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalUploadDocument from "../../components/ModalUploadDocument/ModalUploadDocument";
import DataGridMenuDocumentList from "../../components/DataGridMenu/DataGridMenuDocumentList";
import ModalEditDocument from "../../components/ModalEditDocument/ModalEditDocument";
import ModalDeleteDocument from "../../components/ModalDeleteDocument/ModalDeleteDocument";
// import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Grid2 from "@mui/material/Unstable_Grid2";

// const FILTER = [
//   {
//     id: "",
//     title: "All",
//   },
//   {
//     id: "proposal",
//     title: "Proposal documents",
//   },
//   {
//     id: "contract",
//     title: "Contract documents",
//   },
//   {
//     id: "recommendation",
//     title: "Recommendation documents",
//   },
//   {
//     id: "plan",
//     title: "Financial plan documents",
//   },
//   {
//     id: "disclosure",
//     title: "Disclosure documents",
//   },
//   {
//     id: "agreement",
//     title: "Agreement documents",
//   },
//   {
//     id: "research",
//     title: "Research reports",
//   },
//   {
//     id: "performance",
//     title: "Performance reports",
//   },
//   {
//     id: "prospect",
//     title: "Investment prospect reports",
//   },
// ];

const SecuredDocumentListPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tag = urlParams.get("tag");

  // const history = useHistory();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const initialParamsAdvisorDocuments = {
    advisorID: undefined,
    filter: undefined,
    documentID: undefined,
  };

  const [paramsAdvisorDocuments, setParamsAdvisorDocuments] =
    useState<ParamsGetAdvisorDocuments>(initialParamsAdvisorDocuments);
  const {
    data: advisorDocuments,
    refetch: refetchAdvisorDocuments,
    isLoading: isLoadingAdvisorDocuments,
  } = useAdvisorDocuments(paramsAdvisorDocuments);
  const [search, setSearch] = useState("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  let filteredDocuments =
    advisorDocuments &&
    advisorDocuments.filter((li) => {
      const documentName = li.name.toLowerCase();
      const memberName = li.member_name?.toLowerCase();
      const goalName = li.goal_title?.toLowerCase();
      return (
        documentName.includes(search.toLowerCase()) ||
        memberName?.includes(search.toLowerCase()) ||
        goalName?.includes(search.toLowerCase())
      );
    });

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // // const [filter, setFilter] = useState("");

  // const handleSelectFilter = (fil: string) => {
  //   // setFilter(fil);
  //   if (fil) {
  //     history.push(`/secured-document-list?tag=${fil}`);
  //   } else {
  //     history.push(`/secured-document-list`);
  //   }
  //   handleClose();
  // };

  const [documentId, setDocumentId] = useState("");

  const [isOpenModalRequestDocument, setIsOpenModalRequestDocument] =
    useState(false);
  const [isOpenModalUploadDocument, setIsOpenModalUploadDocument] =
    useState(false);
  const [isOpenModalEditDocument, setIsOpenModalEditDocument] = useState(false);
  const [isOpenModalDeleteDocument, setIsOpenModalDeleteDocument] =
    useState(false);

  const openModalRequestDocument = () => setIsOpenModalRequestDocument(true);
  const closeModalRequestDocument = () => setIsOpenModalRequestDocument(false);

  const openModalUploadDocument = () => setIsOpenModalUploadDocument(true);
  const closeModalUploadDocument = () => setIsOpenModalUploadDocument(false);

  const openModalEditDocument = (id: string) => {
    setDocumentId(id);
    setIsOpenModalEditDocument(true);
  };
  const closeModalEditDocument = () => {
    setDocumentId("");
    setIsOpenModalEditDocument(false);
  };

  const openModalDeleteDocument = (id: string) => {
    setDocumentId(id);
    setIsOpenModalDeleteDocument(true);
  };
  const closeModalDeleteDocument = () => {
    setDocumentId("");
    setIsOpenModalDeleteDocument(false);
  };

  const handleAllowUrl = (id: string) => {
    setParamsAdvisorDocuments((prev) => ({
      ...prev,
      documentID: id,
    }));
    refetchAdvisorDocuments();
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (advisorID) {
      setParamsAdvisorDocuments((prev) => ({
        ...prev,
        advisorID: advisorID,
      }));
    }
  }, [advisorID]);

  useEffect(() => {
    if (tag) {
      setParamsAdvisorDocuments((prev) => ({
        ...prev,
        filter: tag,
      }));
    }
  }, [tag]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchAdvisorDocuments();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchAdvisorDocuments]);

  const columnsData: GridColDef[] = [
    {
      field: "member_name",
      headerName: "Member",
      headerAlign: "left",
      align: "left",
      minWidth: 160,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" gap={2}>
            <Avatar
              sx={{
                backgroundColor: "primary.main",
                width: 24,
                height: 24,
              }}
              src={params.row.member_avatar_photo}
              alt={params.value}
            />
            <Typography variant="body2">{params.value}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "goal_title",
      headerName: "Goal",
      headerAlign: "left",
      align: "left",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="body2">{params.value ?? "-"}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Document name",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
    },
    {
      field: "url",
      headerName: "File",
      headerAlign: "left",
      align: "center",
      minWidth: 20,
      maxWidth: 50,
      renderCell: (params) => {
        const row: ContentGetAdvisorDocument = params.row;

        if (params.value) {
          if (row.url_access_allowed) {
            return (
              <IconButton
                href={params.value}
                rel="noreferrer"
                target="_blank"
                download={row.name}
              >
                <FontAwesomeIcon
                  icon="file-circle-check"
                  color={defaultColor}
                />
              </IconButton>
            );
          } else {
            return (
              <IconButton onClick={() => handleAllowUrl(row.id)}>
                <FontAwesomeIcon icon="file-shield" color={secondaryColor} />
              </IconButton>
            );
          }
        } else {
          return <Typography variant="body2">-</Typography>;
        }
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   headerAlign: "left",
    //   align: "center",
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Chip
    //         label={
    //           <Typography
    //             variant="body2"
    //             fontSize={12}
    //             fontWeight={500}
    //             textTransform="capitalize"
    //           >
    //             {params.value}
    //           </Typography>
    //         }
    //         color={params.value === "NEW" ? "chipnew" : "chipnew"}
    //       />
    //     );
    //   },
    // },
    // {
    //   field: "sent_on",
    //   headerName: "Sent on",
    //   headerAlign: "left",
    //   align: "left",
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return <Typography variant="body2">{params.value ?? "-"}</Typography>;
    //   },
    // },
    // {
    //   field: "modified_on",
    //   headerName: "Last updated",
    //   headerAlign: "left",
    //   align: "left",
    //   minWidth: 120,
    //   renderCell: (params) => {
    //     return <Typography variant="body2">{params.value ?? "-"}</Typography>;
    //   },
    // },
    {
      field: "actions",
      headerName: "",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <DataGridMenuDocumentList
            onEdit={() => openModalEditDocument(id)}
            onDelete={() => openModalDeleteDocument(id)}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Stack spacing={3} padding={2} minHeight="80vh">
        <Stack spacing={2}>
          <Stack>
            <TextField
              fullWidth
              placeholder="Search by member name, goal, or document name"
              value={search}
              size="small"
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#fff",
                },
              }}
            />
          </Stack>
          <Grid2 container spacing={1}>
            <Grid2 xs={12} md={2}>
              <Typography
                display="block"
                component="div"
                fontWeight={500}
                color="primary.main"
              >
                Filter by:{" "}
                <Typography display="inline" fontWeight={500} color="#2c2c2c">
                  {tag ?? "All"}
                </Typography>
              </Typography>
            </Grid2>
            <Grid2 xs={0} md={4}></Grid2>
            <Grid2 xs={12} md={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="flex-end"
                gap={2}
              >
                <Button
                  variant="contained"
                  color="buttonlightgreen"
                  onClick={openModalRequestDocument}
                >
                  Request Document
                </Button>
                <Button
                  variant="contained"
                  color="buttongreen"
                  onClick={openModalUploadDocument}
                >
                  Upload Document
                </Button>
              </Stack>
            </Grid2>
          </Grid2>

          <DataGridCustom
            loading={isLoadingAdvisorDocuments}
            rows={filteredDocuments ?? []}
            columns={columnsData}
            slots={{
              noRowsOverlay: NoRowsImage,
            }}
          />
        </Stack>
      </Stack>
      <ModalRequestDocument
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalRequestDocument}
        onClose={() => {
          closeModalRequestDocument();
          refetchAdvisorDocuments();
        }}
      />
      <ModalUploadDocument
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        isOpenModalUploadDocument={isOpenModalUploadDocument}
        closeModalUploadDocument={() => {
          closeModalUploadDocument();
          refetchAdvisorDocuments();
        }}
      />
      <ModalEditDocument
        fullScreen={isMobileScreen}
        isOpenModalEditDocument={isOpenModalEditDocument}
        closeModalEditDocument={closeModalEditDocument}
        documentId={documentId}
      />
      <ModalDeleteDocument
        fullScreen={isMobileScreen}
        isOpenModalDeleteDocument={isOpenModalDeleteDocument}
        closeModalDeleteDocument={closeModalDeleteDocument}
        documentId={documentId}
      />
    </Container>
  );
};

const NoRowsImage = () => {
  return (
    <GridOverlay>
      <Box>No documents</Box>
    </GridOverlay>
  );
};

export default SecuredDocumentListPage;
