import React, { useCallback, useEffect, useMemo, useState } from "react";
import useMemberDetail from "../../services/queries/useMemberDetail";
import { Cookies } from "react-cookie";
import {
  advisorId,
  defaultColor,
  faviconMeta,
  titleMeta,
  workbookEditionMeta,
} from "../../constants/common";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import BusinessIcon from "@mui/icons-material/Business";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
// import BalanceIcon from "@mui/icons-material/Balance";
// import AnalyticsIcon from "@mui/icons-material/Analytics";
// import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import currencyFormat from "../../utils/currencyFormat";
import MemberAccount from "../../components/MemberAccount/MemberAccount";
import { styled, useTheme } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ModalAddMember from "../../components/ModalAddMember/ModalAddMember";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFetching } from "../../context/fetchingContext";
import { TraceSpinner } from "react-spinners-kit";
import CardCustom from "../../components/CardCustom/CardCustom";
import CommerceIcon from "../../assets/images/accounts/commerce.png";
import GiveMoneyIcon from "../../assets/images/accounts/give-money.png";
import LiabilityIcon from "../../assets/images/accounts/liability.png";
import MoneyIcon from "../../assets/images/accounts/money.png";
import MoneyManageIcon from "../../assets/images/accounts/money-management.png";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalEditWorksheet from "../../components/ModalEditWorksheet/ModalEditWorksheet";
import { ParamsGetMemberDetail } from "../../constants/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

interface IMemberDetailPageProps {}

interface ITweetInputs {
  message: string;
}

const schema = yup.object({
  message: yup.string().required("Please complete this detail."),
});

const MemberDetailPage = (props: IMemberDetailPageProps) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const cookies = useMemo(() => new Cookies(), []);
  const initialParamsMemberDetail = {
    memberID: id,
    documentID: undefined,
  };

  const [paramsMemberDetail, setParamsMemberDetail] =
    useState<ParamsGetMemberDetail>(initialParamsMemberDetail);
  const {
    data: memberDetail,
    refetch: refetchMemberDetail,
    isLoading: isLoadingMemberDetail,
    isError: isErrorMemberDetail,
  } = useMemberDetail(paramsMemberDetail);
  const [openAccordionGoals, setOpenAccordionGoals] = useState(false);
  const [openAccordionDocuments, setOpenAccordionDocuments] = useState(false);
  const [isOpenModalEditMember, setIsOpenModalEditMember] = useState(false);
  const [isOpenModalEditWorksheet, setIsOpenModalEditWorksheet] =
    useState(false);
  const [advisorID, setAdvisorID] = useState("");
  const [workbookEdition, setWorkbookEdition] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModalEditMember = () => setIsOpenModalEditMember(true);
  const closeModalEditMember = () => setIsOpenModalEditMember(false);

  const openModalEditWorksheet = () => setIsOpenModalEditWorksheet(true);
  const closeModalEditWorksheet = () => setIsOpenModalEditWorksheet(false);

  const handleOpenAccordionGoals = () => setOpenAccordionGoals((prev) => !prev);
  const handleOpenAccordionDocuments = () =>
    setOpenAccordionDocuments((prev) => !prev);

  const handleAllowUrl = (id: string) => {
    setParamsMemberDetail((prev) => ({
      ...prev,
      documentID: id,
    }));
    refetchMemberDetail();
  };

  const assets = memberDetail?.worksheet?.Assets;
  const equities = memberDetail?.worksheet?.Equities;
  const expenses = memberDetail?.worksheet?.Expenses;
  const incomes = memberDetail?.worksheet?.Incomes;
  const liabilities = memberDetail?.worksheet?.Liabilities;
  const netWorth = memberDetail?.worksheet?.["Net Worth"];

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      const getWorkbookEdition = await cookies.get(workbookEditionMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
      if (getWorkbookEdition) {
        setWorkbookEdition(getWorkbookEdition);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  const initialValues = useMemo(
    () => ({
      message: "",
    }),
    []
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ITweetInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: ITweetInputs) => {
    setIsButtonLoading(true);
    try {
      const body = {
        message: values.message,
      };
      const res = await axios.post(`/member/${id}/tweet`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "New notes added",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        reset(initialValues);
        refetchMemberDetail();
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (id) {
      setParamsMemberDetail((prev) => ({
        ...prev,
        memberID: id,
      }));
    }
  }, [id]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchMemberDetail();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchMemberDetail]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: isMobileScreen ? "80%" : "100%",
    height: 20,
    borderRadius: 200,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 200,
    },
  }));

  return (
    <Container>
      <Stack spacing={3} padding={2}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href="/member-list"
          >
            Members
          </Link>
          <Typography variant="body2" fontSize={12} color="text.primary">
            {memberDetail?.name}
          </Typography>
        </Breadcrumbs>
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        paddingX={2}
        paddingBottom={2}
      >
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          // onClick={() => openModalEditMember()}
          onClick={handleClick}
          color="buttongreen"
          size="small"
        >
          Edit
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              openModalEditMember();
              handleClose();
            }}
          >
            Edit profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              openModalEditWorksheet();
              handleClose();
            }}
          >
            Edit worksheet
          </MenuItem>
        </Menu>
      </Stack>

      {isLoadingMemberDetail || isErrorMemberDetail ? null : (
        <Stack paddingX={2}>
          <CardCustom noPadding>
            <Stack
              direction="row"
              margin={3}
              spacing={2}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={2} alignItems="flex-start">
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 64,
                    height: 64,
                  }}
                  alt={memberDetail?.name}
                  src={memberDetail?.avatar_photo}
                />
                <Stack>
                  <Typography variant="h6" fontWeight={500}>
                    {memberDetail?.name}
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" gap={1}>
                    {memberDetail?.tags.map((ta) => (
                      <Chip
                        key={ta}
                        label={
                          <Typography variant="body2" fontWeight={500}>
                            {ta}
                          </Typography>
                        }
                        color="chiplightgray"
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
              {/* <Box>
                <Button
                  variant="outlined"
                  endIcon={<KeyboardArrowDownIcon />}
                  // onClick={() => openModalEditMember()}
                  onClick={handleClick}
                  color="buttongreen"
                  size="small"
                >
                  Edit
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      openModalEditMember();
                      handleClose();
                    }}
                  >
                    Edit profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      openModalEditWorksheet();
                      handleClose();
                    }}
                  >
                    Edit worksheet
                  </MenuItem>
                </Menu>
              </Box> */}
            </Stack>
          </CardCustom>
        </Stack>
      )}

      {isLoadingMemberDetail ? (
        <Stack
          padding={5}
          minHeight={"80vh"}
          alignItems="center"
          justifyContent="center"
        >
          <TraceSpinner
            frontColor={defaultColor}
            backColor="#4b4c56"
            loading={true}
          />
        </Stack>
      ) : isErrorMemberDetail ? (
        <Stack
          padding={5}
          minHeight={"80vh"}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5">
            This is a demo contact - not found in database
          </Typography>
        </Stack>
      ) : (
        <Grid container spacing={3} padding={2}>
          {memberDetail?.worksheet ? (
            <Grid item xs={12}>
              <Stack
                component={Card}
                variant="outlined"
                padding={3}
                spacing={3}
                bgcolor="#EB9C64"
                border="1px solid #EB9C64"
              >
                <Typography fontWeight={500} color="#fff">
                  Net worth
                </Typography>
                <Typography variant="h5" color="#fff" fontWeight={700}>
                  ${currencyFormat(Number(netWorth))}
                </Typography>
              </Stack>
            </Grid>
          ) : null}
          {memberDetail?.worksheet ? (
            <Grid item xs={12} md={5} lg={6} xl={5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CardCustom noPadding>
                    <Stack padding={3} spacing={3}>
                      <Typography variant="h6" fontWeight={500}>
                        Accounts
                      </Typography>
                      <div>
                        <MemberAccount
                          title="Assets"
                          icon={MoneyIcon}
                          total={Number(memberDetail.worksheet["Total Assets"])}
                          memberAccount={[
                            { name: "Cash", nominal: Number(assets?.Cash) },
                            {
                              name: "Cash at bank",
                              nominal: Number(assets?.["Cash at bank"]),
                            },
                            {
                              name: "Investments",
                              nominal: Number(assets?.Investments),
                            },
                            {
                              name: "Superannuation",
                              nominal: Number(assets?.Superannuation),
                            },
                            {
                              name: "Real estate",
                              nominal: Number(assets?.["Real Estate"]),
                            },
                            {
                              name: "Vehicles",
                              nominal: Number(assets?.Vehicles),
                            },
                          ]}
                        />
                        <MemberAccount
                          title="Liabilities"
                          icon={LiabilityIcon}
                          total={Number(
                            memberDetail.worksheet["Total Liabilities"]
                          )}
                          memberAccount={[
                            {
                              name: "Credit Cards",
                              nominal: Number(liabilities?.["Credit Cards"]),
                            },
                            {
                              name: "Loans",
                              nominal: Number(liabilities?.Loans),
                            },
                            {
                              name: "Mortgage",
                              nominal: Number(liabilities?.Mortgage),
                            },
                            {
                              name: "Other debts",
                              nominal: Number(liabilities?.["Other debts"]),
                            },
                          ]}
                        />
                        <MemberAccount
                          title="Equities"
                          icon={MoneyManageIcon}
                          total={Number(
                            memberDetail.worksheet["Total Equities"]
                          )}
                          memberAccount={[
                            {
                              name: "Owner's Equity",
                              nominal: Number(equities?.["Owner's Equity"]),
                            },
                          ]}
                        />
                        <MemberAccount
                          title="Incomes"
                          frequency={incomes?.Frequency}
                          icon={GiveMoneyIcon}
                          total={Number(
                            memberDetail.worksheet["Total Incomes"]
                          )}
                          memberAccount={[
                            {
                              name: "Salary",
                              nominal: Number(incomes?.Salary),
                            },
                            {
                              name: "Interests income",
                              nominal: Number(incomes?.["Interest Income"]),
                            },
                            {
                              name: "Rental income",
                              nominal: Number(incomes?.["Rental Income"]),
                            },
                            {
                              name: "Other income",
                              nominal: Number(incomes?.["Other Income"]),
                            },
                          ]}
                        />
                        <MemberAccount
                          title="Expenses"
                          frequency={expenses?.Frequency}
                          icon={CommerceIcon}
                          total={Number(
                            memberDetail.worksheet["Total Expenses"]
                          )}
                          memberAccount={[
                            {
                              name: "Housing",
                              nominal: Number(expenses?.["Housing"]),
                            },
                            {
                              name: "Utilities",
                              nominal: Number(expenses?.Utilities),
                            },
                            {
                              name: "Transport",
                              nominal: Number(expenses?.Transportation),
                            },
                            {
                              name: "Debt payments",
                              nominal: Number(expenses?.["Debt Payments"]),
                            },
                            {
                              name: "Entertainment",
                              nominal: Number(expenses?.Entertainment),
                            },
                            {
                              name: "Food",
                              nominal: Number(expenses?.["Food and Dining"]),
                            },
                            {
                              name: "Healthcare",
                              nominal: Number(expenses?.Healthcare),
                            },
                            {
                              name: "Savings",
                              nominal: Number(expenses?.Savings),
                            },
                            {
                              name: "Other expenses",
                              nominal: Number(expenses?.["Other Expenses"]),
                            },
                          ]}
                        />
                      </div>
                    </Stack>
                  </CardCustom>
                </Grid>
                <Grid item xs={12}>
                  <CardCustom noPadding>
                    <Stack padding={3} spacing={3}>
                      <Typography variant="h6" fontWeight={500}>
                        Quick notes
                      </Typography>
                      <Stack spacing={1}>
                        <Controller
                          name="message"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              placeholder="Enter your updates, notes, todo, follow up, etc."
                              multiline
                              rows={4}
                              error={Boolean(errors.message)}
                              helperText={
                                errors.message ? errors.message.message : ""
                              }
                              InputProps={{ sx: { backgroundColor: "#fff" } }}
                              {...field}
                            />
                          )}
                        />
                        <Stack direction="row" justifyContent="flex-end">
                          <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="center"
                            disableElevation
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            sx={{
                              fontWeight: 700,
                            }}
                          >
                            Add
                          </LoadingButton>
                        </Stack>
                      </Stack>
                      <Stack>
                        {memberDetail?.tweets &&
                        memberDetail?.tweets.length > 0 ? (
                          <Box width="100%">
                            <Timeline
                              sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                  flex: 0,
                                  padding: 0,
                                },
                                padding: 0,
                              }}
                            >
                              {memberDetail?.tweets.map((li) => (
                                <TimelineItem key={li.id}>
                                  <TimelineSeparator
                                    sx={{ alignItems: "flex-start" }}
                                  >
                                    <TimelineDot
                                      sx={{
                                        width: "100px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 500,
                                        border: "none",
                                        fontSize: "13px",
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        color: "rgba(0, 0, 0, 0.87)",
                                        boxShadow: "unset",
                                      }}
                                    >
                                      {li.modified_on}
                                    </TimelineDot>
                                    <TimelineConnector sx={{ marginLeft: 3 }} />
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Stack spacing={2} mt={5} ml={-9}>
                                      <TextField
                                        variant="standard"
                                        value={li.message}
                                        multiline
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </Stack>
                                  </TimelineContent>
                                </TimelineItem>
                              ))}
                            </Timeline>
                          </Box>
                        ) : (
                          <CardCustom>
                            <Typography>
                              The world is your notepad. What will you jot down
                              today?
                            </Typography>
                          </CardCustom>
                        )}
                      </Stack>
                    </Stack>
                  </CardCustom>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} md={7} lg={6} xl={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CardCustom noPadding>
                  <Stack padding={3} spacing={3}>
                    <Typography variant="h6" fontWeight={500}>
                      Goals
                    </Typography>
                    {memberDetail?.goals && memberDetail.goals.length > 0 ? (
                      <>
                        {memberDetail?.goals.map((li, idx) => (
                          <Stack
                            key={idx.toString()}
                            component={ListItemButton}
                            padding={2}
                            spacing="12px"
                            alignItems="flex-start"
                            onClick={() => {
                              history.push(`/goal-detail?id=${li.id}`);
                            }}
                            sx={{
                              border: "1px solid #B7B7B7",
                              borderRadius: 2,
                            }}
                          >
                            <Typography>{li.title}</Typography>
                            {li.total_tasks &&
                            li.completed_tasks &&
                            li.task_completion_percentage &&
                            li.total_tasks > 0 ? (
                              <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                                justifyContent="space-between"
                                width="90%"
                              >
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={Number(li.task_completion_percentage)}
                                  color={
                                    Number(li.task_completion_percentage) <= 40
                                      ? "barunder40"
                                      : Number(li.task_completion_percentage) >=
                                        70
                                      ? "barover70"
                                      : "bar4070"
                                  }
                                />
                                <Typography
                                  width={"10%"}
                                  fontWeight={700}
                                  color={
                                    Number(li.task_completion_percentage) <= 40
                                      ? "#c24b47"
                                      : Number(li.task_completion_percentage) >=
                                        70
                                      ? "#91c467"
                                      : "#389bbb"
                                  }
                                >
                                  {li.task_completion_percentage}%
                                </Typography>
                              </Stack>
                            ) : (
                              <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography variant="caption" color="GrayText">
                                  No tasks yet
                                </Typography>
                              </Stack>
                            )}
                          </Stack>
                        ))}
                        {/* {openAccordionGoals &&
                          memberDetail.goals.length > 3 &&
                          memberDetail?.goals.slice(3).map((li, idx) => (
                            <Stack
                              key={idx.toString()}
                              component={ListItemButton}
                              padding={2}
                              spacing="12px"
                              alignItems="flex-start"
                              onClick={() => {
                                history.push(`/goal-detail?id=${li.id}`);
                              }}
                              sx={{
                                border: "1px solid #B7B7B7",
                                borderRadius: 2,
                              }}
                            >
                              <Typography>{li.title}</Typography>
                              <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                              >
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={Number(li.task_completion_percentage)}
                                  color={
                                    Number(li.task_completion_percentage) <= 40
                                      ? "barunder40"
                                      : Number(li.task_completion_percentage) >=
                                        70
                                      ? "barover70"
                                      : "bar4070"
                                  }
                                />
                                <Typography
                                  width={"10%"}
                                  fontWeight={700}
                                  color={
                                    Number(li.task_completion_percentage) <= 40
                                      ? "#c24b47"
                                      : Number(li.task_completion_percentage) >=
                                        70
                                      ? "#91c467"
                                      : "#389bbb"
                                  }
                                >
                                  {li.task_completion_percentage}%
                                </Typography>
                              </Stack>
                            </Stack>
                          ))} */}
                      </>
                    ) : (
                      <Typography>No goals...</Typography>
                    )}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Button
                        startIcon={<AddIcon />}
                        color="buttongreen"
                        href={`/member-add-a-goal?id=${id}`}
                        sx={{
                          paddingX: 2,
                          paddingY: 1,
                          textTransform: "none",
                          fontWeight: 700,
                        }}
                      >
                        Add a goal
                      </Button>
                      {/* {memberDetail?.goals &&
                        memberDetail?.goals.length > 3 && (
                          <Button
                            color="buttonorange"
                            onClick={handleOpenAccordionGoals}
                            sx={{
                              paddingX: 2,
                              paddingY: 1,
                              textTransform: "none",
                              fontWeight: 700,
                            }}
                          >
                            {openAccordionGoals ? "View less" : "View more"}
                          </Button>
                        )} */}
                    </Stack>
                  </Stack>
                </CardCustom>
              </Grid>
              <Grid item xs={12}>
                <CardCustom noPadding>
                  <Stack padding={3} spacing={3}>
                    <Typography variant="h6" fontWeight={500}>
                      Documents
                    </Typography>
                    {memberDetail?.documents &&
                    memberDetail.documents.length > 0 ? (
                      <>
                        {memberDetail.documents.map((li, idx) => {
                          if (li.url_access_allowed) {
                            return (
                              <Stack
                                key={idx.toString()}
                                component={Link}
                                underline="none"
                                direction="row"
                                alignItems="center"
                                gap="12px"
                                padding={2}
                                href={li.url}
                                rel="noreferrer"
                                target="_blank"
                                download={li.name}
                                sx={{
                                  border: "1px solid #B7B7B7",
                                  borderRadius: 2,
                                  "&:hover": {
                                    background: "#EDECE8",
                                  },
                                  transition: "all .2s",
                                }}
                              >
                                <Box mr={1}>
                                  <FontAwesomeIcon
                                    icon={"file-arrow-down"}
                                    color={defaultColor}
                                    size="3x"
                                  />
                                </Box>
                                <Stack spacing={1}>
                                  <Typography>{li.name}</Typography>
                                  <Typography color="#7D7D7D">
                                    {li.modified_on}
                                  </Typography>
                                </Stack>
                              </Stack>
                            );
                          } else {
                            return (
                              <Stack
                                key={idx.toString()}
                                component={ListItemButton}
                                direction="row"
                                alignItems="center"
                                gap="12px"
                                padding={2}
                                onClick={() => {
                                  handleAllowUrl(li.id);
                                }}
                                sx={{
                                  border: "1px solid #B7B7B7",
                                  borderRadius: 2,
                                }}
                              >
                                <InsertDriveFileIcon
                                  htmlColor="#7D7D7D"
                                  sx={{
                                    width: 48,
                                    height: 48,
                                  }}
                                />
                                <Stack spacing={1}>
                                  <Typography>{li.name}</Typography>
                                  <Typography color="#7D7D7D">
                                    {li.modified_on}
                                  </Typography>
                                </Stack>
                              </Stack>
                            );
                          }
                        })}
                        {/* {openAccordionDocuments &&
                          memberDetail.documents.length > 3 &&
                          memberDetail.documents.slice(3).map((li, idx) => {
                            if (li.url_access_allowed) {
                              return (
                                <Stack
                                  key={idx.toString()}
                                  component={Link}
                                  underline="none"
                                  direction="row"
                                  alignItems="center"
                                  gap="12px"
                                  padding={2}
                                  href={li.url}
                                  rel="noreferrer"
                                  target="_blank"
                                  download={true}
                                  sx={{
                                    border: "1px solid #B7B7B7",
                                    borderRadius: 2,
                                    "&:hover": {
                                      background: "#EDECE8",
                                    },
                                    transition: "all .2s",
                                  }}
                                >
                                  <Box mr={1}>
                                    <FontAwesomeIcon
                                      icon={"file-arrow-down"}
                                      color={defaultColor}
                                      size="3x"
                                    />
                                  </Box>
                                  <Stack spacing={1}>
                                    <Typography>{li.name}</Typography>
                                    <Typography color="#7D7D7D">
                                      {li.modified_on}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              );
                            } else {
                              return (
                                <Stack
                                  key={idx.toString()}
                                  component={ListItemButton}
                                  direction="row"
                                  alignItems="center"
                                  gap="12px"
                                  padding={2}
                                  onClick={() => {
                                    handleAllowUrl(li.id);
                                  }}
                                  sx={{
                                    border: "1px solid #B7B7B7",
                                    borderRadius: 2,
                                  }}
                                >
                                  <InsertDriveFileIcon
                                    htmlColor="#7D7D7D"
                                    sx={{
                                      width: 48,
                                      height: 48,
                                    }}
                                  />
                                  <Stack spacing={1}>
                                    <Typography>{li.name}</Typography>
                                    <Typography color="#7D7D7D">
                                      {li.modified_on}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              );
                            }
                          })} */}
                      </>
                    ) : (
                      <Typography>No documents...</Typography>
                    )}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Button
                        startIcon={<AddIcon />}
                        href="/secured-document-list"
                        color="buttongreen"
                        sx={{
                          paddingX: 2,
                          paddingY: 1,
                          textTransform: "none",
                          fontWeight: 700,
                        }}
                      >
                        Request a document
                      </Button>
                      {/* {memberDetail?.documents &&
                        memberDetail?.documents.length > 3 && (
                          <Button
                            color="buttonorange"
                            onClick={handleOpenAccordionDocuments}
                            sx={{
                              paddingX: 2,
                              paddingY: 1,
                              textTransform: "none",
                              fontWeight: 700,
                            }}
                          >
                            {openAccordionDocuments ? "View less" : "View more"}
                          </Button>
                        )} */}
                    </Stack>
                  </Stack>
                </CardCustom>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ModalAddMember
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalEditMember}
        onClose={closeModalEditMember}
        workbookEdition={workbookEdition}
        memberDetail={memberDetail}
      />
      <ModalEditWorksheet
        fullScreen={isMobileScreen}
        open={isOpenModalEditWorksheet}
        onClose={closeModalEditWorksheet}
        data={memberDetail?.worksheet}
      />
    </Container>
  );
};

export default MemberDetailPage;
