import { Circle } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

interface IBecomeAdvisorStepsProps {
  stepNo: number;
  title: string;
  src: string;
  contents: string[];
}

const BecomeAdvisorSteps = ({
  stepNo,
  title,
  src,
  contents,
}: IBecomeAdvisorStepsProps) => {
  return (
    <Box bgcolor="#EBEBEB" py={3} borderRadius={2}>
      <Stack spacing={3}>
        <Stack direction="row" px={4} spacing={3}>
          <Typography variant="h5" fontWeight={700}>
            {stepNo}
          </Typography>
          <Typography variant="h6">{title}</Typography>
        </Stack>
        <img
          src={src}
          alt=""
          width="auto"
          height="auto"
          style={{ maxWidth: "100%", verticalAlign: "middle" }}
        />
        <Stack px={4}>
          {contents.map((li) => (
            <Stack key={li} direction="row" alignItems="flex-start" spacing={1}>
              <Circle
                htmlColor="black"
                sx={{
                  width: "8px",
                  height: "8px",
                  marginTop: "8px !important",
                }}
              />
              <Typography>{li}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default BecomeAdvisorSteps;
