import {
  Controller,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { ConfigItem } from "../../constants/types";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { NumericFormat } from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import Grid2 from "@mui/material/Unstable_Grid2";

const Form = ({
  formMethods,
  onSubmit,
  children,
}: {
  formMethods: UseFormReturn<FieldValues>;
  onSubmit: SubmitHandler<FieldValues>;
  children: React.ReactNode;
}) => {
  const { handleSubmit } = formMethods;
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

const Input = ({ name, label, placeholder, required, type }: ConfigItem) => {
  const { control } = useFormContext();
  return (
    <Grid2 container>
      <Grid2 xs={12} md={2}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 xs={12} md={10}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? `${label} field is required.` : undefined,
          }}
          render={({ field, fieldState }) => (
            <>
              {type === "numeric" ? (
                <NumericFormat
                  thousandSeparator
                  customInput={TextField}
                  fullWidth
                  multiline
                  variant="outlined"
                  size="small"
                  placeholder={placeholder}
                  required={required}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  decimalScale={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#fff",
                    },
                  }}
                  {...field}
                />
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  placeholder={placeholder}
                  required={required}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  {...field}
                />
              )}
            </>
          )}
        />
      </Grid2>
    </Grid2>
  );
};

Form.Input = Input;

export default Form;
