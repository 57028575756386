import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import { useFetching } from "../../context/fetchingContext";
import { IOptions, ParamsGetMember } from "../../constants/types";
import Autocomplete from "@mui/material/Autocomplete";
import useMember from "../../services/queries/useMember";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ModalTitle from "../ModalTitle/ModalTitle";
import useSuggestedDocumentTags from "../../services/queries/useSuggestedDocumentTags";
import TagsCheckbox from "../TagsCheckbox/TagsCheckbox";

interface IModalRequestDocumentProps {
  advisorID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
}

interface IValues {
  member: {
    id: string;
    name: string;
    goals: {
      id: string;
      name: string;
    }[];
  };
  goals: {
    id: string;
    name: string;
  } | null;
  documents: string[];
}

const ModalRequestDocument = ({
  advisorID,
  fullScreen,
  open,
  onClose,
}: IModalRequestDocumentProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const initialParams = {
    advisorId: advisorID,
  };
  const [paramsMember, setParamsMember] =
    useState<ParamsGetMember>(initialParams);
  const { data: member, isLoading: isLoadingMember } = useMember(paramsMember);
  const [selectedGoals, setSelectedGoals] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  // const [documentNames, setDocumentNames] = useState<string[]>([]);
  const { data: suggestedDocumentTags } = useSuggestedDocumentTags(advisorID);

  const mappedMember = member?.map((li) => ({
    id: li.id,
    name: li.name,
    goals: li.goals.map((go) => ({ id: go.id, name: go.title })),
  }));

  const initialValues = useMemo(
    () => ({
      member: undefined,
      goals: undefined,
    }),
    []
  );

  const schema = yup.object({
    member: yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required(),
      goals: yup
        .array()
        .min(1, "Choose one")
        .of(
          yup.object().shape({
            id: yup.string().required(),
            name: yup.string().required(),
          })
        )
        .required(),
    }),
    goals: yup
      .object()
      .shape({
        id: yup.string().required(),
        name: yup.string().required(),
      })
      .nullable(),
    documents: yup.array().min(1).of(yup.string().required()).required(),
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IValues) => {
    setIsButtonLoading(true);

    for (let index = 0; index < values.documents.length; index++) {
      try {
        const body = {
          name: values.documents[index],
          action: "REQUEST",
          advisor_id: advisorID,
          member_id: values.member.id,
          goal_id: values.goals?.id,
        };
        console.log("body", body);
        const res = await axios.post(`document`, body);
        if (res.status === 200 || res.status === 201) {
          Swal.fire({
            title: `New documents requested`,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
          setFetchingItems();
        }
        onClose();
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (advisorID) {
      setParamsMember((prev) => ({
        ...prev,
        advisorId: advisorID,
      }));
    }
  }, [advisorID]);

  useEffect(() => {
    if (open) {
      reset(initialValues);
    }
  }, [initialValues, open, reset]);

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <InsertDriveFileIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Request document
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers sx={{ pb: 8 }}>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Stack>
              <Typography>Requesting for documents:</Typography>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Controller
                    name="documents"
                    control={control}
                    render={({ field }) => (
                      <TagsCheckbox
                        options={suggestedDocumentTags?.suggested_tags ?? []}
                        value={field.value}
                        onChange={(_event, newTag: string[]) => {
                          field.onChange(newTag);
                        }}
                        freeSolo={true}
                        placeholder="Select from existing suggestion or type a new document and press Enter..."
                        error={Boolean(errors.documents)}
                        helperText={errors.documents?.message}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Member:</Typography>
              <Controller
                name="member"
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    disablePortal
                    disableClearable
                    loading={isLoadingMember}
                    options={mappedMember ?? []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    size="small"
                    value={field.value}
                    onChange={(
                      _event,
                      newOption: {
                        id: string;
                        name: string;
                        goals: {
                          id: string;
                          name: string;
                        }[];
                      } | null
                    ) => {
                      field.onChange(newOption);
                      setValue("goals", null);
                      setSelectedGoals(newOption?.goals);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Member"
                        error={!!fieldState?.error}
                        helperText={
                          fieldState?.error ? "Choose one member" : " "
                        }
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            backgroundColor: "#fff",
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Goal:</Typography>
              <Controller
                name="goals"
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    disablePortal
                    loading={isLoadingMember}
                    options={selectedGoals ?? []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    size="small"
                    value={field.value}
                    onChange={(_event, newOption: IOptions | null) => {
                      field.onChange(newOption);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose goal"
                        error={!!fieldState?.error}
                        helperText={fieldState?.error ? "Choose one goal" : " "}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            backgroundColor: "#fff",
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalRequestDocument;
