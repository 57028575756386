import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import {
  GetAssociateResponse,
  ParamsGetAssociate,
} from "../../constants/types";

const handleRequest = async (params: ParamsGetAssociate) => {
  const urlApi = qs.stringifyUrl({
    url: `advisor/${params.advisorId}/associate-list`,
  });
  const { data } = await axios.get<GetAssociateResponse[]>(urlApi);
  return data;
};

export default function useAssociate(params: ParamsGetAssociate) {
  return useQuery<GetAssociateResponse[]>(
    ["associate", params],
    () => handleRequest(params),
    {
      enabled: !!params.advisorId,
    }
  );
}
