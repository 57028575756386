import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_MINI,
  advisorId,
  defaultColor,
  faviconMeta,
  logoMeta,
  memberPageTour,
  secondaryColor,
  titleMeta,
} from "../constants/common";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { Cookies } from "react-cookie";
import { useFetching } from "../context/fetchingContext";
import useAdvisorDetail from "../services/queries/useAdvisorDetail";
import ThemeComponent from "../components/ThemeComponent/ThemeComponent";
import {
  AccountCircle,
  ExpandLess,
  ExpandMore,
  Lock,
  Notifications,
} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ModalLogout from "../components/ModalLogout/ModalLogout";
import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "../services/axios";
import "./style-dashboard-v2.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import Swal from "sweetalert2";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import TooltipJoyride from "../components/TooltipJoyride/TooltipJoyride";

interface IPageLayoutDashboardv2Props {
  children: React.ReactNode;
}

enum Expand {
  MEMBER = "Member",
  GOAL = "Goal",
  DOCUMENT = "Document",
  ASSOCIATES = "Associates",
  AI = "AI",
  SETTINGS = "Settings",
}

const PageLayoutDashboardv2 = ({ children }: IPageLayoutDashboardv2Props) => {
  const cookies = useMemo(() => new Cookies(), []);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const [logo, setLogo] = useState("");
  const [advisorID, setAdvisorID] = useState("");
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  const {
    data: advisorDetail,
    refetch: refetchAdvisorDetail,
    isSuccess: isSuccesAdvisorDetail,
  } = useAdvisorDetail(advisorID);

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      const getAdvisorId = await cookies.get(advisorId);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  const location = useLocation();

  const expandMember = location.pathname?.includes("member");
  const expandGoal = location.pathname?.includes("goal");
  const expandDocument = location.pathname?.includes("document");
  const expandAssociates = location.pathname?.includes("associates");
  const expandAI = location.pathname?.includes("ai");
  const expandSettings = location.pathname?.includes("settings");

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState<Expand | false>(
    expandMember
      ? Expand.MEMBER
      : expandGoal
      ? Expand.GOAL
      : expandDocument
      ? Expand.DOCUMENT
      : expandAssociates
      ? Expand.ASSOCIATES
      : expandAI
      ? Expand.AI
      : expandSettings
      ? Expand.SETTINGS
      : false
  );

  const handleExpand = (param: Expand | null) => {
    if (param === null) {
      setExpanded(false);
    } else if (param === Expand.MEMBER) {
      setExpanded(param);
    } else if (param === Expand.GOAL) {
      setExpanded(param);
    } else if (param === Expand.DOCUMENT) {
      setExpanded(param);
    } else if (param === Expand.ASSOCIATES) {
      setExpanded(param);
    } else if (param === Expand.AI) {
      setExpanded(param);
    } else if (param === Expand.SETTINGS) {
      setExpanded(param);
    }
    setRun(true);
  };

  const LINKS = [
    {
      id: "memberNav",
      text: "Member",
      expand: Expand.MEMBER,
      children: [
        {
          text: "All members",
          href: "/member-list",
        },
        {
          text: "Set a goal",
          href: "/member-add-a-goal",
        },
        {
          text: "Schedule an appointment",
          disabled: true,
        },
        {
          text: "Send bulk communication",
          disabled: true,
        },
        {
          text: "Set a reminder",
          disabled: true,
        },
        {
          text: "Member presentation",
          disabled: true,
        },
        {
          text: "Member reviews",
          disabled: true,
        },
      ],
    },
    {
      id: "goalNav",
      text: "Goal",
      expand: Expand.GOAL,
      children: [
        {
          id: "trackGoal",
          text: "Track goal progress",
          href: "/goal-list",
        },
        {
          text: "Start a goal",
          href: "/member-add-a-goal",
        },
        {
          text: "Goal analytics",
          disabled: true,
        },
        {
          text: "Evaluate goal performance",
          disabled: true,
        },
      ],
    },
    {
      id: "documentNav",
      text: "Document Vault",
      expand: Expand.DOCUMENT,
      children: [
        {
          text: "All secured documents",
          href: "/secured-document-list",
        },
        {
          text: "Proposal documents",
          href: "/secured-document-list?tag=proposal",
        },
        {
          text: "Contract documents",
          href: "/secured-document-list?tag=contract",
        },
        {
          text: "Recommendation documents",
          href: "/secured-document-list?tag=recommendation",
        },
        {
          text: "Financial plan documents",
          href: "/secured-document-list?tag=plan",
        },
        {
          text: "Disclosure documents",
          href: "/secured-document-list?tag=disclosure",
        },
        {
          text: "Agreement documents",
          href: "/secured-document-list?tag=agreement",
        },
        {
          text: "Research reports",
          href: "/secured-document-list?tag=research",
        },
        {
          text: "Performance reports",
          href: "/secured-document-list?tag=performance",
        },
        {
          text: "Investment prospect reports",
          href: "/secured-document-list?tag=prospect",
        },
      ],
    },
    {
      id: "associateNav",
      text: "Associates",
      expand: Expand.ASSOCIATES,
      children: [
        {
          text: "All associates",
          href: "/associate-list",
        },
        // {
        //   text: "Add an associate",
        //   href: "/secured-document-list",
        // },
        {
          text: "Assign tasks",
          disabled: true,
        },
        {
          text: "Associate incentives",
          disabled: true,
        },
      ],
    },
    {
      id: "aiNav",
      text: "AI & Insights",
      expand: Expand.AI,
      children: [
        {
          text: "Market trends and predictions",
          disabled: true,
        },
        {
          text: "Economic indicators",
          disabled: true,
        },
        {
          text: "Market sentiment analysis",
          disabled: true,
        },
        {
          text: "Regulatory compliance",
          disabled: true,
        },
        {
          text: "Fraud detection",
          disabled: true,
        },
        {
          text: "Performance tracking",
          disabled: true,
        },
        {
          text: "AI-powered research",
          disabled: true,
        },
      ],
    },
    {
      id: "settingNav",
      text: "Settings",
      expand: Expand.SETTINGS,
      children: [
        {
          text: "My account",
          href: "/my-account",
        },
      ],
    },
  ];

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, index, status, type } = data;

    // if (type === EVENTS.STEP_AFTER && index === 0) {
    //   setRun(false);
    //   handleExpand(Expand.GOAL);
    // } else if (type === EVENTS.STEP_AFTER && index === 1) {
    //   if (action === "next") {
    //     setRun(false);
    //   } else {
    //     handleExpand(Expand.MEMBER);
    //     setRun(true);
    //     setStepIndex(0);
    //   }
    // } else if (type === EVENTS.STEP_AFTER && index === 2) {
    //   if (action === "next") {
    //     setRun(false);
    //     handleExpand(Expand.DOCUMENT);
    //   } else {
    //     handleExpand(Expand.GOAL);
    //     setRun(true);
    //     setStepIndex(1);
    //   }
    // } else if (type === EVENTS.STEP_AFTER && index === 3) {
    //   if (action === "next") {
    //     setRun(false);
    //     handleExpand(Expand.AI);
    //   } else {
    //     handleExpand(Expand.DOCUMENT);
    //     setRun(true);
    //     setStepIndex(2);
    //   }
    // } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
    //   // Need to set our running state to false, so we can restart if we click start again.
    //   console.log("close step");
    //   // localStorage.setItem(memberPageTour, "true");
    //   handleExpand(Expand.MEMBER);
    //   setRun(false);
    // }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      console.log("index", index);
      // try {

      // } catch (error) {

      // }
      // setRun(false);
      // handleExpand(
      //   index === -1
      //     ? Expand.MEMBER
      //     : index === 0 || index === 1
      //     ? Expand.GOAL
      //     : index === 2
      //     ? Expand.DOCUMENT
      //     : Expand.AI
      // );
      if (action === ACTIONS.NEXT) {
        setStepIndex(index + 1);
      } else if (action === ACTIONS.PREV) {
        setStepIndex(index - 1);
      } else {
        setRun(false);
      }
    } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      const body = {
        feature_flags: [
          {
            name: "walkthrough_member_menu",
            seen: true,
          },
          {
            name: "walkthrough_goal_menu",
            seen: true,
          },
          {
            name: "walkthrough_goal_list",
            seen: true,
          },
          {
            name: "walkthrough_document_vault_menu",
            seen: true,
          },
          {
            name: "walkthrough_ai_insights_menu",
            seen: true,
          },
        ],
      };
      if (action === ACTIONS.NEXT) {
        try {
          const res = await axios.patch(`advisor/${advisorID}`, body);
          if (res.status === 200 || res.status === 201) {
            handleExpand(Expand.MEMBER);
            setRun(false);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setRun(false);
      }
    }

    // console.log("type", type);
    // console.log("data", data); //eslint-disable-line no-console
    // console.groupEnd();
  };

  const filteredSteps = advisorDetail?.feature_flags
    ?.filter((st) => st.seen === false)
    .map((li, idx) => ({
      content: li.message,
      target:
        li.sequence === 1
          ? "#memberNav"
          : li.sequence === 2 || li.sequence === 3
          ? "#goalNav"
          : li.sequence === 4
          ? "#documentNav"
          : "#aiNav",
      disableBeacon: true,
    }));

  const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElMessage, setAnchorElMessage] =
    React.useState<null | HTMLElement>(null);
  const [isBellRinging, setIsBellRinging] = useState(false);

  const openMessage = Boolean(anchorElMessage);
  const handleClickMessage = (event: React.MouseEvent<HTMLElement>) => {
    setIsBellRinging(true);
    setAnchorElMessage(event.currentTarget);
  };
  const handleCloseMessage = () => {
    setAnchorElMessage(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const [expand, setExpand] = useState(false);

  // const handleChange = () => {
  //   setChecked((prev) => !prev);
  // };

  const handleLogout = () => {
    setIsOpenModalLogout(true);
    setAnchorEl(null);
  };

  const closeModalLogout = () => {
    setIsOpenModalLogout(false);
  };

  useEffect(() => {
    if (isSuccesAdvisorDetail) {
      setRun(true);
    }
  }, [isSuccesAdvisorDetail]);

  useEffect(() => {
    if (anchorElMessage === null) {
      setIsBellRinging(false);
    }
  }, [anchorElMessage]);

  const drawer = (
    <Stack
      display="flex"
      flex={1}
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box width="100%">
        <Box display="flex" p={3}>
          <Link href="/member-list">
            <img
              src={logo}
              width={isMobileScreen ? 100 : 140}
              //   height={}
              style={{
                objectFit: "contain",
              }}
              alt="logo"
            />
          </Link>
        </Box>
        <List component="nav">
          {LINKS.map(({ id, text, expand, children }, idx) => (
            <Box id={id} key={id}>
              <ListItemButton
                selected={children
                  .map((li) => li.href)
                  .includes(location.pathname)}
                component={Link}
                onClick={() => {
                  handleExpand(expand);
                }}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#F9EFE8",
                    // color: "#fff",
                    "&:hover": {
                      backgroundColor: "#F9EFE8",
                      // color: "#fff",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#F9EFE8",
                    // color: "#fff",
                  },
                }}
              >
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    fontSize: isMobileScreen ? "16px" : "20px",
                  }}
                />
                {expanded === expand ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={expanded === expand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {children?.map(({ id, href, text, disabled }, idx) => (
                    <ListItemButton
                      id={id}
                      key={idx.toString()}
                      disabled={disabled}
                      component={Link}
                      href={href}
                      selected={location.pathname + location.search === href}
                      sx={{
                        pl: isMobileScreen ? 4 : 6,
                        "&.Mui-selected": {
                          backgroundColor: secondaryColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: secondaryColor,
                            color: "#fff",
                          },
                        },
                        // "&:hover": {
                        //   backgroundColor: secondaryColor,
                        //   color: "#fff",
                        // },
                      }}
                    >
                      {disabled ? (
                        <>
                          <ListItemIcon>
                            <Lock fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>{text}</ListItemText>
                        </>
                      ) : (
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{
                            fontSize: isMobileScreen ? "14px" : undefined,
                          }}
                        />
                      )}
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))}
        </List>
      </Box>
    </Stack>
  );

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchAdvisorDetail();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchAdvisorDetail]);

  return (
    <>
      <ThemeComponent>
        {!!!localStorage.getItem(memberPageTour) && (
          <Joyride
            tooltipComponent={TooltipJoyride}
            steps={filteredSteps}
            callback={handleJoyrideCallback}
            run={run}
            continuous
            showProgress
            stepIndex={stepIndex}
            styles={{
              options: {
                arrowColor: "#fff",
                backgroundColor: "#fff",
                beaconSize: 36,
                overlayColor: "rgba(0, 0, 0, 0.5)",
                primaryColor: defaultColor,
                spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                textColor: "#333",
                zIndex: 10000,
              },
            }}
          />
        )}
        <Box display="flex" paddingTop={{ xs: "56px", sm: "64px", lg: "0px" }}>
          <AppBar
            variant="outlined"
            elevation={0}
            sx={{
              display: { xs: "block" },
              width: { lg: `calc(100vw - ${DRAWER_WIDTH}px)` },
              ml: {
                sm: isMobileScreen
                  ? `${DRAWER_WIDTH_MINI}px`
                  : `${DRAWER_WIDTH}px`,
              },
              backgroundColor: "#fff",
            }}
          >
            <Toolbar disableGutters>
              <Container maxWidth="lg">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                  paddingLeft={open ? 2.5 : 3}
                  // paddingRight={2}
                  width="100%"
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ display: { lg: "none" } }}
                    >
                      <MenuIcon htmlColor="gray" />
                    </IconButton>
                    <Typography
                      variant={isMobileScreen ? "body1" : "h5"}
                      color="#7D7D7D"
                      fontWeight={700}
                    >
                      {capitalizeFirstLetter(
                        location.pathname === "/secured-document-list"
                          ? "/document-vault"
                          : location.pathname
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Box>
                      <Tooltip title="Open messages">
                        <IconButton
                          onClick={handleClickMessage}
                          onAnimationEnd={() => setIsBellRinging(false)}
                          className={isBellRinging ? "bell2" : ""}
                        >
                          {advisorDetail && (
                            <Badge
                              color="secondary"
                              variant="dot"
                              invisible={
                                advisorDetail.messages_unread_count === 0
                              }
                            >
                              <Notifications fontSize="medium" />
                            </Badge>
                          )}
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        anchorEl={anchorElMessage}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        // keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        slotProps={{
                          paper: {
                            sx: {
                              maxWidth: 400,
                            },
                          },
                        }}
                        open={openMessage}
                        onClose={handleCloseMessage}
                      >
                        {advisorDetail && advisorDetail?.messages.length > 0 ? (
                          advisorDetail?.messages.map((li) => (
                            // <MenuItem key={li.id}>
                            //   <Stack>
                            //     <Typography fontWeight={500}>
                            //       {li.subject}
                            //     </Typography>
                            //     <Typography variant="body2" fontStyle="italic">
                            //       {li.modified_on}
                            //     </Typography>
                            //     <Typography variant="body2" mt={1}>
                            //       {li.message}
                            //     </Typography>
                            //   </Stack>
                            // </MenuItem>
                            <MessageList
                              data={li}
                              key={li.id}
                              refetch={refetchAdvisorDetail}
                            />
                          ))
                        ) : (
                          <Box
                            height={300}
                            width={400}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography fontSize={12} color="gray">
                              No messages
                            </Typography>
                          </Box>
                        )}
                      </Menu>
                    </Box>
                    {advisorDetail && (
                      <Box>
                        <Tooltip title={advisorDetail.name}>
                          <IconButton onClick={handleClick}>
                            {advisorDetail.avatar_photo ? (
                              <Avatar
                                alt={advisorDetail.name}
                                src={advisorDetail.avatar_photo}
                                sx={{ width: 32, height: 32 }}
                              />
                            ) : (
                              <AccountCircle fontSize="medium" />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: "45px" }}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          // keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            component={Link}
                            href="/my-account"
                            onClick={handleClose}
                          >
                            My account
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Container>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{
              width: { lg: DRAWER_WIDTH },
              flexShrink: { sm: 0 },
            }}
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={() => setMobileOpen(false)}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", lg: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: isMobileScreen ? DRAWER_WIDTH_MINI : DRAWER_WIDTH,
                  paddingX: isMobileScreen ? 2 : 4,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: DRAWER_WIDTH,
                  paddingX: 4,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              maxWidth: {
                md: "100vw",
                lg: `calc(100vw - ${DRAWER_WIDTH}px)`,
              },
              overflowX: "hidden",
            }}
          >
            <Toolbar
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
              }}
            />
            {children}
          </Box>
        </Box>
        <ModalLogout open={isOpenModalLogout} onClose={closeModalLogout} />
      </ThemeComponent>
    </>
  );
};

interface IMessageListProps {
  data: {
    icon: string;
    subject: string;
    message: string;
    status: string;
    id: string;
    modified_on: string;
  };
  refetch: () => void;
}

const MessageList = ({ data, refetch }: IMessageListProps) => {
  const [checked, setChecked] = useState(false);
  const [isBellRingingSingle, setIsBellRingingSingle] = useState(false);

  const handleChange = async () => {
    setIsBellRingingSingle(true);
    try {
      if (data.status === "NEW") {
        const res = await axios.patch(`/message/${data.id}/seen`);
        if (res.status === 200) {
          setChecked((prev) => !prev);
          refetch();
        }
      } else {
        setChecked((prev) => !prev);
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <ListItemButton
        alignItems="flex-start"
        onClick={handleChange}
        onAnimationEnd={() => setIsBellRingingSingle(false)}
        sx={{
          background: "#fdfdfd",
        }}
      >
        <ListItemAvatar sx={{ paddingLeft: "12px" }}>
          <Badge
            color="secondary"
            variant="dot"
            invisible={data.status !== "NEW"}
            onAnimationEnd={() => setIsBellRingingSingle(false)}
            className={isBellRingingSingle ? "bell2" : ""}
          >
            <FontAwesomeIcon
              icon={data.icon as IconProp}
              size="xl"
              color="gray"
            />
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography component="span" color="text.primary">
                  {data.subject}
                </Typography>
                <MoreHorizIcon />
              </Stack>
              <Typography fontSize={12} color="gray">
                <i>{data.modified_on}</i>
              </Typography>
            </Stack>
          }
          secondary={<Collapse in={checked}>{data.message}</Collapse>}
        />
      </ListItemButton>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default PageLayoutDashboardv2;
