import {
  Controller,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { ConfigItemEdit } from "../../constants/types";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { NumericFormat } from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import Grid2 from "@mui/material/Unstable_Grid2";

const FormEdit = ({
  formMethods,
  onSubmit,
  children,
}: {
  formMethods: UseFormReturn<FieldValues>;
  onSubmit: SubmitHandler<FieldValues>;
  children: React.ReactNode;
}) => {
  const { handleSubmit } = formMethods;
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

const Input = ({ id, value }: ConfigItemEdit) => {
  const { control } = useFormContext();
  return (
    <Grid2 container>
      <Grid2 xs={12} md={2}>
        <Typography
          sx={{
            textTransform: "capitalize",
          }}
        >
          {id ? id.replaceAll("_", " ") : ""}
        </Typography>
      </Grid2>
      <Grid2 xs={12} md={10}>
        <Controller
          name={id}
          control={control}
          rules={{
            required: `${id.replaceAll("_", " ")} field is required.`,
          }}
          render={({ field, fieldState }) => (
            <>
              {isNaN(Number(value ? value.replaceAll(",", "") : "")) ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={value}
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  {...field}
                />
              ) : (
                <NumericFormat
                  thousandSeparator
                  customInput={TextField}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={value}
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  decimalScale={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#fff",
                    },
                  }}
                  {...field}
                />
              )}
            </>
          )}
        />
      </Grid2>
    </Grid2>
  );
};

FormEdit.Input = Input;

export default FormEdit;
