import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetAdvisorDetailResponse } from "../../constants/types";

const handleRequest = async (advisorID: string) => {
  const urlApi = qs.stringifyUrl({
    url: `/advisor/${advisorID}`,
  });
  const { data } = await axios.get<GetAdvisorDetailResponse>(urlApi);
  return data;
};

export default function useAdvisorDetail(advisorID: string) {
  return useQuery<GetAdvisorDetailResponse>(
    ["advisorDetail", advisorID],
    () => handleRequest(advisorID),
    {
      enabled: !!advisorID,
    }
  );
}
