import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { defaultColor, font, secondaryColor } from "../../constants/common";

interface IThemeComponentProps {
  children: React.ReactNode;
}

declare module "@mui/material/styles" {
  interface Palette {
    buttonyellow: Palette["primary"];
    buttonorange: Palette["primary"];
    buttonwhite: Palette["primary"];
    buttongreen: Palette["primary"];
    buttonlightgreen: Palette["primary"];
    barclosed: Palette["primary"];
    barunder40: Palette["primary"];
    bar4070: Palette["primary"];
    barover70: Palette["primary"];
    chipgray: Palette["primary"];
    chiplightgray: Palette["primary"];
    chipnew: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    buttonyellow?: PaletteOptions["primary"];
    buttonorange?: PaletteOptions["primary"];
    buttonwhite?: PaletteOptions["primary"];
    buttongreen?: PaletteOptions["primary"];
    buttonlightgreen?: PaletteOptions["primary"];
    barclosed?: PaletteOptions["primary"];
    barunder40?: PaletteOptions["primary"];
    bar4070?: PaletteOptions["primary"];
    barover70?: PaletteOptions["primary"];
    chipgray?: PaletteOptions["primary"];
    chiplightgray?: PaletteOptions["primary"];
    chipnew?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/lab/LoadingButton" {
  interface LoadingButtonPropsColorOverrides {
    buttonyellow: true;
    buttonorange: true;
    buttonwhite: true;
    buttongreen: true;
    buttonlightgreen: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    buttonyellow: true;
    buttonorange: true;
    buttonwhite: true;
    buttongreen: true;
    buttonlightgreen: true;
  }
}

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides {
    buttonyellow: true;
    buttonorange: true;
    buttonwhite: true;
    buttongreen: true;
    buttonlightgreen: true;
  }
}

declare module "@mui/lab/TimelineDot" {
  interface TimelineDotPropsColorOverrides {
    buttonyellow: true;
    buttonorange: true;
    buttonwhite: true;
    buttongreen: true;
    buttonlightgreen: true;
  }
}

declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    buttonyellow: true;
    buttonorange: true;
    buttonwhite: true;
    buttongreen: true;
    buttonlightgreen: true;
    barclosed: true;
    barunder40: true;
    bar4070: true;
    barover70: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    buttonyellow: true;
    buttonorange: true;
    buttonwhite: true;
    buttongreen: true;
    buttonlightgreen: true;
    barclosed: true;
    barunder40: true;
    bar4070: true;
    barover70: true;
    chipgray: true;
    chiplightgray: true;
    chipnew: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
  }
}

const ThemeComponent = ({ children }: IThemeComponentProps) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tablet: 650,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: defaultColor,
        contrastText: "#fff",
      },
      secondary: {
        main: secondaryColor,
      },
      buttonyellow: {
        main: "#ede1b5",
        contrastText: "#260046",
      },
      buttonorange: {
        main: "#d93e02",
        contrastText: "#fff",
      },
      buttonwhite: {
        main: "#fff",
        contrastText: "#ea4403",
      },
      buttongreen: {
        main: "#326E61",
        contrastText: "#fff",
      },
      buttonlightgreen: {
        main: "#82B8A8",
        contrastText: "#fff",
      },
      barclosed: {
        main: "#3b4b6c",
        contrastText: "#fff",
      },
      barunder40: {
        main: "#c24b47",
        contrastText: "#fff",
      },
      bar4070: {
        main: "#389bbb",
        contrastText: "#fff",
      },
      barover70: {
        main: "#91c467",
        contrastText: "#fff",
      },
      chipgray: {
        main: "#B7B7B7",
        contrastText: "#2C2C2C",
      },
      chiplightgray: {
        main: "#DEDEDE",
        contrastText: "#2C2C2C",
      },
      chipnew: {
        main: "#ADBFFF",
        contrastText: "#2C2C2C",
      },
    },
    typography: {
      fontFamily: font,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 50,
            paddingRight: 24,
            paddingLeft: 24,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          InputProps: {
            sx: {
              backgroundColor: "#fff",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
