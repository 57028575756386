import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

interface IModalAreYouSureProps {
  open: boolean;
  onClose: () => void;
  onYes: () => void;
}

const ModalAreYouSure = ({ open, onClose, onYes }: IModalAreYouSureProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant={isMobileScreen ? "body1" : "h5"}
            fontWeight="bold"
          >
            Are you sure you want to exit?
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>Your progress will not be saved.</DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Stack direction="row" gap={2}>
          <Button onClick={onYes} variant="contained" color="buttonlightgreen">
            Yes
          </Button>
          <Button onClick={onClose} variant="contained" color="buttongreen">
            No
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAreYouSure;
