import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { secondaryColor } from "../../constants/common";
import Stack from "@mui/material/Stack";
import CardCustom from "../CardCustom/CardCustom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import TextField from "@mui/material/TextField";
import { ParamsGetBusinessData } from "../../constants/types";
import useTweet from "../../services/queries/useTweet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ITweetTimelineProps {
  id: string;
  alias: string;
}

interface ITweetInputs {
  message: string;
}

const schema = yup.object({
  message: yup.string().required("Please complete this detail."),
});

const categoryList = [
  { id: "thumbs-up", name: "Thumbs Up", color: secondaryColor },
  { id: "fire-flame-curved", name: "Fire", color: secondaryColor },
  { id: "face-tired", name: "Tired", color: secondaryColor },
  { id: "laugh-beam", name: "Laugh", color: secondaryColor },
  { id: "coffee", name: "Coffee", color: secondaryColor },
];

const TweetTimeline = ({ id, alias }: ITweetTimelineProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [category, setCategory] = useState("thumbs-up");
  const [image, setImage] = useState<File | string | null>(null);
  const initialParamsTweet = {
    businessAlias: alias,
    filter: "tweet",
  };
  const [paramsTweet, setParamsTweet] =
    useState<ParamsGetBusinessData>(initialParamsTweet);
  const { data: tweet, refetch: refetchTweet } = useTweet(paramsTweet);

  const handleCategory = (icon: string) => {
    setCategory(icon);
  };

  const initialValues = useMemo(
    () => ({
      message: "",
      gambar: null,
    }),
    []
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ITweetInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: ITweetInputs) => {
    setIsButtonLoading(true);
    try {
      const body = {
        icon: category,
        message: values.message,
        photo_url: image ?? "",
        status: "NEW",
      };
      const res = await axios.post(`/business/${id}/tweet`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "New notes added",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        reset(initialValues);
        refetchTweet();
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  useEffect(() => {
    if (alias) {
      setParamsTweet((prev) => ({
        ...prev,
        businessAlias: alias,
      }));
    }
  }, [alias]);

  return (
    <Stack spacing={5.5} width="100%">
      {tweet?.tweet_count && tweet.tweet_count > 0 ? (
        <>
          {tweet.tweets.map((li) => (
            <CardCustom key={li.id}>
              <Stack spacing={3} width="100%">
                <Stack direction="row" alignItems="flex-start" gap={3}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    width={isMobileScreen ? undefined : "64px"}
                  >
                    <FontAwesomeIcon
                      icon={li.icon as IconProp}
                      color="gray"
                      size={isMobileScreen ? "2x" : "3x"}
                    />
                  </Stack>
                  <Stack width="100%">
                    <Typography color="#7D7D7D">{li.modified_on}</Typography>
                    <TextField
                      variant="standard"
                      value={li.message}
                      multiline
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                  </Stack>
                </Stack>
                {li.photo_url && (
                  <Stack direction="row" justifyContent="center" padding={1.5}>
                    <img
                      src={li.photo_url}
                      alt={li.photo_url}
                      style={{ maxWidth: "70%" }}
                    />
                  </Stack>
                )}
              </Stack>
            </CardCustom>
          ))}
        </>
      ) : (
        <CardCustom>
          <Typography>
            The world is your notepad. What will you jot down today?
          </Typography>
        </CardCustom>
      )}
      <CardCustom>
        <Grid container spacing={3}>
          <Grid item xs={12} mb={1}>
            <Typography variant="h5" fontWeight={700}>
              Add a quick note
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={3}>
              <Typography fontWeight={500}>What's your mood today?</Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap={isMobileScreen ? 2 : 4}
              >
                {categoryList.map((li) => (
                  <IconButton key={li.id} onClick={() => handleCategory(li.id)}>
                    <FontAwesomeIcon
                      icon={li.id as IconProp}
                      color={category === li.id ? li.color : "gray"}
                      size={isMobileScreen ? "lg" : "xl"}
                    />
                  </IconButton>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography fontWeight={500}>Enter notes</Typography>
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    placeholder="Enter your notes, todo list, shout out, news, etc."
                    multiline
                    rows={4}
                    error={Boolean(errors.message)}
                    helperText={errors.message ? errors.message.message : ""}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={500}>Attachment (optional):</Typography>
            <TextField
              fullWidth
              type="file"
              variant="outlined"
              inputProps={{
                accept: "image/*",
              }}
              onChange={async (event: any) => {
                if (event.target.files[0].size < 2097152) {
                  console.log(event.target.files[0]);

                  const formData = new FormData();
                  if (event.target.files[0]) {
                    formData.append("filename", event.target.files[0] as any);
                  }
                  const res = await axios.post(`/upload-media`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  if (res.status === 201 || res.status === 200) {
                    console.log("imageurl", res.data.url);
                    setImage(res.data.url);
                  }
                } else {
                  event.target.value = "";
                }
              }}
              value={undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack mt={1} direction="row" justifyContent="flex-end">
              <LoadingButton
                loading={isButtonLoading}
                loadingPosition="center"
                disableElevation
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{
                  fontWeight: 700,
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </CardCustom>
    </Stack>
  );
};

export default TweetTimeline;
