import React, { ReactNode } from "react";
import { FetchingProvider } from "./fetchingContext";

interface IindexProps {
  children: ReactNode;
}

const AppProvider = ({ children }: IindexProps) => {
  return <FetchingProvider>{children}</FetchingProvider>;
};

export default AppProvider;
