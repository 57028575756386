import Box from "@mui/material/Box";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import FooterLinks from "../../components/FooterLinks/FooterLinks";
import Stack from "@mui/material/Stack";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import axios from "../../services/axios";
import Typography from "@mui/material/Typography";
import { Cookies } from "react-cookie";
import {
  titleMeta,
  faviconMeta,
  logoMeta,
  secondaryColor,
  otpSecuredDocumentMeta,
  advisorEmailMeta,
  advisorMaskedEmailMeta,
  memberEmailMeta,
  memberMaskedEmailMeta,
} from "../../constants/common";
import OtpInput from "react-otp-input";
import FormHelperText from "@mui/material/FormHelperText";
import { TraceSpinner } from "react-spinners-kit";
import "./style.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";

interface IOTPInputs {
  otp: string;
}

const schema = yup
  .object({
    otp: yup.string().required("Please complete this detail."),
  })
  .required();

const OTPLoginMemberPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const cookies = useMemo(() => new Cookies(), []);
  const [logo, setLogo] = useState("");
  const [advisorEmail, setAdvisorEmail] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [advisorMaskedEmail, setAdvisorMaskedEmail] = useState("");
  const [memberMaskedEmail, setMemberMaskedEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const advisorParams = urlParams.get("advisor");

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      const advisorEmail = await cookies.get(advisorEmailMeta);
      const memberEmail = await cookies.get(memberEmailMeta);
      const advisorMaskedEmail = await cookies.get(advisorMaskedEmailMeta);
      const memberMaskedEmail = await cookies.get(memberMaskedEmailMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
      if (advisorEmail) setAdvisorEmail(advisorEmail);
      if (memberEmail) setMemberEmail(memberEmail);
      if (advisorMaskedEmail) setAdvisorMaskedEmail(advisorMaskedEmail);
      if (memberMaskedEmail) setMemberMaskedEmail(memberMaskedEmail);
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  const initialValues = React.useMemo(
    () => ({
      otp: "",
    }),
    []
  );

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IOTPInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IOTPInputs) => {
    setIsLoading(true);
    if (!!advisorParams) {
      try {
        const body = {
          auth_type: "EMAIL_SECRET",
          alias: advisorEmail,
          secret: values.otp,
        };
        const res = await axios.post(`/advisor/auth`, body);
        if (res.status === 200) {
          if (res.data.success) {
            window.location.replace(`/secured-document-vault?id=${id}`);
            cookies.set(otpSecuredDocumentMeta, true);
          } else {
            setIsLoading(false);
            setError("otp", {
              message: "Incorrect, please try again.",
            });
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError("otp", {
          message: "Incorrect, please try again.",
        });
        setIsLoading(false);
      }
    } else {
      try {
        const body = {
          auth_type: "EMAIL_SECRET",
          alias: memberEmail,
          secret: values.otp,
        };
        const res = await axios.post(`/member/auth`, body);
        if (res.status === 200) {
          if (res.data.success) {
            window.location.replace(`/secured-document-vault?id=${id}`);
            cookies.set(otpSecuredDocumentMeta, true);
          } else {
            setIsLoading(false);
            setError("otp", {
              message: "Incorrect, please try again.",
            });
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError("otp", {
          message: "Incorrect, please try again.",
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={3}
      alignItems="center"
      padding={3}
      sx={{ height: "100vh" }}
    >
      <Stack spacing={6} width="100%" alignItems="center">
        <img
          src={logo as string}
          alt="banner"
          style={{
            backgroundSize: "cover",
            color: "#fff",
            height: isMobileScreen ? "100px" : "200px",
            zIndex: 1,
            padding: 0,
            width: "100%",
            objectFit: "contain",
          }}
        />
        {isLoading ? (
          <Stack spacing={2} alignItems="center">
            <TraceSpinner
              frontColor={secondaryColor}
              backColor="#4b4c56"
              loading={isLoading}
            />
            <Typography variant="h6">Please wait…</Typography>
          </Stack>
        ) : (
          <Stack width="100%" alignItems="center">
            <Typography
              variant={isMobileScreen ? "h6" : "h5"}
              textAlign="center"
              fontWeight={700}
            >
              Welcome! We've sent you a code to your email for added safety.
            </Typography>
            <Typography
              variant={isMobileScreen ? "body1" : "h6"}
              textAlign="center"
              mt={3}
            >
              Please check your{" "}
              {!!advisorParams ? advisorMaskedEmail : memberMaskedEmail} for
              this code and enter it here to access your documents.
            </Typography>
            <Box maxWidth="450px" width="100%" borderRadius="8px" mt={6}>
              <form
                style={{
                  width: "100%",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="otp"
                  control={control}
                  rules={{ validate: (value) => value.length === 6 }}
                  render={({ field, fieldState }) => (
                    <Box>
                      <OtpInput
                        numInputs={6}
                        renderInput={(props) => {
                          return <input {...props} />;
                        }}
                        containerStyle={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        inputType="number"
                        inputStyle={{
                          width: "3rem",
                          height: "3rem",
                          borderBottom: "1px solid rgba(0,0,0,.3)",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          fontFamily: "'Gotham',sans-serif",
                          fontSize: "24px",
                          backgroundColor: "transparent",
                        }}
                        {...field}
                      />
                      {errors.otp?.message ? (
                        <FormHelperText error>
                          {errors.otp?.message}
                        </FormHelperText>
                      ) : null}
                    </Box>
                  )}
                />
                <Stack
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                  marginTop={6}
                >
                  <Button
                    disableElevation
                    type="submit"
                    variant="contained"
                    color="buttongreen"
                    sx={{
                      fontWeight: 700,
                      width: 250,
                      textAlign: "center",
                    }}
                  >
                    Log In
                  </Button>
                  <Link
                    color="#326E61"
                    onClick={() => history.goBack()}
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Go back
                  </Link>
                </Stack>
              </form>
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default OTPLoginMemberPage;
