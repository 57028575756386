// import { Cookies } from "react-cookie";
import axios from "axios";

// const cookies = new Cookies();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

// const handleRemoveToken = async () => {
//   try {
//     await cookies.remove(tokenKey);
//   } catch (error) {
//     throw error;
//   }
// };

instance.interceptors.request.use(
  async (config) => {
    // const token = await cookies.get(tokenKey);
    if (config.headers) {
      config.headers["x-api-key"] = String(process.env.REACT_APP_API_KEY);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      //   handleRemoveToken();
    }
    return Promise.reject(error);
  }
);

export default instance;
