import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import useRandomBusiness from "../../services/queries/useRandomBusiness";
import { titleMeta, faviconMeta, secondaryColor } from "../../constants/common";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { Add, Delete, Sync } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import { GetRandomBusinessResponse } from "../../constants/types";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Grid2 from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import { useDropzone } from "react-dropzone";
import { TraceSpinner } from "react-spinners-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

const RandomBusinessPage = () => {
  const cookies = useMemo(() => new Cookies(), []);

  const [paramsRandomBusiness, setParamsRandomBusiness] = useState<number>(5);
  const {
    data: randomBusiness,
    refetch: refetchMemberDocuments,
    isLoading: isLoadingRandomBusiness,
  } = useRandomBusiness(paramsRandomBusiness);

  const handleClick = () => {
    refetchMemberDocuments();
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  return (
    <Container>
      <Stack padding={5} spacing={3}>
        <Stack direction="row" justifyContent="flex-end">
          <LoadingButton
            variant="outlined"
            loading={isLoadingRandomBusiness}
            loadingPosition="start"
            startIcon={<Sync />}
            onClick={handleClick}
          >
            Refetch
          </LoadingButton>
        </Stack>
        <Stack>
          <Box width="100%">
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {randomBusiness && randomBusiness.length > 0
                ? randomBusiness.map((li) => <Fields key={li.id} data={li} />)
                : null}
            </Timeline>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

interface IFields {
  data: GetRandomBusinessResponse;
}

interface IValues {
  websiteUrl: string;
  contacts: {
    name: string;
    email: string;
    phoneNumber: string;
  }[];
}

const Fields = ({ data }: IFields) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setIsDropzoneLoading(true);
    try {
      const formData = new FormData();
      if (acceptedFiles[0]) {
        formData.append("filename", acceptedFiles[0] as any);
      }
      const res = await axios.post(`/upload-media`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 201 || res.status === 200) {
        console.log("documentUrl", res.data.url);
        setDocumentName(acceptedFiles[0].name);
        setDocumentUrl(res.data.url);
      }
      setIsDropzoneLoading(false);
    } catch (error) {
      setIsDropzoneLoading(false);
      console.error(error);
    }
  }, []);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: isDropzoneLoading || isButtonLoading ? true : false,
  });

  const initialValues = useMemo(
    () => ({
      websiteUrl: "",
      contacts: [{ name: "", email: "", phoneNumber: "" }],
    }),
    []
  );

  const schema = yup.object({
    websiteUrl: yup.string().required("Website url is required"),
    contacts: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required("Name is required").max(50),
          email: yup
            .string()
            // .email("Email format is not correct")
            .required("Email is required"),
          phoneNumber: yup.string().required("Phone number is required"),
        })
      )
      .required(),
  });

  const { handleSubmit, control, reset } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: `contacts`,
  });

  const addContact = () => {
    const newField = {
      name: "",
      email: "",
      phoneNumber: "",
    };
    append(newField);
  };

  const onSubmit = async (values: IValues) => {
    setIsButtonLoading(true);
    try {
      const body = {
        status: "REVIEW",
        logo_url: documentUrl,
        website_url: values.websiteUrl,
        campaigns: {
          c2023004: {
            contacts: values.contacts.map((li) => ({
              name: li.name,
              email: li.email,
              phone_number: li.phoneNumber,
            })),
          },
        },
      };
      const res = await axios.patch(`business/${data.id}`, body);
      if (res.status === 200 || res.status === 201 || res.status === 203) {
        Swal.fire({
          title: `Advisor data added`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        reset(initialValues);
        setDocumentName("");
        setDocumentUrl("");
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        ></TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Stack spacing={2} mt={1}>
          <Typography variant="h6" fontWeight={700}>
            {data.legal_name}
          </Typography>
        </Stack>
        <Grid2 container mt={2} spacing={2} alignItems="flex-end">
          {fields.map((field, idx) => (
            <React.Fragment key={field.id}>
              <Grid2 xs={4}>
                <Typography>Contact name:</Typography>
                <Controller
                  name={`contacts.${idx}.name`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="outlined"
                      placeholder="eg. John Smith"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message ?? ""}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={4}>
                <Typography>Contact email:</Typography>
                <Controller
                  name={`contacts.${idx}.email`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="outlined"
                      placeholder="eg. john@mail.com"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message ?? ""}
                      InputProps={{
                        type: "email",
                        sx: {
                          backgroundColor: "#fff",
                        },
                      }}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={3}>
                <Typography>Contact phone:</Typography>
                <Controller
                  name={`contacts.${idx}.phoneNumber`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="outlined"
                      placeholder="eg. 0411 222 333"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message ?? ""}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid2>
              {idx === 0 ? null : (
                <Grid2 xs={1}>
                  <Button
                    onClick={() => remove(idx)}
                    size="large"
                    color="error"
                    sx={{
                      height: "54px",
                    }}
                  >
                    <Delete />
                  </Button>
                </Grid2>
              )}
            </React.Fragment>
          ))}
          <Grid2 xs={11}>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => addContact()}
                startIcon={<Add />}
                // size="small"
                color="buttongreen"
              >
                Add contact
              </Button>
            </Stack>
          </Grid2>
          <Grid2 xs={12}>
            <Typography>Website URL:</Typography>
            <Controller
              name="websiteUrl"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  variant="outlined"
                  placeholder="eg. https://smithco.com"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message ?? ""}
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Stack>
              <Typography>Upload image:</Typography>
              <Stack
                direction="row"
                justifyContent="center"
                border={"1px solid rgba(0,0,0,0.2)"}
                borderRadius={2}
                padding={3}
                bgcolor={"#fff"}
                sx={{
                  opacity:
                    isDragAccept || isDropzoneLoading || isButtonLoading
                      ? 0.5
                      : 1,
                }}
                {...getRootProps({ className: "dropzone" })}
              >
                {isDropzoneLoading ? (
                  <Stack spacing={2} alignItems="center">
                    <TraceSpinner
                      frontColor={secondaryColor}
                      backColor="#4b4c56"
                      loading={isDropzoneLoading}
                    />
                    <Typography variant="h6">Please wait…</Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <input {...getInputProps()} />
                    <Stack spacing={3}>
                      <FontAwesomeIcon
                        icon={"file-arrow-up"}
                        color="#2C3B55"
                        size="5x"
                      />
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        alignItems="center"
                        gap={0.5}
                      >
                        <Typography>Drag and drop files or</Typography>
                        <Link onClick={open} sx={{ cursor: "pointer" }}>
                          browse image
                        </Link>
                      </Stack>
                      {documentName && documentUrl && (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <h4>Image:</h4>
                          <Link
                            href={`${documentUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            textAlign="center"
                          >
                            {documentName}
                          </Link>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
        <Stack mt={2} direction="row" justifyContent="flex-end">
          <LoadingButton
            disabled={!documentUrl}
            loading={isButtonLoading}
            loadingPosition="center"
            disableElevation
            onClick={handleSubmit(onSubmit)}
            type="submit"
            variant="contained"
            color="buttongreen"
            sx={{
              // borderRadius: 50,
              fontWeight: 700,
            }}
          >
            Save
          </LoadingButton>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

export default RandomBusinessPage;
