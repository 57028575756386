import React, { useCallback, useEffect, useMemo, useState } from "react";
import Column from "../../components/GoalList/Column";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import Stack from "@mui/material/Stack";
import useAdvisorGoalList from "../../services/queries/useAdvisorGoalList";
import { Cookies } from "react-cookie";
import {
  titleMeta,
  faviconMeta,
  advisorId,
  defaultColor,
  DRAWER_WIDTH,
} from "../../constants/common";
import {
  GetAdvisorGoalListResponse,
  IColumn,
  IGoal,
} from "../../constants/types";
import Container from "@mui/material/Container";
import { TraceSpinner } from "react-spinners-kit";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const GoalListPage = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  const { data: goalList, isLoading: isLoadingGoalList } =
    useAdvisorGoalList(advisorID);

  const [data, setData] = useState<GetAdvisorGoalListResponse | null>(null);

  useEffect(() => {
    if (goalList) {
      setData(goalList);
    }
  }, [goalList]);

  useEffect(() => console.log("data", data), [data]);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    console.log("destination", destination);
    console.log("source", source);
    console.log("draggableId", draggableId);

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    if (data) {
      const start = data.columns[source.droppableId];
      const finish = data.columns[destination.droppableId];

      if (start === finish) {
        const newGoals = Array.from(start.goals);
        newGoals.splice(source.index, 1);
        newGoals.splice(destination.index, 0, draggableId);

        const newColumn: IColumn = {
          ...start,
          goals: newGoals,
        };

        const newState = {
          ...data,
          columns: {
            ...data.columns,
            [newColumn.id]: newColumn,
          },
        };

        setData(newState);
        setIsButtonLoading(true);

        try {
          const res = await axios.patch(
            `/goal/${draggableId}/status/${destination.droppableId}`
          );
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            Swal.fire({
              title: "Successfully updated",
              position: "bottom-end",
              showConfirmButton: false,
              icon: "success",
              toast: true,
              timer: 3000,
              timerProgressBar: true,
              showCloseButton: true,
              customClass: {
                container: "my-swal",
              },
            });
          }
          setIsButtonLoading(false);
        } catch (error) {
          console.error(error);
          setIsButtonLoading(false);
        }

        return;
      }

      const startGoals = Array.from(start.goals);
      startGoals.splice(source.index, 1);
      const newStart = {
        ...start,
        goals: startGoals,
      };

      const finishGoals = Array.from(finish.goals);
      finishGoals.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        goals: finishGoals,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };

      setData(newState);
      setIsButtonLoading(true);

      try {
        const res = await axios.patch(
          `/goal/${draggableId}/status/${destination.droppableId}`
        );
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          Swal.fire({
            title: "Successfully updated",
            position: "bottom-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
        }
        setIsButtonLoading(false);
      } catch (error) {
        console.error(error);
        setIsButtonLoading(false);
      }
    }
  };

  return (
    <Container sx={{ padding: "0px!important" }}>
      <Stack
        padding={2}
        spacing={3}
        width={isBigScreen ? `calc(100vw - ${DRAWER_WIDTH}px)` : "100vw"}
        sx={{ overflowX: "auto" }}
      >
        {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href="/"
          >
            Home
          </Link>
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href="/dashboard"
          >
            Dashboard
          </Link>
          <Typography variant="body2" fontSize={12} color="text.primary">
            Goal List
          </Typography>
        </Breadcrumbs> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Typography variant="h4" fontWeight={700}>
            Goal List
          </Typography> */}
          {isButtonLoading && (
            <TraceSpinner
              frontColor={defaultColor}
              backColor="#4b4c56"
              loading={isButtonLoading}
            />
          )}
        </Stack>
        {isLoadingGoalList ? (
          <Stack padding={5} alignItems="center" justifyContent="center">
            <TraceSpinner
              frontColor={defaultColor}
              backColor="#4b4c56"
              loading={isLoadingGoalList}
            />
          </Stack>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Stack direction="row" spacing={3} width={1120}>
              {data &&
                data.column_order.map((col) => {
                  const column = data.columns[col];
                  const goals = column.goals.map(
                    (go) => data.goals.find((li) => li.id === go) as IGoal
                  );
                  // const goals = data.tasks.filter((e) => column.goals.includes(e.id));
                  // const goals = column.goals.map((goal) => data.tasks[goal]);

                  return (
                    <Column key={column.id} column={column} goals={goals} />
                  );
                })}
            </Stack>
          </DragDropContext>
        )}
      </Stack>
    </Container>
  );
};

export default GoalListPage;
