import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import { IOptions, ParamsGetMember } from "../../constants/types";
import useMember from "../../services/queries/useMember";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import useSuggestedDocumentTags from "../../services/queries/useSuggestedDocumentTags";
import { TraceSpinner } from "react-spinners-kit";
import { secondaryColor } from "../../constants/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "@mui/material/Link";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ModalTitle from "../ModalTitle/ModalTitle";
import ModalSendEmailUploadDocument from "../ModalSendEmail/ModalSendEmailUploadDocument";
import ModalAreYouSure from "../ModalAreYouSure/ModalAreYouSure";
import TagsCheckbox from "../TagsCheckbox/TagsCheckbox";

interface IModalUploadDocumentProps {
  advisorID: string;
  fullScreen: boolean;
  isOpenModalUploadDocument: boolean;
  closeModalUploadDocument: () => void;
}

interface IValues {
  name: string;
  action: string;
  member: {
    id: string;
    name: string;
    goals: {
      id: string;
      name: string;
    }[];
  };
  goals: {
    id: string;
    name: string;
  } | null;
  tags: string[];
}

enum Action {
  View = "VIEW",
  WetSign = "WET_SIGN",
}

const ModalUploadDocument = ({
  advisorID,
  fullScreen,
  isOpenModalUploadDocument,
  closeModalUploadDocument,
}: IModalUploadDocumentProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const initialParams = {
    advisorId: advisorID,
  };
  const [paramsMember, setParamsMember] =
    useState<ParamsGetMember>(initialParams);
  const { data: member, isLoading: isLoadingMember } = useMember(paramsMember);
  const [selectedGoals, setSelectedGoals] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const { data: suggestedDocumentTags } = useSuggestedDocumentTags(advisorID);
  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);

  const [documentId, setDocumentId] = useState("");
  const [emailFrom, setEmailFrom] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailReplyTo, setEmailReplyTo] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [html, setHtml] = useState("");
  const [memberDocumentVault, setMemberDocumentVault] = useState("");

  const [isOpenModalSendEmail, setIsOpenModalSendEmail] = useState(false);
  const [isOpenModalAreYouSure, setIsOpenModalAreYouSure] = useState(false);

  const openModalSendEmail = () => {
    setIsOpenModalSendEmail(true);
  };
  const closeModalSendEmail = () => setIsOpenModalSendEmail(false);

  const openModalAreYouSure = () => setIsOpenModalAreYouSure(true);
  const closeModalAreYouSure = () => setIsOpenModalAreYouSure(false);

  const handleEditorContent = (content: string) => {
    setHtml(content);
  };

  const mappedMember = member?.map((li) => ({
    id: li.id,
    name: li.name,
    goals: li.goals.map((go) => ({ id: go.id, name: go.title })),
  }));

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setIsDropzoneLoading(true);
    try {
      const formData = new FormData();
      if (acceptedFiles[0]) {
        formData.append("filename", acceptedFiles[0] as any);
      }
      const res = await axios.post(`/upload-document`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 201 || res.status === 200) {
        console.log("documentUrl", res.data.url);
        setDocumentName(acceptedFiles[0].name);
        setDocumentUrl(res.data.url);
      }
      setIsDropzoneLoading(false);
    } catch (error) {
      setIsDropzoneLoading(false);
      console.error(error);
    }
  }, []);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: isDropzoneLoading || isButtonLoading ? true : false,
  });

  const initialValues = useMemo(
    () => ({
      name: "",
      action: Action.View,
      member: undefined,
      goals: undefined,
    }),
    []
  );

  const schema = yup.object({
    name: yup.string().required(),
    action: yup.string().required(),
    member: yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required(),
      goals: yup
        .array()
        .min(1, "Choose one")
        .of(
          yup.object().shape({
            id: yup.string().required(),
            name: yup.string().required(),
          })
        )
        .required(),
    }),
    goals: yup
      .object()
      .shape({
        id: yup.string().required(),
        name: yup.string().required(),
      })
      .nullable(),
    tags: yup.array().min(1).of(yup.string().required()).required(),
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IValues) => {
    setIsButtonLoading(true);

    try {
      const body = {
        name: values.name,
        action: values.action,
        tags: values.tags,
        advisor_id: advisorID,
        member_id: values.member.id,
        goal_id: values.goals?.id,
      };
      const res = await axios.post<{
        document_id: string;
        sendmail: true;
        email_from: string;
        email_to: string;
        email_reply_to: string;
        email_subject: string;
        email_content: string;
        member_document_vault: string;
      }>(`document`, body);
      console.log("res", res);
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        Swal.fire({
          title: `New documents requested`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        console.log("done");
        setDocumentId(res.data.document_id);
        setEmailFrom(res.data.email_from);
        setEmailTo(res.data.email_to);
        setEmailReplyTo(res.data.email_reply_to);
        setEmailSubject(res.data.email_subject);
        setHtml(`${res.data.email_content}`);
        setMemberDocumentVault(res.data.member_document_vault);
        setFetchingItems();
        closeModalUploadDocument();
        openModalSendEmail();
        reset(initialValues);
        setDocumentName("");
        setDocumentUrl("");
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (advisorID) {
      setParamsMember((prev) => ({
        ...prev,
        advisorId: advisorID,
      }));
    }
  }, [advisorID]);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullScreen={fullScreen}
        fullWidth={true}
        open={isOpenModalUploadDocument}
        onClose={() => {
          closeModalUploadDocument();
          reset(initialValues);
          setDocumentName("");
          setDocumentUrl("");
        }}
        scroll="body"
        PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
      >
        <ModalTitle
          onClose={() => {
            closeModalUploadDocument();
            reset(initialValues);
            setDocumentName("");
            setDocumentUrl("");
          }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <InsertDriveFileIcon />
            <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
              Upload document
            </Typography>
          </Stack>
        </ModalTitle>
        <DialogContent dividers sx={{ pb: 8 }}>
          <Grid container gap={2} mt={2}>
            <Grid item xs={12}>
              <Stack>
                <Typography>Document name:</Typography>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="outlined"
                      placeholder="eg. Payslips"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message ?? ""}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography>Document action:</Typography>
                <Controller
                  name="action"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <FormControl error={Boolean(fieldState.error)}>
                      <RadioGroup row value={value} onChange={onChange}>
                        <FormControlLabel
                          value={Action.View}
                          control={<Radio />}
                          label="View only"
                        />
                        <FormControlLabel
                          value={Action.WetSign}
                          control={<Radio />}
                          label="Signture required"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {fieldState.error ? fieldState.error.message : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography>Member:</Typography>
                <Controller
                  name="member"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      disablePortal
                      disableClearable
                      loading={isLoadingMember}
                      options={mappedMember ?? []}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      size="small"
                      value={field.value}
                      onChange={(
                        _event,
                        newOption: {
                          id: string;
                          name: string;
                          goals: {
                            id: string;
                            name: string;
                          }[];
                        } | null
                      ) => {
                        field.onChange(newOption);
                        setValue("goals", null);
                        setSelectedGoals(newOption?.goals);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose Member"
                          error={!!fieldState?.error}
                          helperText={
                            fieldState?.error ? "Choose one member" : ""
                          }
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              backgroundColor: "#fff",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography>Goal:</Typography>
                <Controller
                  name="goals"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      disablePortal
                      loading={isLoadingMember}
                      options={selectedGoals ?? []}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      size="small"
                      value={field.value}
                      onChange={(_event, newOption: IOptions | null) => {
                        field.onChange(newOption);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose goal"
                          error={!!fieldState?.error}
                          helperText={
                            fieldState?.error ? "Choose one goal" : ""
                          }
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              backgroundColor: "#fff",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography>Tags:</Typography>
                <Stack spacing={1}>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => (
                      <TagsCheckbox
                        options={suggestedDocumentTags?.suggested_tags ?? []}
                        value={field.value}
                        onChange={(_event, newTag: string[]) => {
                          field.onChange(newTag);
                          // setLimitReached(newTag.length >= 5);
                        }}
                        freeSolo={true}
                        placeholder="Select from existing suggestion or type a new tag, and press Enter..."
                        error={Boolean(errors.tags)}
                        helperText={errors.tags?.message}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography>Upload file:</Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  border={"1px solid rgba(0,0,0,0.2)"}
                  borderRadius={2}
                  padding={3}
                  bgcolor={"#fff"}
                  sx={{
                    opacity:
                      isDragAccept || isDropzoneLoading || isButtonLoading
                        ? 0.5
                        : 1,
                  }}
                  {...getRootProps({ className: "dropzone" })}
                >
                  {isDropzoneLoading ? (
                    <Stack spacing={2} alignItems="center">
                      <TraceSpinner
                        frontColor={secondaryColor}
                        backColor="#4b4c56"
                        loading={isDropzoneLoading}
                      />
                      <Typography variant="h6">Please wait…</Typography>
                    </Stack>
                  ) : (
                    <Stack>
                      <input {...getInputProps()} />
                      <Stack spacing={3}>
                        <FontAwesomeIcon
                          icon={"file-arrow-up"}
                          color="#2C3B55"
                          size="5x"
                        />
                        <Stack
                          direction={{ xs: "column", md: "row" }}
                          alignItems="center"
                          gap={0.5}
                        >
                          <Typography>Drag and drop files or</Typography>
                          <Link onClick={open} sx={{ cursor: "pointer" }}>
                            browse document
                          </Link>
                        </Stack>
                        {documentName && documentUrl && (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <h4>File:</h4>
                            <Link
                              href={`${documentUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              textAlign="center"
                            >
                              {documentName}
                            </Link>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <LoadingButton
            disabled={!documentUrl}
            loading={isButtonLoading}
            loadingPosition="center"
            disableElevation
            onClick={handleSubmit(onSubmit)}
            type="submit"
            variant="contained"
            color="buttongreen"
            sx={{
              // borderRadius: 50,
              fontWeight: 700,
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ModalSendEmailUploadDocument
        advisorID={advisorID}
        documentID={documentId ?? ""}
        fullScreen={fullScreen}
        open={isOpenModalSendEmail}
        onClose={closeModalSendEmail}
        sendmail={true}
        email_from={emailFrom}
        email_to={emailTo}
        email_reply_to={emailReplyTo}
        email_subject={emailSubject}
        html={html}
        handleEditorContent={handleEditorContent}
        openModalAreYouSure={openModalAreYouSure}
        closeModalAreYouSure={closeModalAreYouSure}
        member_document_vault={memberDocumentVault}
      />
      <ModalAreYouSure
        open={isOpenModalAreYouSure}
        onClose={closeModalAreYouSure}
        onYes={() => {
          closeModalAreYouSure();
          closeModalSendEmail();
        }}
      />
    </>
  );
};

export default ModalUploadDocument;
