import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import { useFetching } from "../../context/fetchingContext";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import useSuburbs from "../../services/queries/useSuburb";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import { Sync } from "@mui/icons-material";
import {
  GetMemberDetailResponse,
  GetSuggestedPhotoResponse,
} from "../../constants/types";
import qs from "query-string";
import DialogActions from "@mui/material/DialogActions";
import useSuggestedMemberTags from "../../services/queries/useSuggestedMemberTags";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalTitle from "../ModalTitle/ModalTitle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import TagsCheckbox from "../TagsCheckbox/TagsCheckbox";

interface IModalAddMemberProps {
  advisorID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  workbookEdition: string;
  memberDetail?: GetMemberDetailResponse;
}

interface IValues {
  name: string;
  email: string;
  phone: string;
  suburb: string;
  tags: string[];
}

const ModalAddMember = ({
  advisorID,
  fullScreen,
  open,
  onClose,
  workbookEdition,
  memberDetail,
}: IModalAddMemberProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoadingGenerateProfilePhoto, setIsLoadingGenerateProfilePhoto] =
    useState(false);
  // const [isLoadingGenerateTags, setIsLoadingGenerateTags] = useState(false);
  const [image, setImage] = useState<File | string>("");
  const { data: suburbs } = useSuburbs();
  const { data: suggestedMemberTags } = useSuggestedMemberTags(advisorID);
  // const [formTags, setFormTags] = useState<string[]>([]);
  const [limitReached, setLimitReached] = useState(false);

  const uniqueSuburbs = [...new Set(suburbs)];

  const initialValues = useMemo(
    () => ({
      name: "",
      email: "",
      phone: "",
      suburb: "",
      tags: [],
    }),
    []
  );

  const schema = yup.object({
    name: yup.string().required("Name is required").max(50),
    email: yup
      .string()
      .email("Email format is not correct")
      .required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    suburb: yup.string().required("Suburb is required"),
    tags: yup.array().min(1).of(yup.string().required()).required(),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const generateProfilePhoto = async () => {
    setIsLoadingGenerateProfilePhoto(true);

    try {
      const urlApi = qs.stringifyUrl({
        url: `suggested-avatar-photo`,
      });
      const res = await axios.get<GetSuggestedPhotoResponse>(urlApi);
      if (res.status === 200 && res.data.url.length > 0) {
        setImage(res.data.url);
        if (memberDetail) {
          try {
            const body = {
              avatar_photo: res.data.url,
            };
            console.log("body", body);
            const response = await axios.patch(
              `member/${memberDetail.id}`,
              body
            );
            if (response.status === 200) {
              Swal.fire({
                title: `Avatar updated`,
                position: "top-end",
                showConfirmButton: false,
                icon: "success",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                showCloseButton: true,
                customClass: {
                  container: "my-swal",
                },
              });
              setFetchingItems();
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      setIsLoadingGenerateProfilePhoto(false);
    } catch (error) {
      setIsLoadingGenerateProfilePhoto(false);
      console.error(error);
    }
  };

  // const generateTags = async () => {
  //   setIsLoadingGenerateTags(true);

  //   try {
  //     const urlApi = qs.stringifyUrl({
  //       url: `suggested-member-attributes`,
  //       query: {
  //         advisor_id: advisorID,
  //       },
  //     });
  //     const res = await axios.get<GetSuggestedTagsResponse>(urlApi);
  //     if (res.status === 200 && res.data.suggested_tags.length > 0) {
  //       setValue("tags", res.data.suggested_tags);
  //     }
  //     setIsLoadingGenerateTags(false);
  //   } catch (error) {
  //     setIsLoadingGenerateTags(false);
  //     console.error(error);
  //   }
  // };

  const onSubmit = async (values: IValues) => {
    setIsButtonLoading(true);
    if (memberDetail) {
      try {
        const body = {
          advisor_id: advisorID,
          name: values.name,
          contact: {
            phone_number: values.phone,
            email: values.email,
            address: {
              suburb: values.suburb,
            },
          },
          tags: values.tags,
          avatar_photo: image ?? "",
          workbook_edition: workbookEdition,
          campaign_id: "c2023003",
        };
        console.log("body", body);
        const res = await axios.patch(`member/${memberDetail.id}`, body);
        if (res.status === 200) {
          Swal.fire({
            title: `Member updated`,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
          // setFormTags([]);
          setFetchingItems();
          onClose();
        }
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
        console.error(error);
      }
    } else {
      try {
        const body = {
          advisor_id: advisorID,
          name: values.name,
          contact: {
            phone_number: values.phone,
            email: values.email,
            address: {
              suburb: values.suburb,
            },
          },
          tags: values.tags,
          avatar_photo: image ?? "",
          workbook_edition: workbookEdition,
          campaign_id: "c2023003",
        };
        const res = await axios.post(`member`, body);
        if (res.status === 200) {
          Swal.fire({
            title: `New member added`,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
          // setFormTags([]);
          setFetchingItems();
          onClose();
        }
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (open) {
      if (memberDetail) {
        reset({
          email: memberDetail.contact.email,
          name: memberDetail.name,
          phone: memberDetail.contact.phone_number,
          suburb: memberDetail.contact.address.suburb,
          tags: memberDetail.tags,
        });
        setImage(memberDetail.avatar_photo);
        // setFormTags(memberDetail.tags);
      } else {
        reset(initialValues);
        setImage("");
        generateProfilePhoto();
      }
    }
  }, [initialValues, memberDetail, open, reset]);

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          {memberDetail ? <EditIcon /> : <AddIcon />}
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            {memberDetail ? "Edit member" : "Add new member"}
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        {/* {memberDetail && (
          <Box marginBottom="26px">
            <ToggleButtonGroup
              color="primary"
              value={section}
              exclusive
              onChange={(_, value) => {
                if (value !== null) {
                  setSection(value);
                }
              }}
              fullWidth
            >
              <ToggleButton value={Section.General}>General</ToggleButton>
              <ToggleButton value={Section.COA}>Chart of Accounts</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )} */}

        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center">
              <label htmlFor="outlined-button-file">
                <Input
                  inputProps={{
                    accept: "image/*",
                    style: {
                      display: "none",
                    },
                  }}
                  id="outlined-button-file"
                  type="file"
                  onChange={async (event: any) => {
                    if (event.target.files[0].size < 2097152) {
                      console.log(event.target.files[0]);

                      const formData = new FormData();
                      if (event.target.files[0]) {
                        formData.append(
                          "filename",
                          event.target.files[0] as any
                        );
                      }
                      const res = await axios.post(`/upload-media`, formData, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      });
                      if (res.status === 201 || res.status === 200) {
                        console.log("imageurl", res.data.url);
                        setImage(res.data.url);
                      }
                    } else {
                      event.target.value = "";
                    }
                  }}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        position: "relative",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        border: "0.5px solid rgba(0,0,0,0.2)",
                      }}
                    >
                      <Box>
                        <PhotoCamera
                          sx={{
                            fontSize: "1rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </Box>
                    </Box>
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    alt="photo"
                    src={typeof image === "string" ? image : ""}
                    sx={{
                      width: 72,
                      height: 72,
                    }}
                  />
                </Badge>
              </label>
            </Stack>
            <Stack direction="row" justifyContent="center" mt={2}>
              <LoadingButton
                loading={isLoadingGenerateProfilePhoto}
                loadingPosition="start"
                onClick={generateProfilePhoto}
                disableElevation
                variant="contained"
                color="buttonlightgreen"
                size="small"
                startIcon={<Sync />}
              >
                Hey AI, generate another photo
              </LoadingButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Name:</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    placeholder="eg. John Smith"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? ""}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Email:</Typography>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    placeholder="eg. john@mail.com"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? ""}
                    InputProps={{
                      type: "email",
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>Phone Number:</Typography>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    placeholder="eg. 0411 222 333"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message ?? ""}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography>Suburb:</Typography>
            <Controller
              name="suburb"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  // loading={isLoadingKategori}
                  disableClearable
                  options={uniqueSuburbs ?? []}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) =>
                    String(option) === String(value)
                  }
                  size="small"
                  value={field.value}
                  onChange={(_event, suburb: string | null) => {
                    field.onChange(suburb);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Choose Suburb --"
                      error={Boolean(errors.suburb)}
                      helperText={errors.suburb && "Please choose one"}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Characteristics:</Typography>
            <Stack spacing={1}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <TagsCheckbox
                    options={suggestedMemberTags?.suggested_tags ?? []}
                    getOptionDisabled={(option) =>
                      limitReached && !field.value.includes(option)
                    }
                    value={field.value}
                    onChange={(_event, newTag: string[]) => {
                      field.onChange(newTag);
                      setLimitReached(newTag.length >= 5);
                    }}
                    freeSolo={field.value.length >= 5 ? false : true}
                    placeholder="Select from existing suggestion or type a new tag, and press Enter..."
                    error={Boolean(errors.tags)}
                    helperText={errors.tags?.message}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddMember;
