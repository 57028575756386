import React, { useEffect, useMemo, useState } from "react";
import { GetMemberDetailResponse } from "../../constants/types";
import { useFetching } from "../../context/fetchingContext";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import ModalTitle from "../ModalTitle/ModalTitle";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import numberValueFormat from "../../utils/numberValueFormat";
import Box from "@mui/material/Box";

interface IModalEditWorksheetProps {
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  data: GetMemberDetailResponse["worksheet"];
}

interface IValues {
  cash: string;
  cashAtBank: string;
  investments: string;
  superannuation: string;
  realEstate: string;
  vehicles: string;
  creditCards: string;
  loans: string;
  mortgage: string;
  otherDebts: string;
  ownersEquity: string;
  salary: string;
  interestIncome: string;
  rentalIncome: string;
  otherIncome: string;
  housing: string;
  utilities: string;
  transportation: string;
  foodAndDining: string;
  healthcare: string;
  entertainment: string;
  debtPayments: string;
  savings: string;
  otherExpenses: string;
}

const ModalEditWorksheet = ({
  fullScreen,
  open,
  onClose,
  data,
}: IModalEditWorksheetProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      cash: "0",
      cashAtBank: "0",
      investments: "0",
      superannuation: "0",
      realEstate: "0",
      vehicles: "0",
      creditCards: "0",
      loans: "0",
      mortgage: "0",
      otherDebts: "0",
      ownersEquity: "0",
      salary: "0",
      interestIncome: "0",
      rentalIncome: "0",
      otherIncome: "0",
      housing: "0",
      utilities: "0",
      transportation: "0",
      foodAndDining: "0",
      healthcare: "0",
      entertainment: "0",
      debtPayments: "0",
      savings: "0",
      otherExpenses: "0",
    }),
    []
  );

  const schema = yup.object({
    cash: yup.string().required("This field is required"),
    cashAtBank: yup.string().required("This field is required"),
    investments: yup.string().required("This field is required"),
    superannuation: yup.string().required("This field is required"),
    realEstate: yup.string().required("This field is required"),
    vehicles: yup.string().required("This field is required"),
    creditCards: yup.string().required("This field is required"),
    loans: yup.string().required("This field is required"),
    mortgage: yup.string().required("This field is required"),
    otherDebts: yup.string().required("This field is required"),
    ownersEquity: yup.string().required("This field is required"),
    salary: yup.string().required("This field is required"),
    interestIncome: yup.string().required("This field is required"),
    rentalIncome: yup.string().required("This field is required"),
    otherIncome: yup.string().required("This field is required"),
    housing: yup.string().required("This field is required"),
    utilities: yup.string().required("This field is required"),
    transportation: yup.string().required("This field is required"),
    foodAndDining: yup.string().required("This field is required"),
    healthcare: yup.string().required("This field is required"),
    entertainment: yup.string().required("This field is required"),
    debtPayments: yup.string().required("This field is required"),
    savings: yup.string().required("This field is required"),
    otherExpenses: yup.string().required("This field is required"),
  });

  const { handleSubmit, control, reset } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IValues) => {
    setIsButtonLoading(true);
    if (data) {
      try {
        const body = {
          Assets: {
            Cash: Number(numberValueFormat(values.cash)),
            "Cash at bank": Number(numberValueFormat(values.cashAtBank)),
            Investments: Number(numberValueFormat(values.investments)),
            Superannuation: Number(numberValueFormat(values.superannuation)),
            "Real Estate": Number(numberValueFormat(values.realEstate)),
            Vehicles: Number(numberValueFormat(values.vehicles)),
          },
          Liabilities: {
            "Credit Cards": Number(numberValueFormat(values.creditCards)),
            Loans: Number(numberValueFormat(values.loans)),
            Mortgage: Number(numberValueFormat(values.mortgage)),
            "Other debts": Number(numberValueFormat(values.otherDebts)),
          },
          Equities: {
            "Owner's Equity": Number(numberValueFormat(values.ownersEquity)),
          },
          Incomes: {
            Frequency: "Monthly",
            Salary: Number(numberValueFormat(values.salary)),
            "Interest Income": Number(numberValueFormat(values.interestIncome)),
            "Rental Income": Number(numberValueFormat(values.rentalIncome)),
            "Other Income": Number(numberValueFormat(values.otherIncome)),
          },
          Expenses: {
            Frequency: "Monthly",
            Housing: Number(numberValueFormat(values.housing)),
            Utilities: Number(numberValueFormat(values.utilities)),
            Transportation: Number(numberValueFormat(values.transportation)),
            "Food and Dining": Number(numberValueFormat(values.foodAndDining)),
            Healthcare: Number(numberValueFormat(values.healthcare)),
            Entertainment: Number(numberValueFormat(values.entertainment)),
            "Debt Payments": Number(numberValueFormat(values.debtPayments)),
            Savings: Number(numberValueFormat(values.savings)),
            "Other Expenses": Number(numberValueFormat(values.otherExpenses)),
          },
        };
        console.log("body", body);
        const res = await axios.patch(`worksheet/${data.id}`, body);
        if (res.status === 200) {
          Swal.fire({
            title: `Member worksheet updated`,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
              container: "my-swal",
            },
          });
          setFetchingItems();
          onClose();
        }
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
        console.error(error);
      }
    }
  };

  interface IWorksheet {
    parent: string;
    child: {
      label: string;
      name: any;
    }[];
  }

  const worksheet: IWorksheet[] = [
    {
      parent: "ASSETS",
      child: [
        { label: "Cash", name: "cash" },
        { label: "Cash at bank", name: "cashAtBank" },
        { label: "Investments", name: "investments" },
        { label: "Superannuation", name: "superannuation" },
        { label: "Real estate", name: "realEstate" },
        { label: "Vehicles", name: "vehicles" },
      ],
    },
    {
      parent: "LIABILITES",
      child: [
        { label: "Credit cards", name: "creditCards" },
        { label: "Loans", name: "loans" },
        { label: "Mortgage", name: "mortgage" },
        { label: "Other debts", name: "otherDebts" },
      ],
    },
    {
      parent: "EQUITIES",
      child: [{ label: "Owner's equity", name: "ownersEquity" }],
    },
    {
      parent: "INCOMES",
      child: [
        { label: "Salary", name: "salary" },
        { label: "Interests income", name: "interestIncome" },
        { label: "Rental income", name: "rentalIncome" },
        { label: "Other income", name: "otherIncome" },
      ],
    },
    {
      parent: "EXPENSES",
      child: [
        { label: "Housing", name: "housing" },
        { label: "Utilities", name: "utilities" },
        { label: "Transport", name: "transportation" },
        { label: "Debt payments", name: "debtPayments" },
        { label: "Entertainment", name: "entertainment" },
        { label: "Food", name: "foodAndDining" },
        { label: "Healthcare", name: "healthcare" },
        { label: "Savings", name: "savings" },
        { label: "Other expenses", name: "otherExpenses" },
      ],
    },
  ];

  useEffect(() => {
    if (open) {
      if (data) {
        reset({
          cash: String(Number(data.Assets.Cash)),
          cashAtBank: String(Number(data.Assets["Cash at bank"])),
          investments: String(Number(data.Assets.Investments)),
          superannuation: String(Number(data.Assets.Superannuation)),
          realEstate: String(Number(data.Assets["Real Estate"])),
          vehicles: String(Number(data.Assets.Vehicles)),
          creditCards: String(Number(data.Liabilities["Credit Cards"])),
          loans: String(Number(data.Liabilities.Loans)),
          mortgage: String(Number(data.Liabilities.Mortgage)),
          otherDebts: String(Number(data.Liabilities["Other debts"])),
          ownersEquity: String(Number(data.Equities["Owner's Equity"])),
          salary: String(Number(data.Incomes.Salary)),
          interestIncome: String(Number(data.Incomes["Interest Income"])),
          rentalIncome: String(Number(data.Incomes["Rental Income"])),
          otherIncome: String(Number(data.Incomes["Other Income"])),
          housing: String(Number(data.Expenses.Housing)),
          utilities: String(Number(data.Expenses.Utilities)),
          transportation: String(Number(data.Expenses.Transportation)),
          foodAndDining: String(Number(data.Expenses["Food and Dining"])),
          healthcare: String(Number(data.Expenses.Healthcare)),
          entertainment: String(Number(data.Expenses.Entertainment)),
          debtPayments: String(Number(data.Expenses["Debt Payments"])),
          savings: String(Number(data.Expenses.Savings)),
          otherExpenses: String(Number(data.Expenses["Other Expenses"])),
        });
      } else {
        reset(initialValues);
      }
    }
  }, [data, initialValues, open, reset]);

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      //   scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <EditIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Edit worksheet
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers sx={{ padding: 4 }}>
        {worksheet.map((wo, idx) => (
          <Box key={wo.parent} mt={idx !== 0 ? 6 : undefined}>
            <Typography variant="h6" color="#7D7D7D" mb={2}>
              {wo.parent}
            </Typography>
            {wo.child.map((ch) => (
              <Grid key={ch.label} container mb={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography color="#4A4A4A" fontWeight={500}>
                    {ch.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Controller
                    name={ch.name}
                    control={control}
                    render={({ field, fieldState }) => (
                      <NumericFormat
                        thousandSeparator
                        customInput={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder={"0"}
                        required
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : ""
                        }
                        decimalScale={0}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          sx: {
                            backgroundColor: "#fff",
                          },
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        ))}
        {/* <Typography variant="h6" color="#7D7D7D" mb={1}>
          ASSETS
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography color="#4A4A4A" fontWeight={500}>
              Cash
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              name="cash"
              control={control}
              render={({ field, fieldState }) => (
                <NumericFormat
                  thousandSeparator
                  customInput={TextField}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={"0"}
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  decimalScale={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#fff",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color="#4A4A4A" fontWeight={500}>
              Cash at bank
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              name="cashAtBank"
              control={control}
              render={({ field, fieldState }) => (
                <NumericFormat
                  thousandSeparator
                  customInput={TextField}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={"0"}
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  decimalScale={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#fff",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color="#4A4A4A" fontWeight={500}>
              Investments
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              name="investments"
              control={control}
              render={({ field, fieldState }) => (
                <NumericFormat
                  thousandSeparator
                  customInput={TextField}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={"0"}
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  decimalScale={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#fff",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="#4A4A4A" fontWeight={500}>
              Superannuation
            </Typography>
            <Controller
              name="superannuation"
              control={control}
              render={({ field, fieldState }) => (
                <NumericFormat
                  thousandSeparator
                  customInput={TextField}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={"0"}
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  decimalScale={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#fff",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Real estate
              </Typography>
              <Controller
                name="realEstate"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Vehicles
              </Typography>
              <Controller
                name="vehicles"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="h6" color="#7D7D7D" mt={3} mb={1}>
          Liabilities
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Credit cards
              </Typography>
              <Controller
                name="creditCards"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Loans
              </Typography>
              <Controller
                name="loans"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Mortgage
              </Typography>
              <Controller
                name="mortgage"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Other debts
              </Typography>
              <Controller
                name="otherDebts"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="h6" color="#7D7D7D" mt={3} mb={1}>
          Equities
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Owner's equity
              </Typography>
              <Controller
                name="ownersEquity"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="h6" color="#7D7D7D" mt={3} mb={1}>
          Incomes
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Salary
              </Typography>
              <Controller
                name="salary"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Interests income
              </Typography>
              <Controller
                name="interestIncome"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Rental income
              </Typography>
              <Controller
                name="rentalIncome"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Other income
              </Typography>
              <Controller
                name="otherIncome"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="h6" color="#7D7D7D" mt={3} mb={1}>
          Expenses
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Housing
              </Typography>
              <Controller
                name="housing"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Utilities
              </Typography>
              <Controller
                name="utilities"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Transport
              </Typography>
              <Controller
                name="transportation"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Debt payments
              </Typography>
              <Controller
                name="debtPayments"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Entertainment
              </Typography>
              <Controller
                name="entertainment"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Food
              </Typography>
              <Controller
                name="foodAndDining"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Healthcare
              </Typography>
              <Controller
                name="healthcare"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Savings
              </Typography>
              <Controller
                name="savings"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography color="#4A4A4A" fontWeight={500}>
                Other expenses
              </Typography>
              <Controller
                name="otherExpenses"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={"0"}
                    required
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    decimalScale={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "#fff",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid> */}
      </DialogContent>
      <DialogActions sx={{ py: 3, px: 4 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditWorksheet;
