import Box from "@mui/material/Box";
import { DataGridProps } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import React from "react";

const DataGridCustom = ({
  rows,
  getRowId,
  columns,
  ...props
}: DataGridProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: 1,
        border: "1px solid #cdcdcd",
        "& .headerColumn": {
          backgroundColor: "#E4EEE8",
          borderColor: "#E4EEE8",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          color: "#525252",
        },
        "& .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          visibility: "visible",
          width: "0 !important",
        },
      }}
    >
      <DataGrid
        pagination
        rows={rows}
        getRowId={getRowId}
        columns={columns}
        // components={{
        //     ColumnUnsortedIcon: () => {
        //         return <ImportExport />;
        //     },
        //     NoRowsOverlay: () => {
        //         return <Box></Box>;
        //     },
        //     LoadingOverlay: () => {
        //         return <Box></Box>;
        //     },
        // }}
        // rowsPerPageOptions={[10]}
        disableColumnMenu
        // rowCount={props.data.daily.length}
        disableRowSelectionOnClick
        sx={{
          borderWidth: 0,
        }}
        autoHeight
        // density="comfortable"
        {...props}
        // hideFooterPagination
      />
    </Box>
  );
};

export default DataGridCustom;
