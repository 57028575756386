import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SecuredDocumentIllust from "../../assets/images/SecuredDocumentIllust.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Cookies } from "react-cookie";
import useGoalDocuments from "../../services/queries/useGoalDocuments";
import {
  titleMeta,
  faviconMeta,
  secondaryColor,
  otpSecuredDocumentMeta,
  defaultColor,
  advisorEmailMeta,
  advisorMaskedEmailMeta,
  advisorMaskedNameMeta,
  memberEmailMeta,
  memberMaskedEmailMeta,
  memberMaskedNameMeta,
} from "../../constants/common";
import Avatar from "@mui/material/Avatar";
import { useDropzone } from "react-dropzone";
import Link from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../services/axios";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import Check from "@mui/icons-material/Check";
import TimelineContent from "@mui/lab/TimelineContent";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import { GoalDocument } from "../../constants/types";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import { useFetching } from "../../context/fetchingContext";
import { TraceSpinner } from "react-spinners-kit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const SecuredDocumentVaultPage = () => {
  const cookies = useMemo(() => new Cookies(), []);
  const otp = cookies.get(otpSecuredDocumentMeta);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  useEffect(() => console.log("otp", !!otp), [otp]);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();

  const [paramsGoalDocuments, setParamsGoalDocuments] = useState<string | null>(
    id
  );
  const {
    data: goalDocuments,
    refetch: refetchMemberDocuments,
    isError,
    isLoading: isLoadingGoalDocuments,
    isSuccess,
  } = useGoalDocuments(paramsGoalDocuments);

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (id) {
      setParamsGoalDocuments(id);
    }
  }, [id]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchMemberDocuments();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchMemberDocuments]);

  useEffect(() => {
    if (!!otp === false) {
      const fetchData = async () => {
        // const res = await axios.get(
        //   `/member/login/${goalDocuments?.member_email}`
        // );
        // cookies.set(memberAlias, goalDocuments?.member_email);
        // if (res.data.success) {
        if (isSuccess) {
          cookies.set(advisorEmailMeta, goalDocuments?.advisor_email);
          cookies.set(memberEmailMeta, goalDocuments?.member_email);
          cookies.set(
            advisorMaskedEmailMeta,
            goalDocuments?.advisor_masked_email
          );
          cookies.set(
            memberMaskedEmailMeta,
            goalDocuments?.member_masked_email
          );
          cookies.set(
            advisorMaskedNameMeta,
            goalDocuments?.advisor_masked_name
          );
          cookies.set(memberMaskedNameMeta, goalDocuments?.member_masked_name);
          window.location.replace(`/otp-login-member-advisor?id=${id}`);
        }
        // }
      };

      fetchData().catch(console.error);
    }
  }, [
    cookies,
    goalDocuments?.advisor_email,
    goalDocuments?.advisor_masked_email,
    goalDocuments?.advisor_masked_name,
    goalDocuments?.member_email,
    goalDocuments?.member_masked_email,
    goalDocuments?.member_masked_name,
    id,
    isSuccess,
    otp,
  ]);

  if (isLoadingGoalDocuments) {
    return (
      <Container>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <TraceSpinner
            frontColor={defaultColor}
            backColor="#4b4c56"
            loading={true}
          />
        </Stack>
      </Container>
    );
  }

  if (isError) {
    return (
      <Box>
        <Container>
          <Grid container padding={5} bgcolor="#2C3B55">
            <Grid item xs={12} md={6}>
              <Stack height="100%" justifyContent="center">
                <Typography variant="h4" fontWeight={700} color="white">
                  Secured Document Vault
                </Typography>
                <Typography
                  mt={3}
                  color="white"
                  fontSize={isMobileScreen ? undefined : 20}
                >
                  Apologies
                </Typography>
                <Typography
                  mt={3}
                  color="white"
                  fontSize={isMobileScreen ? undefined : 20}
                >
                  The request link you are accessing has expired. Please contact
                  your advisor to re-send.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={0}></Grid>
            <Grid item xs={12} md={6} textAlign="center">
              <img
                src={SecuredDocumentIllust}
                alt="illust"
                style={{
                  // position: "absolute",
                  // top: 0,
                  // left: 0,
                  // right: 0,
                  // bottom: 0,
                  // objectPosition: "50%",
                  // color: "#fff",
                  // height: "100%",
                  // zIndex: 1,
                  // padding: 0,
                  width: "70%",
                  // objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <>
      {goalDocuments && goalDocuments.documents.length > 0 ? (
        <Box>
          <Container>
            <Grid container padding={5} bgcolor="#2C3B55">
              <Grid item xs={12} md={6}>
                <Stack height="100%" justifyContent="center">
                  <Typography variant="h4" fontWeight={700} color="white">
                    Secured Document Vault
                  </Typography>
                  <Typography
                    mt={3}
                    color="white"
                    fontSize={isMobileScreen ? undefined : 20}
                  >
                    Welcome <b>{goalDocuments?.member_name}</b>,
                  </Typography>
                  <Typography
                    mt={3}
                    color="white"
                    fontSize={isMobileScreen ? undefined : 20}
                  >
                    Secured Document Vault uses SSL (Secure Sockets Layer)
                    encryption, an industry-standard protocol that ensures all
                    data transmitted between your device and our servers is
                    encrypted and secure. This means that your documents remain
                    confidential and protected at all time.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={0}></Grid>
              <Grid item xs={12} md={6} textAlign="center">
                <img
                  src={SecuredDocumentIllust}
                  alt="illust"
                  style={{
                    // position: "absolute",
                    // top: 0,
                    // left: 0,
                    // right: 0,
                    // bottom: 0,
                    // objectPosition: "50%",
                    // color: "#fff",
                    // height: "100%",
                    // zIndex: 1,
                    // padding: 0,
                    width: "70%",
                    // objectFit: "cover",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg">
            <Stack spacing={3} py={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 80,
                    height: 80,
                  }}
                  alt={goalDocuments?.advisor_name}
                  src={goalDocuments?.advisor_avatar_photo}
                />
                <Stack direction="row" gap={0.5} alignItems="center">
                  <Typography
                    fontSize={isMobileScreen ? undefined : 20}
                    fontWeight={700}
                  >
                    {goalDocuments?.advisor_name}
                  </Typography>
                  <Typography fontSize={isMobileScreen ? undefined : 20}>
                    has requested attention/action on the following documents.
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Box width="100%">
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {goalDocuments && goalDocuments.documents.length > 0
                      ? goalDocuments?.documents.map((li) => (
                          <Dropzone key={li.id} data={li} />
                        ))
                      : null}
                  </Timeline>
                </Box>
              </Stack>
            </Stack>
          </Container>
        </Box>
      ) : (
        <Box>
          <Container>
            <Grid container padding={5} bgcolor="#2C3B55">
              <Grid item xs={12} md={6}>
                <Stack height="100%" justifyContent="center">
                  <Typography variant="h4" fontWeight={700} color="white">
                    Secured Document Vault
                  </Typography>
                  <Typography
                    mt={3}
                    color="white"
                    fontSize={isMobileScreen ? undefined : 20}
                  >
                    Apologies
                  </Typography>
                  <Typography
                    mt={3}
                    color="white"
                    fontSize={isMobileScreen ? undefined : 20}
                  >
                    The request link you are accessing has expired. Please
                    contact your advisor to re-send.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={0}></Grid>
              <Grid item xs={12} md={6} textAlign="center">
                <img
                  src={SecuredDocumentIllust}
                  alt="illust"
                  style={{
                    width: "70%",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

interface IDropZone {
  data: GoalDocument;
}

const Dropzone = ({ data }: IDropZone) => {
  //   const [myFiles, setMyFiles] = useState<File[]>([]);
  const { setFetchingItems } = useFetching();
  const [documentName, setDocumentName] = useState(data.name);
  const [documentUrl, setDocumentUrl] = useState(data.url);
  const [documentComment, setDocumentComment] = useState(data.comment);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);

  useEffect(() => console.log("documentUrl", documentUrl), [documentUrl]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsDropzoneLoading(true);
      try {
        const formData = new FormData();
        if (acceptedFiles[0]) {
          formData.append("filename", acceptedFiles[0] as any);
        }
        const res = await axios.post(`/upload-document`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status === 201 || res.status === 200) {
          console.log("documentUrl", res.data.url);
          setDocumentName(acceptedFiles[0].name);
          setDocumentUrl(res.data.url);
          const body = {
            url: res.data.url,
            modified_by: "member",
          };
          const response = await axios.patch(`document/${data.id}`, body);
          if (response.status === 201 || response.status === 200) {
            Swal.fire({
              title: `${data.name} succesfully uploaded`,
              position: "top-end",
              showConfirmButton: false,
              icon: "success",
              toast: true,
              timer: 3000,
              timerProgressBar: true,
              showCloseButton: true,
              customClass: {
                container: "my-swal",
              },
            });
            setFetchingItems();
          }
        }
        setIsDropzoneLoading(false);
      } catch (error) {
        setIsDropzoneLoading(false);
        console.error(error);
      }
    },
    [data.id, data.name, setFetchingItems]
  );

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: isDropzoneLoading || isButtonLoading ? true : false,
  });

  const onSubmit = async () => {
    setIsButtonLoading(true);

    try {
      const body = {
        url: documentUrl,
        modified_by: "member",
        comment: documentComment,
      };
      console.log("Body", body);
      const res = await axios.patch(`document/${data.id}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: `${data.name} succesfully uploaded`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setFetchingItems();
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  //   const removeFile = (file: File) => () => {
  //     const newFiles = [...myFiles];
  //     newFiles.splice(newFiles.indexOf(file), 1);
  //     setMyFiles(newFiles);
  //   };

  //   const files = myFiles.map((file) => (
  //     <li key={file.name}>
  //       {file.name} - {file.size} bytes{" "}
  //       <button onClick={removeFile(file)}>Remove File</button>
  //     </li>
  //   ));

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={data.url ? "buttongreen" : "buttonorange"}
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          {data.url && <Check htmlColor="#fff" fontSize="small" />}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Stack spacing={2} mt={1}>
          <Typography variant="h6" fontWeight={700}>
            {data.name}
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            border={"1px solid rgba(0,0,0,0.2)"}
            borderRadius={2}
            padding={3}
            bgcolor={"#F7F6F1"}
            sx={{
              opacity:
                isDragAccept || isDropzoneLoading || isButtonLoading ? 0.5 : 1,
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            {isDropzoneLoading ? (
              <Stack spacing={2} alignItems="center">
                <TraceSpinner
                  frontColor={secondaryColor}
                  backColor="#4b4c56"
                  loading={isDropzoneLoading}
                />
                <Typography variant="h6">Please wait…</Typography>
              </Stack>
            ) : (
              <Stack>
                <input {...getInputProps()} />
                <Stack spacing={6}>
                  {data.action === "VIEW" ? (
                    <FontAwesomeIcon
                      icon={"file-circle-check"}
                      color="#2C3B55"
                      size="5x"
                    />
                  ) : data.action === "WET_SIGN" ? (
                    <Stack
                      direction="row"
                      gap={3}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <FontAwesomeIcon
                        icon={"file-arrow-down"}
                        color="#2C3B55"
                        size="5x"
                      />
                      <Box marginRight={1}>
                        <ArrowForwardIcon
                          htmlColor="#2C3B55"
                          fontSize="large"
                        />
                      </Box>
                      <FontAwesomeIcon
                        icon={"file-signature"}
                        color="#2C3B55"
                        size="5x"
                      />
                      <Box>
                        <ArrowForwardIcon
                          htmlColor="#2C3B55"
                          fontSize="large"
                        />
                      </Box>
                      <FontAwesomeIcon
                        icon={"file-arrow-up"}
                        color="#2C3B55"
                        size="5x"
                      />
                    </Stack>
                  ) : (
                    <FontAwesomeIcon
                      icon={"file-arrow-up"}
                      color="#2C3B55"
                      size="5x"
                    />
                  )}
                  {data.action !== "REQUEST" && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Link
                        href={`${data.url}`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ cursor: "pointer" }}
                      >
                        Download document
                      </Link>
                    </Stack>
                  )}
                  {data.action !== "VIEW" && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={0.5}
                    >
                      <Typography>Drag and drop files or</Typography>
                      <Link onClick={open} sx={{ cursor: "pointer" }}>
                        browse document
                      </Link>
                    </Stack>
                  )}
                  {data.action !== "VIEW" &&
                    documentUrl !== data.url &&
                    documentName &&
                    documentUrl && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <h4>File:</h4>
                        <Link
                          href={`${documentUrl}`}
                          target="_blank"
                          rel="noreferrer"
                          textAlign="center"
                        >
                          {documentName}
                        </Link>
                      </Stack>
                    )}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Grid2 container spacing={1}>
            <Grid2 xs={8} md={10}>
              <TextField
                fullWidth
                multiline
                size="small"
                variant="outlined"
                placeholder={"Add comment here"}
                value={documentComment}
                onChange={(e) => setDocumentComment(e.target.value)}
              />
            </Grid2>
            <Grid2 xs={4} md={2}>
              <LoadingButton
                loading={isButtonLoading}
                loadingPosition="center"
                disableElevation
                fullWidth
                onClick={onSubmit}
                type="submit"
                variant="contained"
                color="buttongreen"
                sx={{
                  // borderRadius: 50,
                  fontWeight: 700,
                }}
              >
                Save
              </LoadingButton>
            </Grid2>
          </Grid2>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

export default SecuredDocumentVaultPage;
