import React, { useEffect, useMemo, useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalTitle from "../ModalTitle/ModalTitle";
import EditIcon from "@mui/icons-material/Edit";

interface IModalEditEmailProps {
  advisorID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  email: string;
}

interface IValues {
  email: string;
}

const ModalEditEmail = ({
  advisorID,
  fullScreen,
  open,
  onClose,
  email,
}: IModalEditEmailProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      email: email,
    }),
    [email]
  );

  const schema = yup.object({
    email: yup
      .string()
      .email("Email format is not correct")
      .required("Email is required"),
  });

  const { handleSubmit, control, reset } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IValues) => {
    console.log(values);
    setIsButtonLoading(true);

    try {
      const body = {
        id: advisorID,
        email: values.email,
      };
      console.log("Body", body);
      const res = await axios.patch(`/advisor/${advisorID}`, body);
      if (res.status === 200) {
        Swal.fire({
          title: `Email successfully edited`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setFetchingItems();
        onClose();
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      reset(initialValues);
    }
  }, [initialValues, open, reset]);

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={onClose}>
        <Stack direction="row" alignItems="center" gap={2}>
          <EditIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Edit email
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12}>
            <Typography>Name:</Typography>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="eg. john@mail.com"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message ?? ""}
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditEmail;
