import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import {
  GetMemberDetailResponse,
  ParamsGetMemberDetail,
} from "../../constants/types";

const handleRequest = async (params: ParamsGetMemberDetail) => {
  const urlApi = qs.stringifyUrl({
    url: `/member/${params.memberID}`,
    query: {
      allowed_access_document_id: params.documentID,
    },
  });
  const { data } = await axios.get<GetMemberDetailResponse>(urlApi);
  return data;
};

export default function useMemberDetail(params: ParamsGetMemberDetail) {
  return useQuery<GetMemberDetailResponse>(
    ["memberDetail", params],
    () => handleRequest(params),
    {
      enabled: !!params.memberID,
    }
  );
}
