import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useMember from "../../services/queries/useMember";
import { ParamsGetMember } from "../../constants/types";
import { Cookies } from "react-cookie";
import {
  advisorId,
  faviconMeta,
  titleMeta,
  workbookEditionMeta,
} from "../../constants/common";
import { useFetching } from "../../context/fetchingContext";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ModalAddMember from "../../components/ModalAddMember/ModalAddMember";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";
import { ReactComponent as NoGoalIllust } from "../../assets/images/NoGoalIllust.svg";

const MemberPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const [workbookEdition, setWorkbookEdition] = useState("");
  const initialParams = {
    advisorId: undefined,
  };
  const [paramsMember, setParamsMember] =
    useState<ParamsGetMember>(initialParams);
  const {
    data: member,
    refetch: refetchMember,
    isLoading: isLoadingMember,
  } = useMember(paramsMember);
  const [search, setSearch] = useState("");
  const [isOpenModalAddMember, setIsOpenModalAddMember] = useState(false);

  const openModalAddMember = () => setIsOpenModalAddMember(true);
  const closeModalAddMember = () => setIsOpenModalAddMember(false);

  let filteredMember =
    member &&
    member.filter((li) => {
      const s = li.name.toLowerCase();
      const t = li.tags;
      return (
        s.includes(search.toLowerCase()) || t?.includes(search.toLowerCase())
      );
    });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      const getWorkbookEdition = await cookies.get(workbookEditionMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
      if (getWorkbookEdition) {
        setWorkbookEdition(getWorkbookEdition);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (advisorID) {
      setParamsMember((prev) => ({
        ...prev,
        advisorId: advisorID,
      }));
    }
  }, [advisorID]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchMember();
      cancelFetchingItems();
    }
  }, [cancelFetchingItems, isFetchingItems, refetchMember]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: isSmallMobileScreen ? "75%" : isMobileScreen ? "60%" : "80%",
    height: 20,
    borderRadius: 200,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 200,
    },
  }));

  return (
    <Container>
      <Stack spacing={3} padding={2}>
        {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href="/"
          >
            Home
          </Link>
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href="/dashboard"
          >
            Dashboard
          </Link>
          <Typography variant="body2" fontSize={12} color="text.primary">
            Members
          </Typography>
        </Breadcrumbs>
        <Typography variant="h4" fontWeight={700}>
          Member List
        </Typography> */}
        <Box>
          <TextField
            fullWidth
            placeholder="Search"
            value={search}
            size="small"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#fff",
              },
            }}
          />
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          gap={3}
        >
          <Box
            component={ListItemButton}
            onClick={openModalAddMember}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            border="1px dashed #B7B7B7"
            borderRadius="6px"
            gap={2}
            padding={3}
            sx={{
              background: "#F7F6F1",
              cursor: "pointer",
              minHeight: "300px",
            }}
          >
            <AddIcon
              htmlColor="#B7B7B7"
              sx={{ width: "56px", height: "56px" }}
            />
            <Typography
              variant="h6"
              // fontWeight="bold"
              fontSize="21px"
              color="#B7B7B7"
            >
              Add Member
            </Typography>
          </Box>
          {isLoadingMember && (
            <Stack padding={5} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
          {filteredMember?.map((li, idx) => (
            <Box
              key={idx.toString()}
              component={ListItemButton}
              display="block"
              gap={3}
              onClick={() => {
                if (li.demo_member || li.id === "0") return;
                history.push(`/member-detail?id=${li.id}`);
              }}
              sx={{
                border: "1px solid #B7B7B7",
                background: "#F7F6F1",
                borderRadius: "6px",
                cursor: "pointer",
                padding: 0,
                minHeight: 300,
              }}
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                gap={2}
                padding={3}
              >
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 50,
                    height: 50,
                  }}
                  alt={li.name}
                  src={li.avatar_photo}
                />
                <Stack>
                  <Typography variant="h6" fontWeight={500}>
                    {li.name}
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" gap={1}>
                    {li.tags &&
                      li.tags.length > 0 &&
                      li?.tags.map((ta) => (
                        <Chip
                          key={ta}
                          label={
                            <Typography variant="body2" fontWeight={500}>
                              {ta}
                            </Typography>
                          }
                          color="chiplightgray"
                        />
                      ))}
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={3} padding={3}>
                {li.goals && li.goals.length > 0 ? (
                  li.goals.slice(0, 2).map((go, idx) => (
                    <Stack
                      key={idx.toString()}
                      padding={2}
                      border="1px solid rgba(0,0,0,0.2)"
                      borderRadius={2}
                      gap="12px"
                    >
                      <Typography>{go.title}</Typography>
                      {go.total_tasks &&
                      go.completed_tasks &&
                      go.task_completion_percentage &&
                      go.total_tasks > 0 ? (
                        <Stack direction="row" gap={2} alignItems="center">
                          <BorderLinearProgress
                            variant="determinate"
                            value={Number(go.task_completion_percentage)}
                            color={
                              Number(go.task_completion_percentage) <= 40
                                ? "barunder40"
                                : Number(go.task_completion_percentage) >= 70
                                ? "barover70"
                                : "bar4070"
                            }
                          />
                          <Typography
                            width="10%"
                            fontWeight={700}
                            color={
                              Number(go.task_completion_percentage) <= 40
                                ? "#c24b47"
                                : Number(go.task_completion_percentage) >= 70
                                ? "#91c467"
                                : "#389bbb"
                            }
                          >
                            {go.task_completion_percentage}%
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack
                          direction="row"
                          gap={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="caption" color="GrayText">
                            No tasks yet
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    padding={3}
                  >
                    <NoGoalIllust
                      style={{
                        height: "fit-content",
                        width: "50%",
                      }}
                    />
                    <Typography variant="h6" color="#7D7D7D">
                      No goals yet
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Box>
          ))}
        </Box>
      </Stack>
      <ModalAddMember
        advisorID={advisorID}
        fullScreen={isMobileScreen}
        open={isOpenModalAddMember}
        onClose={closeModalAddMember}
        workbookEdition={workbookEdition}
      />
    </Container>
  );
};

export default MemberPage;
