import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Goal from "./Goal";
import Stack from "@mui/material/Stack";
import { Droppable } from "react-beautiful-dnd";
import { IColumn, IGoal } from "../../constants/types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface IColumnProps {
  column: IColumn;
  goals: IGoal[];
}

const Column = ({ column, goals }: IColumnProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card variant="outlined" sx={{ width: 300, bgcolor: "#f7f7f7" }}>
      <CardContent
        sx={{
          p: 0,
          pb: "0px !important",
          bgcolor: "#f7f7f7",
        }}
      >
        <Stack
          margin={1}
          paddingY={1}
          paddingX={2}
          bgcolor={
            column.name === "Review"
              ? "#ED9D64"
              : column.name === "Active"
              ? "#F3BB94"
              : column.name === "Proposed"
              ? "#82B8A8"
              : "#326E61"
          }
          borderRadius={1}
        >
          <Typography
            variant={isMobileScreen ? "body1" : "h6"}
            fontWeight={700}
            color="#fff"
          >
            {column.name}
          </Typography>
        </Stack>
        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <Stack
              padding={1}
              spacing={1}
              ref={provided.innerRef}
              {...provided.droppableProps}
              bgcolor={snapshot.isDraggingOver ? "#e9e3ed" : "#f7f7f7"}
              sx={{
                transition: "all 0.2s ease",
                borderTop: "1px solid rgba(0,0,0,0.12)",
              }}
              height={`calc(100vh - 200px)`}
            >
              <Stack spacing={1}>
                {goals.map((goal, index) => (
                  <Goal key={goal.id} goal={goal} index={index} />
                ))}
              </Stack>
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </CardContent>
    </Card>
  );
};

export default Column;
