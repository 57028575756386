import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import axios from "../../services/axios";
import {
  GetAdvisorLoginResponse,
  ParamsGetBusinessData,
} from "../../constants/types";
import ListItemButton from "@mui/material/ListItemButton";
import { Cookies } from "react-cookie";
import {
  titleMeta,
  faviconMeta,
  logoMeta,
  licenseeAlias,
  aliasMeta,
  secondaryColor,
  defaultColor,
} from "../../constants/common";
import useBusinessData from "../../services/queries/useBusinessData";
import { useFetching } from "../../context/fetchingContext";
import useTweet from "../../services/queries/useTweet";
import useServiceRequest from "../../services/queries/useServiceRequest";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import TweetTimeline from "../../components/TweetTimeline/TweetTimeline";
import { TraceSpinner } from "react-spinners-kit";
import ServiceRequestTimeline from "../../components/ServiceRequestTimeline/ServiceRequestTimeline";
import maskEmail from "../../utils/maskEmail";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS } from "react-joyride";
import TooltipJoyride from "../../components/TooltipJoyride/TooltipJoyride";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
    </div>
  );
}

const LicenseeFoundPagev2 = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { isFetchingItems, cancelFetchingItems } = useFetching();
  const cookies = useMemo(() => new Cookies(), []);
  const [logo, setLogo] = useState("");
  const [alias, setAlias] = useState("");
  const [tab, setTab] = useState(0);

  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, index, status, type } = data;

    console.log("action", action);
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      console.log("next step");
      const body = {
        feature_flags: [
          {
            name: "walkthrough_login",
            seen: true,
          },
        ],
      };
      if (action === ACTIONS.NEXT) {
        try {
          const res = await axios.patch(`business/${alias}`, body);
          if (res.status === 200 || res.status === 201) {
            setStepIndex(index + 1);
          }
        } catch (error) {
          console.error(error);
        }
      } else if (action === ACTIONS.PREV) {
        setStepIndex(index - 1);
      } else {
        setRun(false);
      }
    } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      const body = {
        feature_flags: [
          {
            name: "walkthrough_service_request",
            seen: true,
          },
        ],
      };
      if (action === ACTIONS.NEXT) {
        try {
          const res = await axios.patch(`business/${alias}`, body);
          if (res.status === 200 || res.status === 201) {
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setRun(false);
      }
    }

    // console.log("type", type);
    // console.log("data", data); //eslint-disable-line no-console
    // console.groupEnd();
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const initialParamsBusinessData = {
    businessAlias: alias,
    filter: "",
  };
  const [paramsBusinessData, setParamsBusinessData] =
    useState<ParamsGetBusinessData>(initialParamsBusinessData);
  const {
    data: businessData,
    isLoading: isLoadingBusinessData,
    isSuccess: isSuccessBusinessData,
  } = useBusinessData(paramsBusinessData);

  const initialParamsTweet = {
    businessAlias: alias,
    filter: "tweet",
  };
  const [paramsTweet, setParamsTweet] =
    useState<ParamsGetBusinessData>(initialParamsTweet);
  const { data: tweet, refetch: refetchTweet } = useTweet(paramsTweet);

  const initialParamsServiceRequest = {
    businessAlias: alias,
    filter: "service-request",
  };
  const [paramsServiceRequest, setParamsServiceRequest] =
    useState<ParamsGetBusinessData>(initialParamsServiceRequest);
  const { data: serviceRequest, refetch: refetchServiceRequest } =
    useServiceRequest(paramsServiceRequest);

  const filteredSteps = businessData?.feature_flags
    ?.filter((st) => st.seen === false)
    .map((li, idx) => ({
      content: li.message,
      target: li.sequence === 1 ? "#advisorsList" : "#serviceRequestTab",
      disableBeacon: true,
    }));

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      const getLicenseeAlias = await cookies.get(licenseeAlias);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
      if (getLicenseeAlias) {
        setAlias(getLicenseeAlias);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (alias) {
      setParamsBusinessData((prev) => ({
        ...prev,
        businessAlias: alias,
      }));
      setParamsTweet((prev) => ({
        ...prev,
        businessAlias: alias,
      }));
      setParamsServiceRequest((prev) => ({
        ...prev,
        businessAlias: alias,
      }));
    }
  }, [alias]);

  const handleGetOTP = async (email: string) => {
    try {
      const res = await axios.get<GetAdvisorLoginResponse>(
        `/advisor/login/${email}`
      );
      cookies.set(aliasMeta, email);
      if (res.data.success) {
        history.push(`/otp-login?redirect=/dashboard`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSuccessBusinessData) {
      setRun(true);
    }
  }, [isSuccessBusinessData]);

  useEffect(() => {
    if (isFetchingItems === true) {
      refetchTweet();
      refetchServiceRequest();
      cancelFetchingItems();
    }
  }, [
    cancelFetchingItems,
    isFetchingItems,
    refetchServiceRequest,
    refetchTweet,
  ]);

  return (
    <>
      <Joyride
        tooltipComponent={TooltipJoyride}
        steps={filteredSteps ?? []}
        callback={handleJoyrideCallback}
        run={run}
        continuous
        showProgress
        stepIndex={stepIndex}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            beaconSize: 36,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: defaultColor,
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
            textColor: "#333",
            zIndex: 100,
          },
        }}
      />
      <Box>
        {isLoadingBusinessData ? (
          <Stack
            spacing={1}
            height="100vh"
            alignItems="center"
            justifyContent="center"
          >
            <TraceSpinner
              frontColor={secondaryColor}
              backColor="#4b4c56"
              loading={true}
            />
            <Typography>Loading...</Typography>
          </Stack>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              lg={4.5}
              height="100vh"
              position="relative"
              sx={{ overflowY: "auto" }}
            >
              <Stack
                spacing={4}
                width="100%"
                alignItems="center"
                height="100vh"
                borderRight="1px solid rgba(0,0,0,0.2)"
                paddingY={6}
              >
                <Stack>
                  <img
                    src={logo as string}
                    alt="banner"
                    style={{
                      backgroundSize: "cover",
                      color: "#fff",
                      height: "120px",
                      zIndex: 1,
                      padding: 0,
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {/* <Typography variant="h6" fontWeight={700} my={1}>
                  {businessData?.legal_name}
                </Typography> */}
                </Stack>
                <Stack
                  id="advisorsList"
                  width={isMobileScreen ? "90%" : "70%"}
                  height="50vh"
                  sx={{
                    overflowY: "auto",
                    border: "1px solid rgba(0,0,0,0.12)",
                    borderRadius: 1,
                  }}
                >
                  {businessData?.advisors.map((li) => (
                    <ListItemButton
                      key={li.id}
                      component="a"
                      onClick={() => handleGetOTP(li.email)}
                      sx={{
                        borderRadius: "4px 4px 0 0",
                        padding: 2,
                        maxHeight: 72,
                        borderBottom: "1px solid rgba(0,0,0,0.12)",
                      }}
                    >
                      <Stack direction="row" spacing={3} alignItems="center">
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                            width: 50,
                            height: 50,
                          }}
                          alt={li.name}
                          src={li.avatar_photo}
                        />
                        <Stack>
                          <Typography
                            fontWeight={700}
                            component="div"
                            textOverflow="ellipsis"
                          >
                            {li.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="#a1a1a1"
                            textOverflow="ellipsis"
                          >
                            {maskEmail(li.email)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </ListItemButton>
                  ))}
                </Stack>
              </Stack>
              <Stack
                display={isMobileScreen ? undefined : "none"}
                position="absolute"
                bottom={8}
                right={0}
                left={0}
                spacing={1}
                alignItems="center"
                justifyContent="center"
                color="GrayText"
              >
                <Typography fontSize={12} textAlign="center" color="GrayText">
                  Scroll down to add notes or request service
                </Typography>
                <KeyboardDoubleArrowDownIcon color="inherit" />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={7.5}
              height={isMobileScreen ? undefined : "100vh"}
              sx={{ overflowY: "auto" }}
            >
              <Container maxWidth="md">
                <Stack
                  paddingY={isMobileScreen ? 2.5 : 5.5}
                  paddingX={isMobileScreen ? undefined : 3}
                >
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    textColor="secondary"
                    indicatorColor="secondary"
                    centered
                    variant="fullWidth"
                  >
                    <Tab
                      icon={<SpeakerNotesIcon />}
                      iconPosition="start"
                      label={
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography fontWeight={700}>Quick Notes</Typography>
                          {tweet && tweet?.tweet_count > 0 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "auto",
                                height: "24px",
                                borderRadius: "50px",
                                padding: 1,
                                backgroundColor: secondaryColor,
                                color: "white",
                                fontWeight: 700,
                              }}
                            >
                              <Box fontSize={14}>{tweet?.tweet_count}</Box>
                            </Box>
                          )}
                        </Stack>
                      }
                      sx={{ minHeight: "48px" }}
                    />
                    <Tab
                      id="serviceRequestTab"
                      icon={<RoomServiceIcon />}
                      iconPosition="start"
                      label={
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography fontWeight={700}>
                            Service Request
                          </Typography>
                          {serviceRequest &&
                            serviceRequest.service_request_count > 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "auto",
                                  height: "24px",
                                  borderRadius: "50px",
                                  padding: 1,
                                  backgroundColor: secondaryColor,
                                  color: "white",
                                  fontWeight: 700,
                                }}
                              >
                                <Box fontSize={14}>
                                  {serviceRequest?.service_request_count}
                                </Box>
                              </Box>
                            )}
                        </Stack>
                      }
                      sx={{ minHeight: "48px" }}
                    />
                  </Tabs>
                </Stack>
                <TabPanel value={tab} index={0}>
                  <TweetTimeline id={String(businessData?.id)} alias={alias} />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <ServiceRequestTimeline
                    id={String(businessData?.id)}
                    alias={alias}
                  />
                </TabPanel>
              </Container>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default LicenseeFoundPagev2;
