import React from "react";
import Box from "@mui/material/Box";

interface ICardCustomProps {
  children: React.ReactNode;
  noPadding?: boolean;
  hFull?: boolean;
}

const CardCustom = ({ children, noPadding, hFull }: ICardCustomProps) => {
  return (
    <Box
      p={noPadding ? 0 : 4}
      border="1px solid #B7B7B7"
      borderRadius={1}
      bgcolor="#F7F6F1"
      height={hFull ? "100%" : undefined}
    >
      {children}
    </Box>
  );
};

export default CardCustom;
