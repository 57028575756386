export const font = "'Gotham', sans-serif";
export const defaultColor = "#28695C";
export const secondaryColor = "#CC7C44";
export const titleMeta = "site_title";
export const faviconMeta = "site_favicon_url";
export const logoMeta = "site_logo_url";
export const bannerMeta = "site_banner_url";
export const backgroundMeta = "site_background_url";
export const licenseeAlias = "business_alias";
export const emailSenderId = "email_sender_id";
export const emailHeaderBanner = "email_header_banner";
export const emailFooterBanner = "email_footer_banner";
export const featureFlagWelcomeMessage = "feature_flag_welcome_message";
export const featureFlagAnnouncement = "feature_flag_announcement";
export const advisorId = "advisor_id";
export const aliasMeta = "alias";
export const otpSecuredDocumentMeta = "otp-verification";
export const workbookEditionMeta = "workbook_edition";
export const DRAWER_WIDTH = 420;
export const DRAWER_WIDTH_MINI = 320;
export const welcomePageTour = "welcome_page_tour";
export const memberPageTour = "member_page_tour";
export const advisorEmailMeta = "advisor_email";
export const memberEmailMeta = "member_email";
export const advisorMaskedEmailMeta = "advisor_masked_email";
export const memberMaskedEmailMeta = "member_masked_email";
export const advisorMaskedNameMeta = "advisor_masked_name";
export const memberMaskedNameMeta = "member_masked_name";
