import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetMemberResponse, ParamsGetMember } from "../../constants/types";

const handleRequest = async (params: ParamsGetMember) => {
  const urlApi = qs.stringifyUrl({
    url: `advisor/${params.advisorId}/member-list`,
    query: {
      view: params.view,
    },
  });
  const { data } = await axios.get<GetMemberResponse[]>(urlApi);
  return data;
};

export default function useMember(params: ParamsGetMember) {
  return useQuery<GetMemberResponse[]>(
    ["member", params],
    () => handleRequest(params),
    {
      enabled: !!params.advisorId,
    }
  );
}
