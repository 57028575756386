import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetSuggestedServicesResponse } from "../../constants/types";

const handleRequest = async (advisorID?: string) => {
  const apiUrl = qs.stringifyUrl({
    url: "/suggested-associate-attributes",
    query: {
      advisor_id: advisorID,
    },
  });
  const { data } = await axios.get<GetSuggestedServicesResponse>(apiUrl);
  return data;
};

export default function useSuggestedAssociateTags(advisorID?: string) {
  return useQuery<GetSuggestedServicesResponse>(
    ["suggestedAssociateTags", advisorID],
    () => handleRequest(advisorID)
  );
}
