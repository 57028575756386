import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BuildingSuper from "../../assets/images/buildingsuper.png";
import CentreLinkBenefits from "../../assets/images/centrelinkbenefits.png";
import EstatePlanning from "../../assets/images/estateplanning.png";
import HouseholdBudgeting from "../../assets/images/householdbudgeting.png";
import InsuranceProtection from "../../assets/images/insuranceprotection.png";
import PreparingForRetirement from "../../assets/images/preparingforretirement.png";
import SavingInvestments from "../../assets/images/savingsinvestments.png";
import SelfManagedSuperfunds from "../../assets/images/selfmanagedsuperfunds.png";
import ARInput from "../../components/ARInput/ARInput";
import { AreaType } from "../../constants/types";
import { Autocomplete, LoadingButton } from "@mui/lab";
import useSuburbs from "../../services/queries/useSuburb";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";

interface IRequestAssistance {
  title: string;
  name: string;
  phone: string;
  email: string;
  suburb: string;
}

const schema = yup.object({
  title: yup.string().required("Title is required"),
  name: yup
    .string()
    .required(
      "Name is required, and may contain letters, apostrophes and hyphens"
    ),
  phone: yup.string().required("Phone is required"),
  email: yup.string().email().required("Email is required"),
  suburb: yup.string().required("Suburb is required"),
});

const areas: AreaType[] = [
  { id: 1, src: BuildingSuper, name: "Superannuation" },
  { id: 2, src: PreparingForRetirement, name: "Retirement Planning" },
  { id: 3, src: CentreLinkBenefits, name: "Centrelink" },
  { id: 4, src: SelfManagedSuperfunds, name: "Self Managed Super Funds" },
  { id: 5, src: HouseholdBudgeting, name: "Budget Planning" },
  { id: 6, src: SavingInvestments, name: "Wealth Creation" },
  { id: 7, src: InsuranceProtection, name: "Personal Insurance" },
  { id: 8, src: EstatePlanning, name: "Estate Planning" },
];

const RequestAssistancePage = () => {
  const [selectedAreas, setSelectedAreas] = useState<AreaType[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { data: suburbs } = useSuburbs();

  const uniqueSuburbs = [...new Set(suburbs)];

  const handleClickArea = (data: AreaType) => {
    if (selectedAreas.findIndex((c) => c.id === data.id) >= 0) {
      setSelectedAreas(
        selectedAreas.filter((li) => {
          return li.id !== data.id;
        })
      );
    } else {
      setSelectedAreas((prev) => [...prev, data]);
    }
  };

  const initialValues = React.useMemo(
    () => ({
      title: "",
      name: "",
      phone: "",
      email: "",
      suburb: "",
    }),
    []
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IRequestAssistance>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IRequestAssistance) => {
    setIsButtonLoading(true);
    try {
      const body = {
        title: values.title,
        name: values.name,
        contact: {
          phone_number: values.phone,
          email: values.email,
          address: {
            suburb: values.suburb,
          },
        },
        category: selectedAreas.map((li) => li.name),
        avatar_photo: "",
        campaign_id: "2023001",
      };
      const res = await axios.post(`/person`, body);
      if (res.status === 200) {
        setIsButtonLoading(false);
        reset(initialValues);
        setSelectedAreas([]);
        Swal.fire({
          title:
            "Thank you for your request, our Advisor Team will reach out to you shortly.",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
      }
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  return (
    <Box bgcolor="#E9E6EC">
      <Container maxWidth="lg">
        <Stack spacing={3} py={3}>
          <Typography variant="h5">
            Complete this form to request assistance from the AustralianSuper
            team with your financial advice needs.
          </Typography>
          <Card>
            <CardContent sx={{ py: 6, px: 0 }}>
              <Grid container px={3} pb={3} spacing={3}>
                <Grid item xs={12} md={3} gap={2}>
                  <Typography variant="h6">Your details</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={7}>
                  <Grid container gap={1}>
                    <Grid item xs={12} md={2.8}>
                      <Box>
                        <Typography variant="body2">Title</Typography>
                        <Controller
                          name="title"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={Boolean(errors.title)}
                            >
                              <Select
                                margin="dense"
                                onChange={onChange}
                                value={value}
                                displayEmpty
                                size="small"
                                error={Boolean(errors.title)}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Master">Master</MenuItem>
                                <MenuItem value="Dr">Dr</MenuItem>
                              </Select>
                              <FormHelperText>
                                {errors.title ? errors.title.message : " "}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box>
                        <Typography variant="body2">Full Name</Typography>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={Boolean(errors.name)}
                            >
                              <OutlinedInput
                                id="name"
                                // type="name"
                                size="small"
                                {...field}
                              />
                              <FormHelperText>
                                {errors.name ? errors.name.message : " "}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2">Phone</Typography>
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={Boolean(errors.phone)}
                            >
                              <OutlinedInput
                                id="phone"
                                // type="phone"
                                size="small"
                                type="number"
                                {...field}
                              />
                              <FormHelperText>
                                {errors.phone ? errors.phone.message : " "}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2">Email</Typography>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={Boolean(errors.email)}
                            >
                              <OutlinedInput
                                id="email"
                                size="small"
                                type="email"
                                {...field}
                              />
                              <FormHelperText>
                                {errors.email ? errors.email.message : " "}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2">Suburb</Typography>
                        <Controller
                          name="suburb"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              disablePortal
                              // loading={isLoadingKategori}
                              disableClearable
                              options={uniqueSuburbs ?? []}
                              getOptionLabel={(option) => option}
                              isOptionEqualToValue={(option, value) =>
                                String(option) === String(value)
                              }
                              size="small"
                              value={field.value}
                              onChange={(_event, suburb: string | null) => {
                                field.onChange(suburb);
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="-- Choose Suburb --"
                                  error={Boolean(errors.suburb)}
                                  helperText={
                                    errors.suburb && "Please choose one"
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Stack px={3} pt={3} pb={6} spacing={3}>
                <Typography variant="h6">
                  What areas would you like advice about?
                </Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
                  gap={2}
                >
                  {areas.map((li) => (
                    <ARInput
                      key={li.id.toString()}
                      data={li}
                      onClick={() => handleClickArea(li)}
                      selectedAreas={selectedAreas}
                    />
                  ))}
                </Box>
              </Stack>
              {/* <Divider />
              <Grid container px={3} pt={3} pb={6} spacing={3}>
                <Grid item xs={12} md={3} gap={2}>
                  <Typography variant="h6">Investment details</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={7}>
                  <Stack spacing={1}>
                    <Typography>Are you an AustralianSuper member?</Typography>
                    <ToggleButtonCustom
                      value={toggleButtonMember}
                      handleValue={handleToggleButtonMember}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
              <Grid container p={3} spacing={3} alignItems="center">
                <Grid item xs={12} md={3} gap={2}>
                  <Typography variant="h6">Notes</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={7}>
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Typography>Do you have any special needs?</Typography>
                        <Typography variant="body2" color="#6c757d">
                          (e.g. hearing impaired)
                        </Typography>
                      </Stack>
                      <ToggleButtonCustom
                        value={toggleButtonNeeds}
                        handleValue={handleToggleButtonNeeds}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Collapse in={toggleButtonNeeds === SelectedButton.YES}>
                        <Typography>
                          Tell us more about your special needs
                        </Typography>
                        <OutlinedInput
                          fullWidth
                          id="specialNeedsDetail"
                          size="small"
                          multiline
                          rows={4}
                          value={specialNeedsDetail}
                          onChange={(e) => handleSpecialNeedsDetail(e)}
                        />
                      </Collapse>
                    </Stack>
                    <Stack spacing={1}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Typography>
                          Please include a brief summary of what help you're
                          looking for
                        </Typography>
                        <Typography variant="body2" color="#6c757d">
                          (optional)
                        </Typography>
                      </Stack>
                      <OutlinedInput
                        fullWidth
                        id="helpSummary"
                        size="small"
                        multiline
                        rows={4}
                        value={helpSummary}
                        onChange={(e) => handleHelpSummary(e)}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="body2" color="#6c757d">
                        AustralianSuper collects your personal information (PI)
                        to help you find an adviser. If we can't collect your PI
                        we may not be able to provide this service. We will only
                        share your PI where necessary so you can be contacted by
                        the selected adviser.
                      </Typography>
                      <Typography variant="body2" color="#6c757d">
                        For complete details on how we may use your information,
                        please see our <a href="#">Privacy Policy</a>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid> */}
            </CardContent>
            <Divider />
            <CardActions
              sx={{
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                size="large"
                sx={{ borderRadius: 50, textTransform: "none" }}
              >
                Cancel
              </Button>
              <LoadingButton
                loadingPosition="center"
                loading={isButtonLoading}
                disableElevation
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
                sx={{ borderRadius: 50, textTransform: "none" }}
              >
                Submit
              </LoadingButton>
            </CardActions>
          </Card>
        </Stack>
      </Container>
    </Box>
  );
};

export default RequestAssistancePage;
