import React, { Component } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Box from "@mui/material/Box";

interface Props {
  handleContent: (content: string) => void;
  html: string;
}

interface State {
  editorState: EditorState;
}

// const ControlledEditor = ({ handleContent, html }: Props) => {
//   const contentBlock = htmlToDraft(html);
// //   const [editorState, setEditorState] = useState<EditorState>();

// //   if (contentBlock) {
// //     const contentState = ContentState.createFromBlockArray(
// //       contentBlock.contentBlocks
// //     );
// //     const newEditorState = EditorState.createWithContent(contentState);
// //     setEditorState(newEditorState);
// //   }

// //   const onEditorStateChange = (editorState: EditorState) => {
// //     // console.log(editorState)
// //     setEditorState(editorState);
// //     handleContent(
// //       convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
// //         convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
// //         ? ""
// //         : draftToHtml(convertToRaw(editorState.getCurrentContent()))
// //     );
// //   };

//   return (
//     <div className="editor">
//       {/* <button onClick={this.toggleEditMode}>Toggle Edit Mode</button> */}
//       <>
//         <Box border="1px solid rgba(0,0,0,0.23)" padding={2} borderRadius="4px">
//           <Editor
//             // editorState={editorState}
//             toolbarClassName="toolbar-class"
//             wrapperClassName="wrapper-class"
//             editorClassName="editor-class"
//             // onEditorStateChange={onEditorStateChange}
//             toolbar={{
//               options: [
//                 // "inline",
//                 // "blockType",
//                 // "fontSize",
//                 // "fontFamily",
//                 // "list",
//                 // "textAlign",
//                 // "colorPicker",
//                 // "link",
//                 // "embedded",
//                 // "emoji",
//                 // "image",
//                 "history",
//               ],
//               // inline: { inDropdown: true },
//               // list: { inDropdown: true },
//               // textAlign: { inDropdown: true },
//               // link: { inDropdown: true },
//               // history: { inDropdown: true },
//               // image: {
//               //     urlEnabled: true,
//               //     uploadEnabled: true,
//               //     // uploadCallback: this.uploadImageCallBack,
//               //     previewImage: true,
//               //     alt: { present: false, mandatory: false },
//               // },
//             }}
//           />
//         </Box>
//         {/* <textarea
//                         disabled
//                         value={draftToHtml(
//                             convertToRaw(editorState.getCurrentContent()),
//                         )}
//                     /> */}
//       </>
//     </div>
//   );
// };

class ControlledEditor extends Component<Props, State> {
  // private editorRef: React.RefObject<Editor>;
  constructor(props: Props) {
    super(props);
    const contentBlock = htmlToDraft(this.props.html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    } else {
      this.state = {
        editorState: EditorState.createWithContent(
          ContentState.createFromText("Financials")
        ),
      };
    }
    // this.editorRef = React.createRef<Editor>();
  }

  //   componentWillMount() {
  //     this.onEditorStateChange = this.onEditorStateChange.bind(this);
  //   }

  // focus = () => {
  //     let editor = this.editorRef.current;
  //     if (editor) {
  //         editor.focusEditor();
  //     }
  // };

  // componentDidMount = () => {
  //     console.log("edit Me");
  //     if (this.state.editMode) {
  //         this.focus();
  //     }
  // };

  // toggleEditMode = () => {
  //     this.setState({ editMode: !this.state.editMode });
  // };

  onEditorStateChange = (editorState: EditorState) => {
    // console.log(editorState)
    this.setState({
      editorState,
    });
    this.props.handleContent(
      convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
        convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
        ? ""
        : draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="editor">
        {/* <button onClick={this.toggleEditMode}>Toggle Edit Mode</button> */}
        <>
          <Box
            border="1px solid rgba(0,0,0,0.23)"
            padding={2}
            borderRadius="4px"
            bgcolor="#fff"
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbar-class"
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: [
                  // "inline",
                  // "blockType",
                  // "fontSize",
                  // "fontFamily",
                  // "list",
                  // "textAlign",
                  // "colorPicker",
                  // "link",
                  // "embedded",
                  // "emoji",
                  // "image",
                  "history",
                ],
                // inline: { inDropdown: true },
                // list: { inDropdown: true },
                // textAlign: { inDropdown: true },
                // link: { inDropdown: true },
                // history: { inDropdown: true },
                // image: {
                //     urlEnabled: true,
                //     uploadEnabled: true,
                //     // uploadCallback: this.uploadImageCallBack,
                //     previewImage: true,
                //     alt: { present: false, mandatory: false },
                // },
              }}
            />
          </Box>
          {/* <textarea
                        disabled
                        value={draftToHtml(
                            convertToRaw(editorState.getCurrentContent()),
                        )}
                    /> */}
        </>
      </div>
    );
  }
}

export default ControlledEditor;
