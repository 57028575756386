import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import ModalTitle from "../ModalTitle/ModalTitle";
import Stack from "@mui/material/Stack";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import ControlledEditor from "../ControlledEditor/ControlledEditor";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid2 from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface IModalSendEmailUploadDocumentProps {
  advisorID: string;
  documentID: string;
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  sendmail: boolean;
  email_from: string;
  email_to: string;
  email_reply_to: string;
  email_subject: string;
  member_document_vault: string;
  html: string;
  handleEditorContent: (content: string) => void;
  openModalAreYouSure: () => void;
  closeModalAreYouSure: () => void;
}

interface IValues {
  email_from: string;
  email_to: string;
  email_reply_to: string;
  email_subject: string;
}

const ModalSendEmailUploadDocument = ({
  advisorID,
  documentID,
  fullScreen,
  open,
  onClose,
  sendmail,
  email_from,
  email_to,
  email_reply_to,
  email_subject,
  member_document_vault,
  html,
  handleEditorContent,
  openModalAreYouSure,
  closeModalAreYouSure,
}: IModalSendEmailUploadDocumentProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      sendmail: true,
      email_from: "",
      email_to: "",
      email_reply_to: "",
      email_subject: "",
    }),
    []
  );

  const handleSendLater = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/${member_document_vault}`
    );
    Swal.fire({
      title: `Copied to clipboard`,
      position: "top-end",
      showConfirmButton: false,
      icon: "success",
      toast: true,
      timer: 3000,
      timerProgressBar: true,
      showCloseButton: true,
      customClass: {
        container: "my-swal",
      },
    });
    onClose();
    closeModalAreYouSure();
  };

  const schema = yup.object({
    email_from: yup.string().required("From is required"),
    email_to: yup.string().required("To is required"),
    email_reply_to: yup.string().required("To is required"),
    email_subject: yup.string().required("Subject is required"),
  });

  const { handleSubmit, control, reset } = useForm<IValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IValues) => {
    console.log(values);
    setIsButtonLoading(true);

    try {
      const body = {
        document_id: documentID,
        sendmail: true,
        email_from: values.email_from,
        email_to: values.email_to,
        email_reply_to: values.email_reply_to,
        email_subject: values.email_subject,
        email_content: html,
      };
      console.log("Body", body);
      const res = await axios.post(`/advisor/${advisorID}/sendmail`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: `Email sent`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        onClose();
        closeModalAreYouSure();
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      reset({
        email_from: email_from,
        email_to: email_to,
        email_reply_to: email_reply_to,
        email_subject: email_subject,
      });
    }
  }, [email_from, email_reply_to, email_subject, email_to, open, reset]);

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={openModalAreYouSure}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={openModalAreYouSure}>
        <Stack direction="row" alignItems="center" gap={2}>
          <EmailIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Send email
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        <Grid2 container>
          <Grid2 xs={12} md={2}>
            <Typography>From</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Controller
              name="email_from"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="From"
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  {...field}
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Grid2 container mt={2}>
          <Grid2 xs={12} md={2}>
            <Typography>To</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Controller
              name="email_to"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="From"
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  {...field}
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Grid2 container mt={2}>
          <Grid2 xs={12} md={2}>
            <Typography>Subject</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <Controller
              name="email_subject"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="From"
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : " "}
                  {...field}
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Grid2 container mt={2}>
          <Grid2 xs={12} md={2}>
            <Typography>Message</Typography>
          </Grid2>
          <Grid2 xs={12} md={10}>
            <ControlledEditor handleContent={handleEditorContent} html={html} />
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          startIcon={<ContentCopyIcon />}
          disableElevation
          variant="contained"
          color="buttonlightgreen"
          onClick={handleSendLater}
        >
          SEND IT LATER, COPY SECURED DOCUMENT LINK
        </Button>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          disableElevation
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="buttongreen"
          sx={{
            // borderRadius: 50,
            fontWeight: 700,
          }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSendEmailUploadDocument;
