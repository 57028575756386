import React, { useState } from "react";
import { useFetching } from "../../context/fetchingContext";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

interface IModalDeleteDocumentProps {
  fullScreen: boolean;
  isOpenModalDeleteDocument: boolean;
  closeModalDeleteDocument: () => void;
  documentId: string;
}

const ModalDeleteDocument = ({
  fullScreen,
  isOpenModalDeleteDocument,
  closeModalDeleteDocument,
  documentId,
}: IModalDeleteDocumentProps) => {
  const { setFetchingItems } = useFetching();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onSubmit = async () => {
    setIsButtonLoading(true);

    try {
      const response = await axios.patch(`document/${documentId}`, {
        status: "DELETE",
      });
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: `Successfully deleted document`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        setFetchingItems();
        closeModalDeleteDocument();
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={fullScreen}
      fullWidth={true}
      open={isOpenModalDeleteDocument}
      onClose={() => {
        closeModalDeleteDocument();
      }}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton
            disabled
            onClick={() => {
              closeModalDeleteDocument();
            }}
          >
            <Close htmlColor="white" />
          </IconButton>
          <Typography variant="h5" fontWeight="bold">
            Delete Document
          </Typography>
          <IconButton
            onClick={() => {
              closeModalDeleteDocument();
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {documentId} will be deleted. Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <LoadingButton
          loading={isButtonLoading}
          loadingPosition="center"
          variant="outlined"
          onClick={onSubmit}
          color="error"
        >
          Yes
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => {
            closeModalDeleteDocument();
          }}
          color="error"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDeleteDocument;
