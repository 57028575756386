import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import useGoalDetail from "../../services/queries/useGoalDetail";
import { titleMeta, faviconMeta, advisorId } from "../../constants/common";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { ConfigItemEdit } from "../../constants/types";
import FormEdit from "../../components/FormEdit/FormEdit";
import Box from "@mui/material/Box";
import Check from "@mui/icons-material/Check";
import { ListItemButton, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CardCustom from "../../components/CardCustom/CardCustom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Grid2 from "@mui/material/Unstable_Grid2";

const GoalDetailPage = () => {
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const cookies = useMemo(() => new Cookies(), []);
  const [advisorID, setAdvisorID] = useState("");
  const [paramsGoalDetail, setParamsGoalDetail] = useState<string | null>(id);
  const {
    data: goalDetail,
    refetch: refetchMemberDetail,
    // isLoading: isLoadingGoalDetail,
  } = useGoalDetail(paramsGoalDetail);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = useMemo(() => {
    if (goalDetail) {
      const object = goalDetail?.fields.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.value }),
        {}
      );
      return {
        title: goalDetail?.title,
        summary: goalDetail?.summary,
        ...object,
      };
    }
  }, [goalDetail]);

  useEffect(() => console.log("initialValues", initialValues), [initialValues]);

  const CONFIG: ConfigItemEdit[] | undefined = goalDetail?.fields?.map(
    (li) => ({
      id: li.id,
      value: li.value,
    })
  );

  const formMethods = useForm();

  useEffect(() => {
    if (initialValues) {
      formMethods.reset(initialValues);
    }
  }, [formMethods, initialValues]);

  const { title, summary, ...rest } = formMethods.watch();

  let restObject = Object.keys(rest);
  let restArray: { value: string; id: string }[] = [];

  restObject.forEach((obj) => {
    restArray.push({ value: rest[obj], id: obj });
  });

  const onSubmit: SubmitHandler<any> = async (value) => {
    setIsButtonLoading(true);
    const { title, summary, ...rest } = value;
    let restObject = Object.keys(rest);
    let restArray: { value: string; id: string }[] = [];

    restObject.forEach((obj) => {
      restArray.push({ value: rest[obj], id: obj });
    });

    const body = {
      title: title,
      summary: summary,
      fields: restArray,
    };
    console.log("body", body);
    try {
      const res = await axios.patch(`/goal/${goalDetail?.id}`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "Goal updated",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        history.push(`/member-detail?id=${goalDetail?.member_id}`);
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  const handleUpdateTask = async (taskId: string, taskName: string) => {
    try {
      const res = await axios.patch(`/task/${taskId}/status/done`);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: `Task ${taskName} marked as done`,
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        refetchMemberDetail();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateDocument = async (
    documentId: string,
    documentName: string
  ) => {
    // try {
    //   const res = await axios.patch(`/document/${documentId}/status/done`);
    //   if (res.status === 200 || res.status === 201) {
    //     Swal.fire({
    //       title: `Document ${documentName} marked as done`,
    //       position: "top-end",
    //       showConfirmButton: false,
    //       icon: "success",
    //       toast: true,
    //       timer: 3000,
    //       timerProgressBar: true,
    //       showCloseButton: true,
    //       customClass: {
    //         container: "my-swal",
    //       },
    //     });
    //     refetchMemberDetail();
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    console.log(documentId);
    refetchMemberDetail();
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const getAdvisorId = await cookies.get(advisorId);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (getAdvisorId) {
        setAdvisorID(getAdvisorId);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  useEffect(() => {
    if (id) {
      setParamsGoalDetail(id);
    }
  }, [id]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: "100%",
    height: 20,
    borderRadius: 200,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 200,
    },
  }));

  return (
    <Container>
      <Stack padding={2} spacing={3} mb={6}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href="/member-list"
          >
            Members
          </Link>
          <Link
            variant="body2"
            fontSize={12}
            underline="hover"
            color="inherit"
            href={`/member-detail?id=${goalDetail?.member_id}`}
          >
            Member detail
          </Link>
          <Typography
            mt={0.5}
            variant="body2"
            fontSize={12}
            color="text.primary"
          >
            {goalDetail?.title}
          </Typography>
        </Breadcrumbs>
        <CardCustom>
          <Typography variant="h6" fontWeight={500} mb={2}>
            Goal Review
          </Typography>
          <Grid2 container>
            <Grid2 xs={12} md={2}>
              <Typography>Title</Typography>
            </Grid2>
            <Grid2 xs={12} md={10}>
              <Controller
                name="title"
                control={formMethods.control}
                rules={{
                  required: "Title field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={""}
                    required={true}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : " "
                    }
                    {...field}
                  />
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 xs={12} md={2}>
              <Typography>Summary</Typography>
            </Grid2>
            <Grid2 xs={12} md={10}>
              <Controller
                name="summary"
                control={formMethods.control}
                rules={{
                  required: "Summary field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={""}
                    required={true}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : " "
                    }
                    {...field}
                  />
                )}
              />
            </Grid2>
          </Grid2>
          <FormEdit onSubmit={onSubmit} formMethods={formMethods}>
            {CONFIG &&
              CONFIG.map((configItem, index) => {
                return React.createElement(FormEdit.Input, {
                  ...configItem,
                  key: index,
                });
              })}
          </FormEdit>
          <Grid2 container>
            <Grid2 xs={12} md={2}>
              <Typography>Your Tasks</Typography>
            </Grid2>
            <Grid2 xs={12} md={10}>
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                width="70%"
                minWidth={250}
              >
                <BorderLinearProgress
                  variant="determinate"
                  value={Number(goalDetail?.task_completion_percentage)}
                  color={
                    Number(goalDetail?.task_completion_percentage) <= 40
                      ? "barunder40"
                      : Number(goalDetail?.task_completion_percentage) >= 70
                      ? "barover70"
                      : "bar4070"
                  }
                />
                <Typography
                  fontWeight={700}
                  color={
                    Number(goalDetail?.task_completion_percentage) <= 40
                      ? "#c24b47"
                      : Number(goalDetail?.task_completion_percentage) >= 70
                      ? "#91c467"
                      : "#389bbb"
                  }
                >
                  {goalDetail?.task_completion_percentage}%
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
                mt={1.5}
              >
                {goalDetail &&
                  goalDetail.tasks.map((li) => (
                    <Stack
                      key={li.id}
                      direction="row"
                      alignItems="center"
                      gap={1}
                      border="1px solid rgba(0, 0, 0, 0.2)"
                      borderRadius={4}
                      paddingX={1.5}
                      paddingY={0.5}
                      position="relative"
                      sx={{
                        backgroundColor:
                          li.status === "DONE" ? "#91c467" : "#fff",
                        transition: "all 0.2s ease",
                      }}
                    >
                      {li.status === "NEW" && (
                        <Stack
                          component={ListItemButton}
                          onClick={() => {
                            if (li.status !== "NEW") return;
                            handleUpdateTask(li.id, li.name);
                          }}
                          border="1px solid rgba(0, 0, 0, 0.2)"
                          borderRadius={4}
                          paddingX={1.5}
                          paddingY={0.5}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          position="absolute"
                          top={0}
                          left={0}
                          width="100%"
                          sx={{
                            backgroundColor: "transparent",
                            color: "transparent",
                            transition: "all 0.2s ease",
                            "&:hover": {
                              backgroundColor: "#91c467",
                              color: "white",
                            },
                          }}
                        >
                          <Check htmlColor="inherit" fontSize="small" />
                        </Stack>
                      )}
                      <Typography
                        variant="caption"
                        color={li.status === "DONE" ? "white" : undefined}
                      >
                        {li.name}
                      </Typography>
                    </Stack>
                  ))}
              </Stack>
            </Grid2>
          </Grid2>
          <Stack spacing={1} mt={3}>
            <Typography>Requesting for the following documents</Typography>
            <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
              {goalDetail &&
                goalDetail.documents.map((li) => (
                  <Stack
                    key={li.id}
                    direction="row"
                    alignItems="center"
                    gap={1}
                    border="1px solid rgba(0, 0, 0, 0.2)"
                    borderRadius={4}
                    paddingX={1.5}
                    paddingY={0.5}
                    position="relative"
                    sx={{
                      backgroundColor:
                        li.status === "DONE" ? "#91c467" : "#fff",
                      transition: "all 0.2s ease",
                    }}
                  >
                    {li.status === "NEW" && (
                      <Stack
                        component={ListItemButton}
                        onClick={() => {
                          if (li.status !== "NEW") return;
                          handleUpdateDocument(li.id, li.name);
                        }}
                        border="1px solid rgba(0, 0, 0, 0.2)"
                        borderRadius={4}
                        paddingX={1.5}
                        paddingY={0.5}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        sx={{
                          backgroundColor: "transparent",
                          color: "transparent",
                          transition: "all 0.2s ease",
                          "&:hover": {
                            backgroundColor: "#91c467",
                            color: "white",
                          },
                        }}
                      >
                        <Check htmlColor="inherit" fontSize="small" />
                      </Stack>
                    )}
                    <Typography
                      variant="caption"
                      color={li.status === "DONE" ? "white" : undefined}
                    >
                      {li.name}
                    </Typography>
                  </Stack>
                ))}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" mt={3}>
            <LoadingButton
              loading={isButtonLoading}
              loadingPosition="center"
              variant="contained"
              onClick={formMethods.handleSubmit(onSubmit)}
              color="buttongreen"
              sx={{
                textTransform: "none",
              }}
            >
              SAVE
            </LoadingButton>
          </Stack>
        </CardCustom>
        <GoalNotes id={id} />
      </Stack>
    </Container>
  );
};

interface IGoalNotesProps {
  id: string | null;
}

interface ITweetInputs {
  message: string;
}

const schema = yup.object({
  message: yup.string().required("Please complete this detail."),
});

const GoalNotes = ({ id }: IGoalNotesProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [paramsGoalDetail, setParamsGoalDetail] = useState<string | null>(id);
  const {
    data: goalDetail,
    refetch: refetchMemberDetail,
    // isLoading: isLoadingGoalDetail,
  } = useGoalDetail(paramsGoalDetail);

  useEffect(() => {
    if (id) {
      setParamsGoalDetail(id);
    }
  }, [id]);

  const initialValues = useMemo(
    () => ({
      message: "",
    }),
    []
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ITweetInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: ITweetInputs) => {
    setIsButtonLoading(true);
    try {
      const body = {
        message: values.message,
      };
      const res = await axios.post(`/goal/${id}/tweet`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "New notes added",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        reset(initialValues);
        refetchMemberDetail();
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  return (
    <CardCustom noPadding>
      <Stack padding={3} spacing={3}>
        <Typography variant="h6" fontWeight={500}>
          Goal notes
        </Typography>
        <Stack spacing={1}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                placeholder="Enter your notes, todo list, shout out, news, etc."
                multiline
                rows={4}
                error={Boolean(errors.message)}
                helperText={errors.message ? errors.message.message : ""}
                InputProps={{ sx: { backgroundColor: "#fff" } }}
                {...field}
              />
            )}
          />
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={isButtonLoading}
              loadingPosition="center"
              disableElevation
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              sx={{
                fontWeight: 700,
              }}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack>
          {goalDetail?.tweets && goalDetail?.tweets.length > 0 ? (
            <Box width="100%">
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  padding: 0,
                }}
              >
                {goalDetail?.tweets.map((li) => (
                  <TimelineItem key={li.id}>
                    <TimelineSeparator sx={{ alignItems: "flex-start" }}>
                      <TimelineDot
                        sx={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 500,
                          border: "none",
                          fontSize: "13px",
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                          color: "rgba(0, 0, 0, 0.87)",
                          boxShadow: "unset",
                        }}
                      >
                        {li.modified_on}
                      </TimelineDot>
                      <TimelineConnector sx={{ marginLeft: 3 }} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Stack spacing={2} mt={5} ml={-9}>
                        <TextField
                          variant="standard"
                          value={li.message}
                          multiline
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                        />
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          ) : (
            <CardCustom>
              <Typography>
                The world is your notepad. What will you jot down today?
              </Typography>
            </CardCustom>
          )}
        </Stack>
      </Stack>
    </CardCustom>
  );
};

export default GoalDetailPage;
