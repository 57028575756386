import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import axios from "../../services/axios";
import { GetAdvisorLoginResponse } from "../../constants/types";
import { titleMeta, faviconMeta, logoMeta } from "../../constants/common";
import { Cookies } from "react-cookie";

interface ILoginInputs {
  companyAlias: string;
}

enum SectionLogin {
  LICENSEE = "Licensee",
  ADVISOR = "Advisor",
}

const schema = yup
  .object({
    companyAlias: yup.string().required("Please complete this detail."),
  })
  .required();

const LoginPage = () => {
  const cookies = useMemo(() => new Cookies(), []);
  const [section, setSection] = useState<SectionLogin>(SectionLogin.LICENSEE);
  const [logo, setLogo] = useState("");

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  const initialValues = React.useMemo(
    () => ({
      companyAlias: "",
    }),
    []
  );

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ILoginInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: ILoginInputs) => {
    if (section === SectionLogin.LICENSEE) {
      try {
        const res = await axios.get(`/licencee/${values.companyAlias}`);
        if (res.status === 200) {
          if (res.data.id) {
            window.location.replace(`/welcome?alias=${values.companyAlias}`);
          } else {
            setError("companyAlias", {
              message: "No company found with that alias.",
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const res = await axios.get<GetAdvisorLoginResponse>(
          `/advisor/login/${values.companyAlias}`
        );
        if (res.data.success) {
          window.location.replace(`/otp-login?alias=${values.companyAlias}`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={3}
      alignItems="center"
      padding={3}
      sx={{ height: "100vh", backgroundColor: "rgb(230,230,230)" }}
    >
      <Stack spacing={3} width="100%" alignItems="center">
        <img
          src={logo as string}
          alt="banner"
          style={{
            backgroundSize: "cover",
            color: "#fff",
            height: "200px",
            zIndex: 1,
            padding: 0,
            width: "100%",
            objectFit: "contain",
          }}
        />
        <Box
          // padding={3}
          maxWidth="450px"
          width="100%"
          borderRadius="8px"
          sx={{ backgroundColor: "#FFF" }}
        >
          <Stack direction="row">
            <Button
              disableElevation
              fullWidth
              variant={
                section === SectionLogin.LICENSEE ? "contained" : "outlined"
              }
              onClick={() => setSection(SectionLogin.LICENSEE)}
              sx={{
                border:
                  section === SectionLogin.LICENSEE
                    ? "1px solid #260046"
                    : "1px solid #fff",
                borderRadius: "8px 0 0 0",
              }}
            >
              Licensee Login
            </Button>
            <Button
              disableElevation
              fullWidth
              variant={
                section === SectionLogin.ADVISOR ? "contained" : "outlined"
              }
              onClick={() => setSection(SectionLogin.ADVISOR)}
              sx={{
                border:
                  section === SectionLogin.ADVISOR
                    ? "1px solid #260046"
                    : "1px solid #fff",
                borderRadius: "0 8px 0 0",
              }}
            >
              Advisor Login
            </Button>
          </Stack>
          {section === SectionLogin.LICENSEE ? (
            <Stack spacing={3} padding={3} borderTop="1px solid #ededed">
              <form
                style={{
                  width: "100%",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography variant="h5" mb={3}>
                  Licensee login
                </Typography>
                <Controller
                  name="companyAlias"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Company Alias"
                      fullWidth
                      variant="outlined"
                      error={Boolean(errors.companyAlias)}
                      placeholder="e.g. Future Think"
                      helperText={
                        errors.companyAlias ? errors.companyAlias.message : ""
                      }
                      {...field}
                    />
                  )}
                />
                <Button
                  disableElevation
                  type="submit"
                  variant="contained"
                  sx={{
                    marginTop: 3,
                    borderRadius: 50,
                    fontWeight: 700,
                  }}
                >
                  Log In
                </Button>
              </form>
            </Stack>
          ) : (
            <Stack spacing={3} padding={3} borderTop="1px solid #ededed">
              <form
                style={{
                  width: "100%",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography variant="h5" mb={3}>
                  Advisor login
                </Typography>
                <Controller
                  name="companyAlias"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Advisor"
                      fullWidth
                      variant="outlined"
                      error={Boolean(errors.companyAlias)}
                      placeholder="e.g. newemployee@gmail.com"
                      helperText={
                        errors.companyAlias ? errors.companyAlias.message : ""
                      }
                      {...field}
                    />
                  )}
                />
                <Button
                  disableElevation
                  type="submit"
                  variant="contained"
                  sx={{
                    marginTop: 3,
                    borderRadius: 50,
                    fontWeight: 700,
                  }}
                >
                  Log In
                </Button>
              </form>
            </Stack>
          )}
        </Box>
      </Stack>
      <FooterLinks />
    </Box>
  );
};

export default LoginPage;
