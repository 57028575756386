import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import currencyFormat from "../../utils/currencyFormat";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IMemberAccountProps {
  title: string;
  frequency?: string;
  total?: number;
  memberAccount: { name: string; nominal: number }[];
  icon: string;
}

const MemberAccount = ({
  title,
  frequency,
  total,
  memberAccount,
  icon,
}: IMemberAccountProps) => {
  return (
    <Accordion
      elevation={0}
      sx={{
        background: "#F7F6F1",
        border: "1px solid #B7B7B7",

        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap={2}
          mr={2}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <img
              src={icon}
              alt="illust"
              style={{
                width: 24,
                height: 24,
              }}
            />
            <Typography variant="h6" fontSize={16} fontWeight={700}>
              {title}
            </Typography>
            {frequency ? (
              <Typography fontSize={14}>
                <i>({frequency})</i>
              </Typography>
            ) : null}
          </Stack>
          {total ? (
            <Typography fontSize={14}>${currencyFormat(total)}</Typography>
          ) : null}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 7 }}>
        <Stack spacing={1}>
          {memberAccount.map((li, idx) => (
            <MemberAccountDetail
              key={idx.toString()}
              name={li.name}
              nominal={li.nominal}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

interface IMemberAccountDetailsProps {
  name: string;
  nominal: number;
}

const MemberAccountDetail = ({ name, nominal }: IMemberAccountDetailsProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography>{name}</Typography>
      <Typography>${currencyFormat(nominal)}</Typography>
    </Stack>
  );
};

export default MemberAccount;
