import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetTweetResponse, ParamsGetBusinessData } from "../../constants/types";

const handleRequest = async (params: ParamsGetBusinessData) => {
  const urlApi = qs.stringifyUrl({
    url: `/business/${params.businessAlias}`,
    query: {
      filter: params.filter,
    },
  });
  const { data } = await axios.get<GetTweetResponse>(urlApi);
  return data;
};

export default function useTweet(params: ParamsGetBusinessData) {
  return useQuery<GetTweetResponse>(
    ["tweet", params],
    () => handleRequest(params),
    {
      enabled: !!params.businessAlias,
    }
  );
}
