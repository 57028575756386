import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import { TooltipRenderProps } from "react-joyride";

const TooltipJoyride = (data: TooltipRenderProps) => {
  const {
    continuous,
    index,
    step,
    size,
    backProps,
    primaryProps,
    tooltipProps,
    isLastStep,
  } = data;

  return (
    <Card {...tooltipProps}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {step.title}
        </Typography>
        <Typography variant="body2">{step.content}</Typography>
      </CardContent>
      <CardActions>
        {index > 0 && (
          <Button color="primary" variant="text" size="small" {...backProps}>
            Back
          </Button>
        )}
        {continuous && (
          <Button
            color="primary"
            variant="contained"
            size="small"
            {...primaryProps}
          >
            {isLastStep ? "Close" : "Next"}
            {size > 1 && ` (${index + 1}/${size})`}
          </Button>
        )}
        {!continuous && (
          <Button
            color="primary"
            variant="contained"
            size="small"
            {...primaryProps}
          >
            Close
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default TooltipJoyride;
