import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

interface IModalTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

const ModalTitle = ({ children, onClose }: IModalTitleProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DialogTitle>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {!isMobileScreen && (
          <IconButton disabled>
            <CloseIcon htmlColor="#F7F6F1" />
          </IconButton>
        )}
        {children}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
};

export default ModalTitle;
