import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import { useFetching } from "../../context/fetchingContext";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalTitle from "../ModalTitle/ModalTitle";
import AddIcon from "@mui/icons-material/Add";

interface IModalServiceRequestProps {
  id: string;
  fullScreen: boolean;
  isOpenModalServiceRequest: boolean;
  closeModalServiceRequest: () => void;
}

interface IServiceRequestInputs {
  message: string;
}

const schema = yup.object({
  message: yup.string().required("Please complete this detail."),
});

const categoryList = [
  { id: "face-angry", name: "Serious", color: "red" },
  { id: "exclamation", name: "Exclamation", color: "red" },
  { id: "coffee", name: "Coffee", color: "#5A351D" },
  { id: "thumbs-up", name: "Thumbs Up", color: "#F7C64B" },
  { id: "hands-clapping", name: "Clap", color: "#F7C64B" },
];

const ModalServiceRequest = ({
  id,
  fullScreen,
  isOpenModalServiceRequest,
  closeModalServiceRequest,
}: IModalServiceRequestProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [category, setCategory] = useState("face-angry");
  const [image, setImage] = useState<File | string | null>(null);
  const { setFetchingItems } = useFetching();

  const handleCategory = (icon: string) => {
    setCategory(icon);
  };

  const initialValues = useMemo(
    () => ({
      message: "",
      gambar: null,
    }),
    []
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IServiceRequestInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IServiceRequestInputs) => {
    setIsButtonLoading(true);
    try {
      const body = {
        icon: category,
        message: values.message,
        photo_url: image ?? "",
        status: "NEW",
      };
      const res = await axios.post(`/business/${id}/service-request`, body);
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "New service request added",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          showCloseButton: true,
          customClass: {
            container: "my-swal",
          },
        });
        closeModalServiceRequest();
        reset(initialValues);
        setFetchingItems();
      }
      setIsButtonLoading(false);
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      open={isOpenModalServiceRequest}
      onClose={closeModalServiceRequest}
      scroll="body"
      PaperProps={{ sx: { backgroundColor: "#F7F6F1" } }}
    >
      <ModalTitle onClose={closeModalServiceRequest}>
        <Stack direction="row" alignItems="center" gap={2}>
          <AddIcon />
          <Typography variant={fullScreen ? "body1" : "h5"} fontWeight="bold">
            Add service request
          </Typography>
        </Stack>
      </ModalTitle>
      <DialogContent dividers>
        <Typography variant="h6" fontWeight={700}>
          How can I help?
        </Typography>
        <form
          style={{
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container gap={2} mt={2}>
            <Grid item xs={12}>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  gap={2}
                >
                  {categoryList.map((li) => (
                    <IconButton onClick={() => handleCategory(li.id)}>
                      <FontAwesomeIcon
                        icon={li.id as IconProp}
                        color={category === li.id ? li.color : "gray"}
                        size="2x"
                      />
                    </IconButton>
                  ))}
                </Stack>
                {/* <Select
                  fullWidth
                  size="small"
                  value={category}
                  onChange={handleChangeCategory}
                >
                  {categoryList.map((li) => (
                    <MenuItem value={li.id}>
                      <Stack direction="row" gap={1} alignItems="center">
                        <FontAwesomeIcon
                          icon={li.id as IconProp}
                          color={li.color}
                          size="lg"
                        />
                        {li.name}
                      </Stack>
                    </MenuItem>
                  ))}
                </Select> */}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      placeholder="Describe your request, problem or feedback"
                      multiline
                      rows={4}
                      error={Boolean(errors.message)}
                      helperText={errors.message ? errors.message.message : ""}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography>Suppporting attachment (optional):</Typography>
                <TextField
                  fullWidth
                  type="file"
                  variant="outlined"
                  inputProps={{
                    accept: "image/*",
                  }}
                  onChange={async (event: any) => {
                    if (event.target.files[0].size < 2097152) {
                      console.log(event.target.files[0]);

                      const formData = new FormData();
                      if (event.target.files[0]) {
                        formData.append(
                          "filename",
                          event.target.files[0] as any
                        );
                      }
                      const res = await axios.post(`/upload-media`, formData, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      });
                      if (res.status === 201 || res.status === 200) {
                        console.log("imageurl", res.data.url);
                        setImage(res.data.url);
                      }
                    } else {
                      event.target.value = "";
                    }
                  }}
                  value={undefined}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack mt={2} direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={isButtonLoading}
              loadingPosition="center"
              disableElevation
              type="submit"
              variant="contained"
              color="buttongreen"
              sx={{
                // borderRadius: 50,
                fontWeight: 700,
              }}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModalServiceRequest;
