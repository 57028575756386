import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetGoalDetailResponse } from "../../constants/types";

const handleRequest = async (goalID: string | null) => {
  const urlApi = qs.stringifyUrl({
    url: `/goal/${goalID}`,
  });
  const { data } = await axios.get<GetGoalDetailResponse>(urlApi);
  return data;
};

export default function useGoalDetail(goalID: string | null) {
  return useQuery<GetGoalDetailResponse>(
    ["goalDetail", goalID],
    () => handleRequest(goalID),
    {
      enabled: !!goalID,
    }
  );
}
