import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import {
  titleMeta,
  faviconMeta,
  logoMeta,
  advisorEmailMeta,
  advisorMaskedNameMeta,
  memberEmailMeta,
  memberMaskedNameMeta,
} from "../../constants/common";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "../../services/axios";
import { GetAdvisorLoginResponse } from "../../constants/types";
import { useHistory } from "react-router-dom";

const OTPAdvisorOrMemberPage = () => {
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const cookies = useMemo(() => new Cookies(), []);
  const [logo, setLogo] = useState("");
  const [advisorEmail, setAdvisorEmail] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [advisorMaskedName, setAdvisorMaskedName] = useState("");
  const [memberMaskedName, setMemberMaskedName] = useState("");

  const handleClickAdvisor = async () => {
    try {
      const res = await axios.get<GetAdvisorLoginResponse>(
        `/advisor/login/${advisorEmail}`
      );
      if (res.data.success) {
        history.push(`/otp-login-secured-vault?id=${id}&advisor=true`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickMember = async () => {
    try {
      const res = await axios.get(`/member/login/${memberEmail}`);
      if (res.data.success) {
        history.push(`/otp-login-secured-vault?id=${id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      const advisorEmail = await cookies.get(advisorEmailMeta);
      const memberEmail = await cookies.get(memberEmailMeta);
      const advisorMaskedName = await cookies.get(advisorMaskedNameMeta);
      const memberMaskedName = await cookies.get(memberMaskedNameMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
      if (advisorEmail) setAdvisorEmail(advisorEmail);
      if (memberEmail) setMemberEmail(memberEmail);
      if (advisorMaskedName) setAdvisorMaskedName(advisorMaskedName);
      if (memberMaskedName) setMemberMaskedName(memberMaskedName);
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={3}
      alignItems="center"
      padding={3}
      sx={{ height: "100vh" }}
    >
      <Stack spacing={6} width="100%" alignItems="center">
        <img
          src={logo as string}
          alt="banner"
          style={{
            backgroundSize: "cover",
            color: "#fff",
            height: isMobileScreen ? "100px" : "200px",
            zIndex: 1,
            padding: 0,
            width: "100%",
            objectFit: "contain",
          }}
        />
        <Stack width="100%" alignItems="center">
          <Typography
            variant={isMobileScreen ? "h6" : "h5"}
            textAlign="center"
            fontWeight={700}
          >
            Welcome! Before you proceed, please tell us who you are.
          </Typography>
          <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            marginTop={6}
          >
            <Button
              disableElevation
              variant="outlined"
              color="buttongreen"
              onClick={() => handleClickAdvisor()}
              sx={{
                fontWeight: 700,
                width: 250,
                textAlign: "center",
              }}
            >
              I'm {advisorMaskedName}
            </Button>
            <Button
              disableElevation
              variant="outlined"
              color="buttongreen"
              onClick={() => handleClickMember()}
              sx={{
                fontWeight: 700,
                width: 250,
                textAlign: "center",
              }}
            >
              I'm {memberMaskedName}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default OTPAdvisorOrMemberPage;
