import Card from "@mui/material/Card";
import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import { Draggable } from "react-beautiful-dnd";
import { IGoal } from "../../constants/types";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalDetailGoal from "../ModalDetailGoal/ModalDetailGoal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface IGoalProps {
  goal: IGoal;
  index: number;
}

const Goal = ({ goal, index }: IGoalProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpenModalDetailGoal, setIsOpenModalDetailGoal] = useState(false);

  const openModalDetailGoal = () => setIsOpenModalDetailGoal(true);
  const closeModalDetailGoal = () => setIsOpenModalDetailGoal(false);

  return (
    <>
      <Draggable draggableId={goal.id} index={index}>
        {(provided, snapshot) => (
          <Card
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            elevation={1}
            sx={{
              backgroundColor: snapshot.isDragging ? "lightgray" : "white",
            }}
          >
            <CardContent sx={{ p: 1, pb: "8px !important" }}>
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography fontSize={14} fontWeight={700}>
                      {goal.title}
                    </Typography>
                    {goal.gross_amount && (
                      <Chip label={`$${goal.gross_amount}`} size="small" />
                    )}
                  </Box>
                  <IconButton
                    onClick={openModalDetailGoal}
                    sx={{ mt: "-12px", mr: -1 }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontSize={14}>{goal.member_name}</Typography>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        width: 24,
                        height: 24,
                      }}
                      alt={goal.member_name}
                      src={goal.member_avatar_photo}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Draggable>
      <ModalDetailGoal
        fullScreen={isMobileScreen}
        open={isOpenModalDetailGoal}
        onClose={closeModalDetailGoal}
        data={goal}
      />
    </>
  );
};

export default Goal;
