import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";
import { GetAdvisorServicesResponse } from "../../constants/types";

const handleRequest = async (advisorID: string) => {
  const urlApi = qs.stringifyUrl({
    url: `/advisor/${advisorID}/metadata`,
  });
  const { data } = await axios.get<GetAdvisorServicesResponse>(urlApi);
  return data;
};

export default function useAdvisorServices(advisorID: string) {
  return useQuery<GetAdvisorServicesResponse>(
    ["advisorServices", advisorID],
    () => handleRequest(advisorID),
    {
      enabled: !!advisorID,
    }
  );
}
