import React, { useEffect, useMemo } from "react";
import Routes from "./routes/Routes";
import { Cookies, CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import axios from "./services/axios";
import { GetWhitelabelResponse } from "./constants/types";
import {
  backgroundMeta,
  bannerMeta,
  emailFooterBanner,
  emailHeaderBanner,
  emailSenderId,
  faviconMeta,
  featureFlagAnnouncement,
  featureFlagWelcomeMessage,
  licenseeAlias,
  logoMeta,
  titleMeta,
  workbookEditionMeta,
} from "./constants/common";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFireFlameCurved,
  faThumbsUp,
  faFaceAngry,
  faLaughBeam,
  faCoffee,
  faHandsClapping,
  faFaceTired,
  faExclamation,
  faBed,
  faBuilding,
  fas,
  faArrowRightLong,
  faFileArrowUp,
  faFileSignature,
  faFileArrowDown,
  faFileCircleCheck,
  faFileShield,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import AppProvider from "./context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

function App() {
  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get<GetWhitelabelResponse>(
        // `/tenant/bri.localhost`
        `/tenant/${window.location.host}`
      );
      if (res.status === 200) {
        if (cookies.get(licenseeAlias) === res.data.business_alias) {
          return;
        }
        cookies.set(licenseeAlias, res.data.business_alias);
        cookies.set(emailSenderId, res.data.email_sender_id);
        cookies.set(emailHeaderBanner, res.data.email_header_banner);
        cookies.set(emailFooterBanner, res.data.email_footer_banner);
        cookies.set(titleMeta, res.data.site_title);
        cookies.set(faviconMeta, res.data.site_favicon_url);
        cookies.set(logoMeta, res.data.site_logo_url);
        cookies.set(bannerMeta, res.data.site_banner_url);
        cookies.set(backgroundMeta, res.data.site_background_url);
        cookies.set(workbookEditionMeta, res.data.workbook_edition);
        if (res.data.feature_flag_announcement)
          cookies.set(
            featureFlagAnnouncement,
            res.data.feature_flag_announcement
          );
        if (res.data.feature_flag_welcome_message)
          cookies.set(
            featureFlagWelcomeMessage,
            res.data.feature_flag_welcome_message
          );

        window.location.reload();
      }
    };

    fetchData().catch(console.error);
  }, [cookies]);

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AppProvider>
          <Routes />
        </AppProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
library.add(
  fas,
  faThumbsUp,
  faFireFlameCurved,
  faFaceAngry,
  faLaughBeam,
  faCoffee,
  faHandsClapping,
  faFaceTired,
  faExclamation,
  faBed,
  faBuilding,
  faArrowRightLong,
  faFileArrowUp,
  faFileSignature,
  faFileArrowDown,
  faFileCircleCheck,
  faFileShield,
  faBell
);
