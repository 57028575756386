import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async () => {
  const apiSuburbs = qs.stringifyUrl({
    url: "/geo/australia-suburbs",
  });
  const { data } = await axios.get<string[]>(apiSuburbs);
  return data;
};

export default function useSuburbs() {
  return useQuery<string[]>(["suburbs"], () => handleRequest());
}
