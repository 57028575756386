import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ThemeComponent from "../components/ThemeComponent/ThemeComponent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import FooterLinks from "../components/FooterLinks/FooterLinks";
import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import { faviconMeta, logoMeta, titleMeta } from "../constants/common";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  children: React.ReactNode;
  // window?: () => Window;
}

export default function PageLayoutAlternative({ children }: Props) {
  const cookies = useMemo(() => new Cookies(), []);
  const [logo, setLogo] = useState("");

  const loadWhitelabelData = useCallback(async () => {
    try {
      const title = await cookies.get(titleMeta);
      const favicon = await cookies.get(faviconMeta);
      const logo = await cookies.get(logoMeta);
      if (title) {
        window.document.title = title;
      }
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          //@ts-ignore
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        //@ts-ignore
        link.href = favicon;
      }
      if (logo) {
        setLogo(logo);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [cookies]);

  useEffect(() => {
    loadWhitelabelData();
  }, [loadWhitelabelData]);

  return (
    <ThemeComponent>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{
            backgroundColor: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Container maxWidth="lg">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={logo as string}
                  alt="banner"
                  style={{
                    backgroundSize: "cover",
                    color: "#fff",
                    height: "100px",
                    zIndex: 1,
                    padding: 0,
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Link underline="hover" variant={"body2"} fontWeight={700}>
                CONTACT US
              </Link>
            </Stack>
          </Container>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1 }}>
          {children}
        </Box>
        <Container maxWidth="lg">
          <Stack p={3} gap={3}>
            <Typography variant="subtitle2" fontSize={10}>
            Your site and transaction are highly secured. For more information you can read our security and privacy policy.
            </Typography>
            <Divider />
            <FooterLinks alternative />
          </Stack>
        </Container>
      </Box>
    </ThemeComponent>
  );
}
